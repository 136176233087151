import { formatNumberBySetting, math, formatBigNumber, $_helper_isNumberType_bySetting, formatValue } from '@/concerns/newRegisterData/wijmo.helper'
import { handleCalcFuelForTkmMethod } from '@/utils/csvImportHandler';
import { formatNumberByConditions } from '@/utils/convertNumber';
import i18n from '@/lang/i18n.js';

const GASOLINE = 'ガソリン'
const DIESEL = 'ディーゼル'
const OLD = '軽油'
export const VEHICLES = ['船舶', '航空']
const valueByBasicName = {
  '最大積載量・積載率を指定する場合　ガソリン車（2022年度基準達成車）': { firstVal: 6.96, secondVal: 0.927, lastVal: 0.612 },
  '最大積載量・積載率を指定する場合　ガソリン車（2015年度基準達成車）': { firstVal: 6.23, secondVal: 0.927, lastVal: 0.565 },
  '最大積載量・積載率を指定する場合　ガソリン車（その他）': { firstVal: 14.4, secondVal: 0.927, lastVal: 0.648 },
  '最大積載量・積載率を指定する場合　ディーゼル車（2025年度基準達成車）': { firstVal: 8.83, secondVal: 0.812, lastVal: 0.623 },
  '最大積載量・積載率を指定する場合　ディーゼル車（2022年度基準達成車）': { firstVal: 10.8, secondVal: 0.812, lastVal: 0.654 },
  '最大積載量・積載率を指定する場合　ディーゼル車（2015年度基準達成車）': { firstVal: 14.0, secondVal: 0.812, lastVal: 0.658 },
  '最大積載量・積載率を指定する場合　ディーゼル車（その他）': { firstVal: 15.0, secondVal: 0.812, lastVal: 0.654 },
}

const specialBasicUnitName = [
  '最大積載量・積載率を指定する場合　ガソリン車：2009-2021年',
  '最大積載量・積載率を指定する場合　ディーゼル車：2009-2021年',
  '最大積載量・積載率を指定する場合　ガソリン車：2022年',
  '最大積載量・積載率を指定する場合　ディーゼル車：2022年',
  '最大積載量・積載率を指定する場合　ガソリン車：2023年',
  '最大積載量・積載率を指定する場合　ディーゼル車：2023年'
]

export const listBasicUnitTkm = [
  '最大積載量・積載率を指定する場合　ガソリン車（2022年度基準達成車）',
  '最大積載量・積載率を指定する場合　ガソリン車（2015年度基準達成車）',
  '最大積載量・積載率を指定する場合　ガソリン車（その他）',
  '最大積載量・積載率を指定する場合　ディーゼル車（2025年度基準達成車）',
  '最大積載量・積載率を指定する場合　ディーゼル車（2022年度基準達成車）',
  '最大積載量・積載率を指定する場合　ディーゼル車（2015年度基準達成車）',
  '最大積載量・積載率を指定する場合　ディーゼル車（その他）',
  '最大積載量・積載率を指定する場合　ガソリン車：2009-2021年',
  '最大積載量・積載率を指定する場合　ディーゼル車：2009-2021年',
  '最大積載量・積載率を指定する場合　ガソリン車：2022年',
  '最大積載量・積載率を指定する場合　ディーゼル車：2022年',
  '最大積載量・積載率を指定する場合　ガソリン車：2023年',
  '最大積載量・積載率を指定する場合　ディーゼル車：2023年'
]

const handleGetValByBasicUnit = basicUnitName => {
  return valueByBasicName[basicUnitName]
}

const handleGetValBySpecialBasicUnit = (loadingDataRate, loadingDataMax, basicUnitName) => {
  const loadingDataRateVal = math.bignumber(formatNumberBySetting(loadingDataRate))
  const loadingDataMaxVal = math.bignumber(formatNumberBySetting(loadingDataMax))

  let result = 0
  switch (basicUnitName) {
    case '最大積載量・積載率を指定する場合　ガソリン車：2009-2021年':
    case '最大積載量・積載率を指定する場合　ガソリン車：2022年':
    case '最大積載量・積載率を指定する場合　ガソリン車：2023年':
      result = formatBigNumber(math.evaluate(`exp(2.67 - 0.927 * log(${loadingDataRateVal.toString()}/100) - 0.648 * log(${loadingDataMaxVal.toString()})) * 0.001`), 50)
      break;
    case '最大積載量・積載率を指定する場合　ディーゼル車：2009-2021年':
    case '最大積載量・積載率を指定する場合　ディーゼル車：2022年':
    case '最大積載量・積載率を指定する場合　ディーゼル車：2023年':
      result = formatBigNumber(math.evaluate(`exp(2.71 - 0.812 * log(${loadingDataRateVal.toString()}/100) - 0.654 * log(${loadingDataMaxVal.toString()})) * 0.001`), 50)
      break;
    default:
      result = 0
      break;
  }
  const range = result.includes('-') ? 26 : 25
  return formatNumberByConditions(result.substring(0, range).toString(), { isRealNumber: true, isAlowMore25digit: false })
}

export const calcFuelVal = (loadingDataRate, loadingDataMax, basicUnitName) => {
  // check valid props
  if(!basicUnitName || !$_helper_isNumberType_bySetting(loadingDataRate) || !$_helper_isNumberType_bySetting(loadingDataMax)) return null
  if (['0', 0].includes(loadingDataRate) || ['0', 0].includes(loadingDataMax)) return 0;
  if(specialBasicUnitName.includes(basicUnitName)) { // check case special basic unit => use new calc fuel
    return handleGetValBySpecialBasicUnit(loadingDataRate, loadingDataMax, basicUnitName)
  }

  // case basic don't mapping 7 items t-co2/kl 
  if(!handleGetValByBasicUnit(basicUnitName)) return null

  const { firstVal, secondVal, lastVal } = handleGetValByBasicUnit(basicUnitName)

  const loadingDataRateVal = math.bignumber(formatNumberBySetting(loadingDataRate))
  const loadingDataMaxVal = math.bignumber(formatNumberBySetting(loadingDataMax))
  const firstValNumber = math.bignumber(formatValue(firstVal)) // no need to use formatNumberBySetting for const value
  const secondValNumber = math.bignumber(formatValue(secondVal))
  const lastValNumber = math.bignumber(formatValue(lastVal))
  const result = formatBigNumber(math.evaluate(`${firstValNumber.toString()} / (${loadingDataRateVal.toString()}/100)^${secondValNumber.toString()} / ${loadingDataMaxVal.toString()}^${lastValNumber.toString()} * 0.001`), 50)
  const range = result.includes('-') ? 26 : 25
  return formatNumberByConditions(result.substring(0, range), { isRealNumber: true, isAlowMore25digit: false })
}

export const handleCalcDefaultDataRate = (loadingDataMax, basicUnitName) => {
  if(!basicUnitName || !loadingDataMax) return null
  let val_loading_data_max = formatNumberByConditions(formatNumberBySetting(loadingDataMax), {
    decimalPlaces: 0, // cut 0 decimal places
    isRoundNumber: false,
  });
  val_loading_data_max = math.bignumber(formatNumberBySetting(val_loading_data_max));
  if(basicUnitName.includes(GASOLINE)) { // GASOLINE
    if(math.larger(val_loading_data_max, '1500') || math.equal(val_loading_data_max, '1500')) return '29'
    return '24'
  }

  if(basicUnitName.includes(DIESEL) || basicUnitName.includes(OLD)) { // DIESEL, OLD
    if(math.smaller(val_loading_data_max, '1000')) return '19'
    if((math.larger(val_loading_data_max, '1000') || math.equal(val_loading_data_max, '1000')) && math.smaller(val_loading_data_max, '2000')) return '25'
    if((math.larger(val_loading_data_max, '2000') || math.equal(val_loading_data_max, '2000')) && math.smaller(val_loading_data_max, '4000')) return '34'
    if((math.larger(val_loading_data_max, '4000') || math.equal(val_loading_data_max, '4000')) && math.smaller(val_loading_data_max, '8000')) return '38'
    if(math.larger(val_loading_data_max, '8000') || math.equal(val_loading_data_max, '8000')) return '51' 
  }

  return null
}

export const TKM_UNIT_SOURCE = 't-CO2/kl'
export const VEHICLE_TYPE = [
  {
    id: 1,
    name: i18n.t('tkm_pattern_table.radio_aviation_vehicle'),
  },
  {
    id: 2,
    name: i18n.t('tkm_pattern_table.radio_ships_vehicle')
  },
];

export const isTkmBasicUnitName = basicUnitName => {
  return listBasicUnitTkm.includes(basicUnitName)
}

export const handleCalcRateAndFuelTkmMethod = (dataTable, databaseTypeObject, outOfTkmBasicList = false) => {
  const isCalcDataRate = dataTable?.load_capacity_maximum && !dataTable?.loading_data_rate && dataTable.wsu_unit === TKM_UNIT_SOURCE
  if(!outOfTkmBasicList && isCalcDataRate) {
    dataTable.loading_data_rate = handleCalcDefaultDataRate(dataTable?.load_capacity_maximum, databaseTypeObject?.item_name) || null
    handleCalcFuelForTkmMethod(dataTable, databaseTypeObject)
  }
}