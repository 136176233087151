<template>
  <footer class="wrap__footer" :style="footerStyle" :class="footerClass">
    <div class="wrap__footer--left">
      <p>©︎2022 Sustech Inc.</p>
      <div v-if="isMobile" class="just-logo" @click="linkToTrusty()"><img class="truste-logo" src="@/assets/images/truste-logo/TRUSTe_certified_privacy_en.png"/></div>
    </div>
    <div class="wrap__footer--right">
      <a v-for="(item, index) in items" :key="index" :href="item.href" target="_blank">
        {{ item.label }}
      </a>
      <div v-if="!isMobile" class="just-logo" @click="linkToTrusty()"><img class="truste-logo" src="@/assets/images/truste-logo/TRUSTe_certified_privacy_en.png"/></div>
    </div>
  </footer>
</template>
<script>
import { ROUTES } from "@/router/constants";
export default {
  props: {
    isExpanded: {
      type: Boolean,
      default: false
    },
    isAuth: {
      type:  Boolean,
      default: false,
    }
  },
  data() {
    return {
      items: [
        {
          href: 'https://www.sustech-inc.com/',
          label: this.$t('type_selection.hyperlink_operating_company')
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.TERM_OF_USER}`,
          label: this.$t('type_selection.hyperlink_term_of_use')
        },
        {
          href: `${ROUTES.OTHERS}/${ROUTES.PRIVACY}`,
          label: this.$t('type_selection.hyperlink_privacy_policy')
        },
      ]
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 1024;
    },
    footerStyle() {
      if (this.isAuth || this.isMobile) return 'width: 100%';
      return this.isExpanded ? 'width: calc(100% - 236px)' : 'width: calc(100% - 73px)';
    },
    footerClass() {
      return this.isAuth ? 'auth' : '';
    }
  },
  methods: {
    linkToTrusty() {
      window.open("/others/privacy#truste");
      // window.open("https://www.truste.or.jp/hssl/validate/01632.php");
    },
  }
}
</script>
<style lang="scss" scoped>
.wrap__footer {
  background: $bgDark;
  position: fixed;
  bottom: 0;
  &--left {
    padding: 4.5px 8px 4.5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 44px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    p {
      margin-bottom: 0px;
      color: $monoMid;
      font-family: "Source Han Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.65px;
    }
  }
  &--right {
    padding: 11px 20px;
    display: flex;
    gap: 16px;
    height: 44px;
    a {
      color: $monoDark;
      font-family: "Source Han Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.6px;
      text-decoration: none;
      &:hover {
        color: $monoLight;
        background: $bgDark;
      }
    }
  }
  .just-logo{
    height: 35px;
    cursor: pointer;
    .truste-logo {
      height: 100%;
    }
  }
}
@include desktop {
  .wrap__footer {
    padding: 4.5px 10px 4.5px 40px;
    display: flex;
    align-items: center;
    height: 44px;
    &--left {
      border-bottom: unset;
      padding: unset;
    }
    &--right {
      width: 100%;
      padding: unset;
      align-items: center;
      justify-content: flex-end;
    }
    &.auth {
      height: 60px;
      padding: 10px 10px 10px 40px;
    }
  }
}
</style>