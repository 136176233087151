<template>
  <div v-show="!isEmptySource" class="wrap_pagination">
    <div class="wrap_pagination_selectpage">
      <div class="wrap_pagination_selectpage_item" :class="isDisabledBackBtn" @click="handleBackBtn()">
        <img :src="getBackNextIcon(true)" alt="" />
      </div>
      <div
        v-for="(item, index) in pagingItems"
        :key="index"
        class="wrap_pagination_selectpage_item"
        :class="isSelectedPage(item.id)"
        @click="selectPage(item.id)"
      >
        {{ item.value }}
      </div>
      <div class="wrap_pagination_selectpage_item" :class="isDisabledNextBtn" @click="handleNextBtn()">
        <img :src="getBackNextIcon()" alt="" />
      </div>
    </div>
    <div class="wrap_pagination_search" :class="$i18n.locale">
      <SearchInput
        :isNumberType="true"
        :isDisableButton="isDisableButton"
        :getOriginalValue="true"
        :isPaging="true"
        @handleSeachFilter="handleSeachFilterPaging"
        @handleDisableSearchBtn="handleDisableSearchBtn"
        @handlePressEnterButton="handlePressEnterButton"
      />
    </div>
  </div>
</template>
<script>
const TOTAL_VISIBLE = 5;
import { DEFAULT_PAGING } from '@/constants/basic-unit.js';
import SearchInput from '@/components/commonApp/SearchInput.vue';

export default {
  props: {
    totalDataList: {
      type: [String, Number],
      default: 100,
    },
    currentPageSize: {
      type: Number,
      default: DEFAULT_PAGING.pageSize,
    },
    isEmptySource: {
      type: Boolean,
      default: false,
    }
  },
  components: {SearchInput},
  data() {
    return {
      currentPage: 1,
      nullItem: '...',
      isDisableButton: true,
    };
  },
  computed: {
    pageCount() {
      return this.totalDataList < this.currentPageSize ? 1 : Math.ceil(Number(this.totalDataList) / Number(this.currentPageSize));
    },
    isDisabledBackBtn() {
      return this.currentPage === 1 ? 'disabled' : '';
    },
    isDisabledNextBtn() {
      return this.currentPage == this.pageCount ? 'disabled' : '';
    },
    pagingItems() {
      if (this.pageCount < this.currentPage) {
        this.selectPage(this.pageCount);
      }
      const totalVisible = parseInt(TOTAL_VISIBLE, 10)
      const maxLength = Math.min(
        Math.max(0, totalVisible) || this.pageCount,
        Math.max(0, TOTAL_VISIBLE) || this.pageCount,
        this.pageCount
      )
      if (this.pageCount <= maxLength) {
        return this.setRangeData(1, this.pageCount)
      }
      const even = maxLength % 2 === 0 ? 1 : 0
      const left = Math.floor(maxLength / 2)
      const right = this.pageCount - left + 1 + even
      
      if (this.currentPage > left && this.currentPage < right) {
        const firstItem = 1
        const lastItem = this.pageCount
        const start = this.currentPage - left + 2
        const end = this.currentPage + left - 2 - even
        const secondItem = start - 1 === firstItem + 1 ? 2 : this.nullItem
        const beforeLastItem = end + 1 === lastItem - 1 ? end + 1 : this.nullItem
        
        return [...this.setRangeData(1, 1), ...this.setRangeData(secondItem, secondItem), ...this.setRangeData(start, end), ...this.setRangeData(beforeLastItem, beforeLastItem), ...this.setRangeData(this.pageCount, this.pageCount)]
      } else if (this.currentPage === left) {
        const end = this.currentPage + left - 1 - even
        return [...this.setRangeData(1, end), ...this.setRangeData('null', 'null'), ...this.setRangeData(this.pageCount, this.pageCount)]
      } else if (this.currentPage === right) {
        const start = this.currentPage - left + 1
        return [...this.setRangeData(1, 1), ...this.setRangeData('null', 'null'), ...this.setRangeData(start, this.pageCount)]
      } else {
        return [...this.setRangeData(1, left), ...this.setRangeData('null', 'null'), ...this.setRangeData(right, this.pageCount),
        ]
      }
    }
  },
  methods: {
    getBackNextIcon(back = false) {
      const icon = back ? 'back.svg' : 'next.svg';
      return require(`@/assets/icons/paging/${icon}`);
    },
    selectPage(index) {
      if (this.currentPage === index) return; // keep page when click current page
      if (index === this.nullItem) return; // remove click ...
      this.currentPage = index;
      this.handleSelectedPage();
    },
    isSelectedPage(index) {
      if(this.currentPage === index) {
        return 'selected'
      }else if(this.nullItem === index ) {
        return 'class-item-null'
      } else {
        return ''
      }
    },
    handleBackBtn() {
      if (this.isDisabledBackBtn === 'disabled') return;
      this.currentPage -= 1;
      this.handleSelectedPage();
    },
    handleNextBtn() {
      if (this.isDisabledNextBtn === 'disabled') return;
      this.currentPage += 1;
      this.handleSelectedPage();
    },
    setRangeData(from, to) {
      const range = []
      if (typeof from === 'string', typeof to === 'string') {
        return [{
          id: this.nullItem,
          value: this.nullItem
        }]
      }
      
      from = from > 0 ? from : 1

      for (let i = from; i <= to; i++) {
        range.push({
          id: i,
          value: i
        })
      }

      return range
    },
    handleSelectedPage() {
      this.$emit('handleSelectedPage', this.currentPage);
    },

    handleSeachFilterPaging(value) {
      let customValue = Number(value.replace(/['\,]/g, ''));
      if(customValue < 1 || customValue > this.pageCount) {
        return
      } else {
        this.selectPage(customValue)
      }
    },
    handleDisableSearchBtn(value) {
      if (!value) {
        this.isDisableButton = true;
        return;
      }
      if (value.includes('-') || value.includes('.')) {
        this.isDisableButton = true;
        return;
      }
      let customValue = Number(value.replace(/['\,]/g, ''));
      this.isDisableButton = (customValue < 1 || customValue > this.pageCount || !customValue) ? true : false
    },
    handlePressEnterButton(valueSearch) {
      const isValidPage = Number(valueSearch) > 0 && Number(valueSearch) <= this.pageCount
      if(isValidPage) {
        this.$emit('handlePressEnterButton', Number(valueSearch));
        this.selectPage(Number(valueSearch))
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap_pagination {
  padding-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  &_selectpage {
    display: flex;
    justify-content: center;
    gap: 8px;
    &_item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      min-width: 32px;
      height: 32px;
      padding: 5px 4px;
      border-radius: 4px;
      background: $monoWhite;
      text-align: center;
      font-family: "Source Han Sans JP";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: $monoDark;
      &:hover {
        cursor: pointer;
        color: $monoWhite;
        background: $goldLight;
      }
      &.selected {
        border: 1px solid $goldMid;
        color: $goldMid;
        font-weight: 400;
        &:hover {
          color: $monoWhite;
        }
      }
      &.disabled {
        border: unset;
        opacity: 0.5;
        background: $greyDark;
        &:hover {
          cursor: default;
        }
      }
      &.class-item-null {
        cursor: default;
        pointer-events: none;
      }
    }
  }
  &_search {
    width: 201px;
    .search-wrapper {
      gap: unset;
    }
  }
}
::v-deep {
  .common-btn {
    height: 28px !important;
    min-width: 79px !important;
    max-width: 117px;
    padding: 4px 8px 6px !important;
    .v-btn__content {
      font-size: 11px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0.33px;
    }
  }
  .en {
    .common-btn {
      min-width: 89px !important;
    }
  }
}
</style>