import * as wjGrid from '@mescius/wijmo.grid';
import store from '@/store';
import { AutoComplete } from '@mescius/wijmo.input';
import { KEYS_CODE } from '@/constants/keyboard'
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
  MESSAGE_MAX_20_CHARACTOR,
  MESSAGE_MAX_10_CHARACTOR,
  MESSAGE_IS_NUMBER,
  ORGANIZATIONAL_DIVISION
}
  from '@/constants/registerData'
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';
import debounce from 'lodash/debounce'

import { formatValue, $_helper_isNumberType, math, formatBigNumber, formatEmission25Number, validateNumberFieldBySetting, $_helper_isNumberType_bySetting, formatNumberBySetting } from './wijmo.helper'
import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getColumnIndexByBinding, getBranchId, checkFilterData } from '@/concerns/utils/filter-data'

import { getListDbAddNew, getDbAddNewInObject  } from '@/concerns/utils/master-or-customize-db'
import { addThoundSandComma, formatNumberRealNum } from '@/utils/convertNumber';
import i18n from '@/lang/i18n.js';
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import { handleLostFocus } from '@/utils/csvImportHandler'
import { handleLostFocusBasicUnitPulldown, handleBasicUnitDroppedDownChanged, handleGotFocusBasicUnitPulldown } from '@/utils/registerData';
import { getWidthOfAttachedButton, getWidthOfSearchButton } from '@/utils/multiLanguage';
import { DB_TYPE } from '@/constants/dbType';
import { getDbRelationKeysByType } from '@/utils/sourceExternal';
import { handleCalcDefaultDataRate, calcFuelVal, TKM_UNIT_SOURCE, listBasicUnitTkm, isTkmBasicUnitName, handleCalcRateAndFuelTkmMethod } from '@/constants/tkm-method'
import * as wjcCore from "@mescius/wijmo";
import { getWidthByTextContent } from '@/utils/calcTextWidth';

export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    organizational_division: null,
    company_name: null,
    business_name: null,
    energy_type: null,
    loading_data_maximum: null,
    loading_data_rate: null,
    unit: null,
    distance: null,
    wsu_source: null,
    wsu_unit: null,
    wsu_value: null,
    emissions: null,
    duration_id: null,
    month: null,
    year: null,
    db_customize_id: null,
    db_master_id: null,
    type: null,
    loading_data_source: null,
    distance_source: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
    fuel: null,
    db_relation: null,
    load_capacity_maximum: null,
    departure_location: null,
    arrival_location: null,
  }
}

const validateNumberField = (input, propName = '') => {
  if (input) {
    if(!$_helper_isNumberType(input)){
      return MESSAGE_IS_NUMBER; 
    }
    
    const numberValidate = formatValue(input).includes('-') ? 26 : 25
    if (formatValue(input).length > numberValidate) {
      return MESSAGE_MAX_20_CHARACTOR;
    }
    const number10Char = formatValue(input).includes('-') ? 11 : 10
    if (formatValue(input).length > number10Char && propName === 'loading_data_rate') {
      return MESSAGE_MAX_10_CHARACTOR;
    }
  } 
  return null;
}

const notNullByCondition = {
  notNullListTkmBasicUnit: [
    'organizational_division',
    'company_name',
    'value',
    'wsu_value',
    'wsu_unit',
    'wsu_source',
    'fuel',
    'load_capacity_maximum',
    'loading_data_rate'
  ],
  notNullListTkmDbCustomize: [
    'organizational_division',
    'company_name',
    'value',
    'wsu_value',
    'wsu_unit',
    'wsu_source',
    'fuel'
  ]
}

const max10CharactorBycondition = {
  max10CharactorTkmBasicUnit: [
    'loading_data_rate',
  ]
}

const max25CharactorByCondition = {
  max25CharactorTkmBasicUnit: [
    'loading_data_maximum',
    'value',
    'wsu_unit',
    'fuel',
    'load_capacity_maximum',
    'loading_data_rate'
  ],
  max25CharactorTkmDbCustomize: [
    'loading_data_maximum',
    'value',
    'wsu_unit',
    'fuel',
  ]
}

const max128CharactorByConditon = {
  max128CharactorTkmBasicUnit: [
    'memo_1',
    'memo_2',
    'loading_data_source',
    'distance_source',
    'departure_location',
    'arrival_location'
  ]
}

const numberFieldBycondition = {
  numberFieldTkmBasic: ['value', 'loading_data_maximum', 'loading_data_rate', 'fuel', 'load_capacity_maximum'],
  numberFieldDbCustom: ['value', 'loading_data_maximum', 'fuel']
}

const notNullInView = [
  'wsu_value',
  'wsu_unit',
  'wsu_source'
]

const notNullList = [
  'organizational_division',
  'company_name',
  'distance',
  'wsu_value',
  'wsu_unit',
  'wsu_source'
]

const notNullListDbSource = [
 'organizational_division',
  'company_name',
  'distance',
  'wsu_value',
  'wsu_unit',
  'wsu_source'
]

const max25Charactor = [
  'loading_data_maximum',
  'distance',
  'wsu_unit',
]

const max10Charactor = []

const max128Charactor = [
  'memo_1',
  'memo_2',
  'distance_source',
  'departure_location',
  'arrival_location'
]

const numberField = ['distance', 'loading_data_maximum']

const calcEmissions = (valueSource, loadingDataMax, distance, currentItem) => {
  if(currentItem?.wsu_unit === TKM_UNIT_SOURCE) {
    const fuel = currentItem?.fuel
    if($_helper_isNumberType_bySetting(valueSource) && $_helper_isNumberType_bySetting(loadingDataMax) && $_helper_isNumberType_bySetting(distance) && $_helper_isNumberType_bySetting(fuel)){
      const valSource=  math.bignumber(formatNumberBySetting(valueSource));
      const valLoadingMax= math.bignumber(formatNumberBySetting(loadingDataMax));
      const val= math.bignumber(formatNumberBySetting(distance));
      const fuelVal= math.bignumber(formatNumberBySetting(fuel));
      return  formatBigNumber(math.evaluate(`${valSource.toString()} * ${valLoadingMax.toString()} * ${val.toString()} * ${fuelVal.toString()}`), 50)
    }
  
    return 0
  } else {
    if($_helper_isNumberType_bySetting(valueSource) && $_helper_isNumberType_bySetting(loadingDataMax) && $_helper_isNumberType_bySetting(distance)){

      const valSource=  math.bignumber(formatNumberBySetting(valueSource));
      const valLoadingMax= math.bignumber(formatNumberBySetting(loadingDataMax));
      const val= math.bignumber(formatNumberBySetting(distance));
      
      return  formatBigNumber(math.evaluate(`${valSource.toString()} * ${valLoadingMax.toString()} * ${val.toString()}`), 50)
    }

    return 0
  }
}

const handleCalcFuelForTkmMethod = (currentItem, dbItem, binding) => {
  const isEnableLoadData = (currentItem?.wsu_unit === TKM_UNIT_SOURCE && dbItem?.type === 1)
  if(!currentItem?.wsu_unit) return

  if(currentItem?.wsu_unit !== TKM_UNIT_SOURCE) {
    currentItem.loading_data_rate = null
    currentItem.load_capacity_maximum = null
    currentItem.fuel = null
    currentItem.loading_data_source = null
    return ''
  }

  if(currentItem.loading_data_rate && currentItem.load_capacity_maximum && isEnableLoadData) {
    currentItem.fuel = calcFuelVal(currentItem.loading_data_rate, currentItem.load_capacity_maximum, dbItem?.item_name)
  } else {
    if(binding === 'wsu_source') currentItem.fuel = null
  }
}

const handleResetLoadDataByType = (currentItem) => {
  currentItem.loading_data_rate = null
  currentItem.load_capacity_maximum = null
  if(currentItem?.wsu_unit !== TKM_UNIT_SOURCE) {
    currentItem.fuel = null
    currentItem.loading_data_source = null
  }
}

let isReverted = false;
let searchID = null;

const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']
let listMaxWidths = []

const autoBindingDbSource = dataBinding => {
  const { s, binding, row, cellData, currentItem, dataStore, dbAddNewInObject } = dataBinding
  if (binding == 'distance') {
    s.deferUpdate(() => {
      currentItem['emissions'] = calcEmissions(currentItem.wsu_value, currentItem.distance, currentItem.loading_data_maximum, currentItem)
    })
  }

  if (binding === 'loading_data_maximum') {
    s.deferUpdate(() => {
      const emissions = calcEmissions(currentItem.wsu_value, cellData, currentItem.distance, currentItem)
      s.setCellData(row, getColumnIndexByBinding(s,'emissions' ), emissions, false, true)
    })
  }

  if (binding === 'loading_data_rate') {
    s.deferUpdate(() => {
      const dbItem = dbAddNewInObject[currentItem.wsu_source] ||  dataStore[currentItem.wsu_source]
      const outOfTkmBasicList = dbItem?.type_db === DB_TYPE.MASTER && !isTkmBasicUnitName(dbItem?.name_basic)
      const isEnableLoadData = (currentItem?.wsu_unit === TKM_UNIT_SOURCE && dbItem?.type === 1 && !outOfTkmBasicList) || !currentItem?.wsu_unit
      if(!isEnableLoadData) {
        currentItem.loading_data_rate = null
        currentItem.load_capacity_maximum = null
      } else {
        if(!cellData) currentItem.fuel = null
      }
      handleCalcFuelForTkmMethod(currentItem, dbItem)

      const emissions = calcEmissions(currentItem.wsu_value, currentItem.loading_data_maximum, currentItem.distance, currentItem)
      s.setCellData(row, getColumnIndexByBinding(s,'emissions' ), emissions, false, true)
    })
  }

  if (binding === 'wsu_source') {
    let dbItem = dbAddNewInObject[cellData] || dataStore[cellData]
    if (searchID && !dbItem) {
      dbItem =  dataStore[searchID] || dbAddNewInObject[searchID]
    }
    s.deferUpdate(() => {
      const { SERVICE_LINK, IDEA } = DB_TYPE;
      currentItem.idDbExternal = ![SERVICE_LINK, IDEA].includes(dbItem?.type) ? null : dbItem?.id
      if (dbItem) {
        if(dbItem?.type === SERVICE_LINK) {
          currentItem['detailExternalProductCode'] = currentItem.idDbExternal
          currentItem['distance'] = dbItem.numberValue
        } else {
          currentItem['detailExternalProductCode'] = null
        }
        currentItem['wsu_source'] = dbItem.id;
        currentItem['wsu_value'] = formatNumberRealNum(dbItem.value_source);
        currentItem['wsu_unit'] = dbItem.unit_source;
        currentItem['emissions'] = calcEmissions(dbItem.value_source, currentItem.distance, currentItem.loading_data_maximum, currentItem);
        currentItem['type_db'] = dbItem.type;
        currentItem['name_basic'] = dbItem.name_basic || dbItem?.item_name;
        if(dbItem?.type === 1) {
          handleCalcFuelForTkmMethod(currentItem, dbItem, binding)
          if(!isTkmBasicUnitName(dbItem.name_basic)) handleResetLoadDataByType(currentItem)
        } else {
          handleResetLoadDataByType(currentItem)
        }
      } else {
        currentItem['wsu_source'] = null;
        currentItem['wsu_value'] = null;
        currentItem['wsu_unit'] = null;
        currentItem['emissions'] = calcEmissions(currentItem.wsu_value, currentItem.distance, currentItem.loading_data_maximum, currentItem);
      }
    })
  }

  if(binding === 'load_capacity_maximum') {
    const isCalcDataRate = currentItem?.load_capacity_maximum && !currentItem?.loading_data_rate && currentItem.wsu_unit === TKM_UNIT_SOURCE
    if(isCalcDataRate) { //handle calc default loading_data_rate
      const dbItem = dbAddNewInObject[currentItem.wsu_source] ||  dataStore[currentItem.wsu_source]
      const outOfTkmBasicList = dbItem?.type_db === DB_TYPE.MASTER && !isTkmBasicUnitName(dbItem?.name_basic)
      if(!outOfTkmBasicList) {
        currentItem.loading_data_rate = handleCalcDefaultDataRate(currentItem?.load_capacity_maximum, dbItem?.item_name) || null
        handleCalcFuelForTkmMethod(currentItem, dbItem)
      }
    }
  }
  if (binding === 'loading_data_maximum') {
    s.deferUpdate(() => {
      currentItem['emissions'] = calcEmissions(currentItem.wsu_value, currentItem.distance, currentItem.loading_data_maximum, currentItem);
    })
  }

  if(['load_capacity_maximum', 'fuel'].includes(binding)) { // update emission
    s.deferUpdate(() => {
      const dbItem = dbAddNewInObject[currentItem.wsu_source] ||  dataStore[currentItem.wsu_source]
      const isEnableLoadData = (currentItem?.wsu_unit === TKM_UNIT_SOURCE && dbItem?.type === 1) || !currentItem?.wsu_unit
      
      if(isEnableLoadData) handleCalcFuelForTkmMethod(currentItem, dbItem)
        currentItem['emissions'] = calcEmissions(currentItem.wsu_value, currentItem.distance, currentItem.loading_data_maximum, currentItem);
    })
  }
}
let filterIndex = {};
const partern2 = {
  initializeGrid(dataProps) {
    const { flexgrid, itemCount, branchData = {}, getNewBbStore } =  dataProps
    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};
    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount

          for (let index = 1; index <= itemCount; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100))

    document.addEventListener('keydown', (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener('keydown', (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
          flexgrid.selection = cellRange

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(0, currentSelection.col)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
          flexgrid.selection = cellRange
        }
      }

      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            // flexgrid.itemsSource.clearChanges();
          });
        }
      }

      
    }, false)

    flexgrid.pasted.addHandler((s, e) => {
      const { col, col2, row, row2 } = e.range
      const view = s.collectionView
      const source = view.sourceCollection

      const dbStore = getNewBbStore()
      const dbAddNewInObject = getDbAddNewInObject(getListDbAddNew(dbStore.dbExternalAdded))
      const dataStore = dbStore.getDbCustomizeOrDbMasterById

      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          // add filter index after pasted

          if (filterColumns.includes(s.columns[colIndex].binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s, e, colIndex, rowIndex, filterIndex);
              if (typeof checkFilterDataAfterPasted[rowIndex] === 'undefined') {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }
          const cellData = s.getCellData(rowIndex, colIndex, false)
          const currentItem = source[rowIndex] || {}
          const binding = s.columns[colIndex].binding
          const dataBinding = { s, binding, row: rowIndex, cellData, currentItem, dataStore, dbAddNewInObject }
          //autoBindingDbSource(dataBinding)
        }
      }

      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(branchData.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted)
        store.dispatch('registerData/updateListErrorFilterDataMessage', errorMessage)
        checkFilterDataAfterPasted = {}
      }
    })

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column = s.columns[e.col];
      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}
      const binding = column.binding
      const dbStore = getNewBbStore()
      const dataStore = dbStore.getDbCustomizeOrDbMasterById;
      const item = s.rows[e.row].dataItem;
      const dbAddNewInObject = getDbAddNewInObject(getListDbAddNew(dbStore.dbExternalAdded))
      const dataBinding = { s, binding, row, cellData, currentItem, dataStore, dbAddNewInObject }
      //autoBindingDbSource(dataBinding)

      if (filterColumns.includes(binding)) {
        handlerFilterData(s, e, branchData?.filterPatternList, filterIndex, selectedFirst, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      const column = s.columns[e.col];
      const item = s.rows[e.row].dataItem;
      const binding = column.binding

     
      if (['wsu_unit', 'wsu_value', 'emissions'].includes(binding)) {
        e.cancel = true;
      }
      if (!item.business_name) {
        item.business_name = null;
      }

      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)

      if (filterColumns.includes(binding)) {
        previousCellData = cellData;
        handlerAddFilterData(s, e, filterIndex, selectedFirst, store.state.registerData, branchData);
      }
      // block edit for fuel column by type of DB basic unit type master
      if (['load_capacity_maximum', 'loading_data_rate', 'fuel', 'loading_data_source'].includes(binding)) {
        if(!item?.wsu_source) return
        const isDisableFuelColumns = item?.wsu_unit !== TKM_UNIT_SOURCE

        if(isDisableFuelColumns) {
          e.cancel = true;
        } else { //case wsu_unit === TKM_UNIT_SOURCE => check type db (type 0 can not edit load_capacity_maximum, loading_data_rate)
          const dbStore = getNewBbStore()
          const dataStore = dbStore.getDbCustomizeOrDbMasterById;
          const dbItem = dataStore[item?.wsu_source]
          const outOfTkmBasicList = dbItem?.type_db === DB_TYPE.MASTER && !isTkmBasicUnitName(dbItem?.name_basic)
          
          if([DB_TYPE.IDEA, DB_TYPE.CUSTOMIZE].includes(dbItem?.type) || outOfTkmBasicList) { // block edit load_capacity_maximum, loading_data_rate with db IDEA, CUSTOMIZE
            if(['load_capacity_maximum', 'loading_data_rate'].includes(binding)) e.cancel = true
          } else { // fuel auto fill from load_capacity_maximum, loading_data_rate => block edit fuel 
            if(binding === 'fuel') e.cancel = true
          }
        }
      }
    });
    flexgrid.formatItem.addHandler((s, e) => {
      const colBinding = e.panel.columns[e.col].binding;
      if (e.panel == s.cells && ['load_capacity_maximum', 'loading_data_rate', 'fuel', 'loading_data_source'].includes(colBinding)) {
        const rowData = s.rows[e.row]._data
        const isDisableFuelColumns = rowData?.wsu_source && rowData?.wsu_unit !== TKM_UNIT_SOURCE
        const outOfTkmBasicList = rowData?.type_db === DB_TYPE.MASTER && !isTkmBasicUnitName(rowData?.name_basic)
        if(!rowData?.wsu_source) return

        if(isDisableFuelColumns) {
          wjcCore.addClass(e.cell, "is-admin-read-only");
        }

        if(rowData?.type_db === DB_TYPE.MASTER && colBinding === 'fuel' && isTkmBasicUnitName(rowData?.name_basic)) { // case DB MASTER
          wjcCore.addClass(e.cell, "auto-increment");
        } 

        if(([DB_TYPE.IDEA, DB_TYPE.CUSTOMIZE].includes(rowData?.type_db) || outOfTkmBasicList) && ['load_capacity_maximum', 'loading_data_rate'].includes(colBinding)) {// case db customize, IDEA
          wjcCore.addClass(e.cell, "is-admin-read-only");
        }
      }

    });
  },

 header(dataProps) {
    const { 
      registerData,
      listLayout,
      branchData,
      externalSourcePopup,
      attachFilePopup,
      serviceLinkPopup,
      dbStore,
      grid,
      isRemoveStatusColumn,
      basicUnitSearchPopup,
      uniqueOriginalDb,
      isPastingDbIdea = false,
      basicUnitData,
      typeDbItemAddNew,
      uniqueOriginalAllDbUnit,
      distanceSearchPopup
    } = dataProps

    let companyMap = new wjGrid.DataMap(branchData.company_name, 'value', 'value');
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    let businessMap = new wjGrid.DataMap(branchData.business_name, 'value', 'value');
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = branchData?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = branchData?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    const listDbAddNew = getListDbAddNew(dbStore.dbExternalAdded) // get all db add new
    const dbList = uniqueOriginalAllDbUnit?.length > 0 ? uniqueOriginalAllDbUnit : [ ...uniqueOriginalDb, ...listDbAddNew ]

    return [
      {
        header: '連番',
        binding: 'id', // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
        isRequired: false,
      },
      {
        header: i18n.t('56_pattern.table_organizational_division'),
        binding: 'organizational_division', // phan loai to chuc
        minWidth: getWidthByTextContent(i18n.t('56_pattern.table_organizational_division')),
        maxWidth: 400,
        allowResizing: true,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        cssClassAll: 'required-field',
        dataMap: new wjGrid.DataMap(registerData.listType, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: registerData.listType,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: i18n.t('56_pattern.table_company_name'),
        binding: 'company_name',
        minWidth: getWidthByTextContent(i18n.t('56_pattern.table_company_name')),
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        cssClassAll: 'required-field',
        dataMap: companyMap,
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: branchData?.company_name,
          selectedValuePath: 'value',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      ...listLayout,
      {
        header: i18n.t('56_pattern.table_exhaust_original'),
        align: 'center',
        width: 400,
        columns: [
          {
            header      : i18n.t('basic_unit_library.label_search_basic_unit'),
            binding     : "basic_unit_search",
            maxWidth    : getWidthOfSearchButton(i18n.locale),  
            minWidth    : getWidthOfSearchButton(i18n.locale),  
            allowSorting: false,
            isRequired  : false,
            wordWrap    : true,
            cssClassAll : "search-detail hide-filter",
            cellTemplate: CellMaker.makeButton({
              text : 
                `<div class="search-container">
                  ${i18n.t('register_data.button_search')}
                  <img src="${require('@/assets/icons/pulldown/search-external.svg')}" alt="search icon" class="search-icon">
                </div>`,
              click: (e, ctx) => basicUnitSearchPopup(ctx, 'wsu_source')
            }),
          },
          {
            header: i18n.t('basic_unit_library.basic_unit_name'), // Đơn vị nguồn thải (source)
            binding: 'wsu_source',
            minWidth: 300,
            maxWidth: 950,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClassAll: 'required-field',
            dataMap: new wjGrid.DataMap(dbList, 'id', 'source'),
            editor: new AutoComplete(document.createElement('div'), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: dbList,
              isDroppedDownChanged: (sender) => {
                handleBasicUnitDroppedDownChanged({sender, dataProps})
              },
              lostFocus: (sender, ctx) => {
                handleLostFocusBasicUnitPulldown({sender, ctx, dataProps});
                searchID = handleLostFocus({ ctx, sender, dbStore, registerData, listLayout, branchData, externalSourcePopup, attachFilePopup, serviceLinkPopup, grid, isRemoveStatusColumn, uniqueOriginalDb, basicUnitData }, partern2.header)
              },
              gotFocus: (sender) => {
                handleGotFocusBasicUnitPulldown({sender, dataProps})
              },
              selectedValuePath: 'id',
              displayMemberPath: 'source',
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1
            }),
          },
          {
            header: i18n.t('56_pattern.table_wsu_value'), // Đơn vị nguồn thải
            binding: 'wsu_value',
            minWidth: 70,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_wsu_unit'), // Đơn vị nguồn thải (unit)
            binding: 'wsu_unit',
            minWidth: 70,
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClass: 'auto-increment',
            cssClassAll: 'required-field',
          }
        ],
      },
      {
        header: i18n.t('56_pattern.table_loaded_data'),
        align: 'center',
        columns: [
          {
            header: i18n.t('tkm_pattern_table.maximum_loading_capacity'),
            binding: 'load_capacity_maximum',
            minWidth: getWidthByTextContent(i18n.t('tkm_pattern_table.maximum_loading_capacity')),
            maxWidth: 400,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            align: 'right'
          },
          {
            header: i18n.t('56_pattern.table_loading_data_rate'), // Loại xe thuộc đối tượng
            binding: 'loading_data_rate',
            minWidth: getWidthByTextContent(i18n.t('56_pattern.table_loading_data_rate')),
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right'
          },
          {
            header: i18n.t('tkm_pattern_table.fuel_usage_intensity'),
            binding: 'fuel',
            minWidth: getWidthByTextContent(i18n.t('tkm_pattern_table.fuel_usage_intensity')),
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right'
          },
          {
            header: i18n.t('56_pattern.table_loading_data_source'), //source
            binding: 'loading_data_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
        ],
      },
      {
        header: i18n.t('56_pattern.table_transportation_data'),
        align: 'center',
        columns: [
          {
            header: i18n.t('tkm_pattern_table.table_loading_data_maximum'),
            binding: 'loading_data_maximum',
            minWidth: getWidthByTextContent(i18n.t('tkm_pattern_table.table_loading_data_maximum')),
            maxWidth: 600,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header      : i18n.t('tkm_pattern_table.distance_search'),
            binding     : "distance_search",
            maxWidth    : getWidthOfSearchButton(i18n.locale),  
            minWidth    : getWidthOfSearchButton(i18n.locale),  
            allowSorting: false,
            isRequired  : false,
            wordWrap    : true,
            cssClassAll : "search-detail hide-filter search-tkm",
            cellTemplate: CellMaker.makeButton({
              text : 
                `<div class="search-container">
                  ${i18n.t('register_data.button_search')}
                  <img src="${require('@/assets/icons/pulldown/search-external.svg')}" alt="search icon" class="search-icon">
                </div>`,
                click: (e, ctx) => distanceSearchPopup(e, ctx)
            }),
          },
          {
            header: i18n.t('tkm_pattern_table.place_of_departure'),
            binding: 'departure_location',
            minWidth: getWidthByTextContent(i18n.t('tkm_pattern_table.place_of_departure')),
            maxWidth: 600,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
          {
            header: i18n.t('tkm_pattern_table.place_of_arrival'),
            binding: 'arrival_location',
            minWidth: getWidthByTextContent(i18n.t('tkm_pattern_table.place_of_arrival')),
            maxWidth: 600,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
          {
            header: i18n.t('56_pattern.table_transport_distance'), // Energy quantity data (numerical value)
            binding: 'distance',
            minWidth: getWidthByTextContent(i18n.t('56_pattern.table_transport_distance')),
            maxWidth: 600,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_distance_source'), //source
            binding: 'distance_source',
            minWidth: 83,
            maxWidth: 980,
            allowSorting: false,
            wordWrap: true,
            isRequired: false,
            multiLine: true,
          },
        ]
      },
      {
        header: i18n.t('tkm_pattern_table.table_memo_1'), // memo1
        binding: 'memo_1',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: i18n.t('tkm_pattern_table.table_memo_2'), // memo2
        binding: 'memo_2',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: i18n.t('56_pattern.table_emissions'),
        binding: 'emissions',
        cssClass: 'auto-increment',
        cssClassAll: 'emission',
        align: 'right',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
      },
      {
        header      : i18n.t('56_pattern.table_attach_file'),
        binding     : "attach_file",
        cssClass: 'auto-increment',
        minWidth    : getWidthOfAttachedButton(i18n.locale),
        maxWidth    : getWidthOfAttachedButton(i18n.locale),
        allowSorting: false,
        isRequired  : false,
        cssClassAll: "btn-db attach-file hide-filter",
        cellTemplate: (ctx, el) => {
          let text = i18n.t('56_pattern.table_attach_file');
          if (ctx.item?.evidence) {
            text = `<div class="file-attached-container">
              <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
            </div>`
          }
          const button = CellMaker.makeButton({
            text: text,
            click: (e, context) => attachFilePopup(e, context)
          })
          return button(ctx, el);
        } 
      },
      {
        header: i18n.t('user_management.table_status'),
        binding: "status",
        minWidth: 100,
        width: 100,
        allowSorting: false,
        isRequired  : false,
        wordWrap: true,
        cssClassAll: 'status-field',
      }
    ];
  },

  handlerDataOnTable(dataProps) {
    const {
      dataTable,
      bindingLayout,
      isSaveTemplate,
      branchData,
      isGetBranchDetail,
      dbStore,
      itemAdded
    } = dataProps
    let databaseTypeObject = {};
    const wsuSource = dataTable.wsu_source;
    if(itemAdded) {
      databaseTypeObject = itemAdded
    } else {
      databaseTypeObject = dbStore.getDbCustomizeOrDbMasterById[wsuSource] || {};
      dataTable.idDbExternal = null
      dataTable.db_relation = null
    }
    dataTable.wsu_source = databaseTypeObject?.id || null;
    dataTable.wsu_unit = databaseTypeObject.unit_source || null;
    dataTable.wsu_value = formatNumberRealNum(databaseTypeObject?.value_source) || formatNumberRealNum(databaseTypeObject.wsu_value) || null;
    dataTable.type_db = databaseTypeObject?.type || 0 // for format row
    dataTable.name_basic = databaseTypeObject?.name_basic || databaseTypeObject?.item_name

    const outOfTkmBasicList = dataTable?.type_db === DB_TYPE.MASTER && !isTkmBasicUnitName(dataTable?.name_basic)
    if([DB_TYPE.IDEA, DB_TYPE.CUSTOMIZE].includes(databaseTypeObject?.type) || outOfTkmBasicList) { //reset data
      dataTable.load_capacity_maximum = null
      dataTable.loading_data_rate = null
    }

    if(dataTable?.wsu_unit !== TKM_UNIT_SOURCE) {
      dataTable.fuel = null
      dataTable.loading_data_source = null
    }
    if(databaseTypeObject?.type === DB_TYPE.MASTER && isTkmBasicUnitName(dataTable?.name_basic)) { // case DB MASTER
      if(dataTable.loading_data_rate && dataTable.load_capacity_maximum) {
        dataTable.fuel = calcFuelVal(dataTable.loading_data_rate, dataTable.load_capacity_maximum, databaseTypeObject?.item_name)
      }
    } 
    handleCalcRateAndFuelTkmMethod(dataTable, databaseTypeObject, outOfTkmBasicList)
    if(!isGetBranchDetail) return

    const emissions = calcEmissions(dataTable.wsu_value, dataTable.loading_data_maximum, dataTable.distance, dataTable)
    // auto set data for layout
    const layoutsData = {}
    bindingLayout.forEach(key => {
      layoutsData[key] = dataTable[key] || null
    })
    const branchId = isGetBranchDetail ? getBranchId(branchData.filterPatternList, {
      organizational_division: dataTable.organizational_division,
      company_name: dataTable.company_name,
      ...layoutsData
    }) : {};

    if (typeof dataTable.evidence === 'string') { 
      dataTable.evidence = JSON.parse(dataTable.evidence);
    }
    let dataTableItemCustom = {
      id: dataTable.id,
      company_name: dataTable.company_name || null,
      organizational_division: dataTable.organizational_division || null,
      emissions: formatEmission25Number(emissions),
      fuel: isSaveTemplate ? '' : formatNumberBySetting(dataTable.fuel) === 0 ? '0' : formatNumberBySetting(dataTable.fuel) || null,
      loading_data_maximum: isSaveTemplate ? '' : formatNumberBySetting(dataTable.loading_data_maximum) === 0 ? '0' : formatNumberBySetting(dataTable.loading_data_maximum) || null,
      loading_data_rate: isSaveTemplate ? '' : formatNumberBySetting(dataTable.loading_data_rate) === 0 ? '0' : formatNumberBySetting(dataTable.loading_data_rate) || null,
      distance: isSaveTemplate ? '' : formatNumberBySetting(dataTable.distance) === 0 ? '0' : formatNumberBySetting(dataTable.distance) || null,
      distance_source: dataTable.distance_source || null,
      loading_data_source: dataTable.loading_data_source || null,
      contractor_id: store.state.userData.contractor, // from store
      month: dbStore.isFlagUpdateDataListEmission ? dataTable.month : store.state.registerData.month, // from store
      year: dbStore.isFlagUpdateDataListEmission ? dataTable.year : store.state.registerData.yearSelected,
      db_customize_id: databaseTypeObject.origin_id || null,
      db_master_id: databaseTypeObject.origin_id || null,
      type: databaseTypeObject.type,
      memo_1: dataTable.memo_1 || null,
      memo_2: dataTable.memo_2 || null,
      ...layoutsData,
      branch_id: branchId?.id || null,
      db_relation: dataTable.db_relation ||  null,
      evidence: dataTable.evidence || null,
      status: dataTable.status ? 1 : 0,
      load_capacity_maximum: isSaveTemplate ? '' : formatNumberBySetting(dataTable.load_capacity_maximum) === 0 ? '0' : formatNumberBySetting(dataTable.load_capacity_maximum) || null,
      departure_location: dataTable.departure_location || null,
      arrival_location: dataTable.arrival_location || null,
      wsu_unit: dataTable.wsu_unit,
    };
    if (dataTable.evidence) {
      if (dataTable.is_primary_data) {
        delete dataTable.evidence.value;
      }
      else {
        dataTableItemCustom.evidence.value = dataTable[this.bindingValueColumn];
      }
    }
    
    if (dataTableItemCustom.type > 0) {
      dataTableItemCustom.db_customize_id = null
    } else {
      dataTableItemCustom.db_master_id = null
    }
    if(dataTableItemCustom.type === DB_TYPE.SERVICE_LINK || dataTableItemCustom.type === DB_TYPE.IDEA || dataTable.idDbExternal) {
      const dbRelationKey = getDbRelationKeysByType(dataTableItemCustom.type);
      dataTableItemCustom.db_relation = {
        [dbRelationKey]: databaseTypeObject.origin_id
      }
      // dataTableItemCustom.type = 2
      dataTableItemCustom.db_customize_id = null
      dataTableItemCustom.db_master_id = null
    }

    return dataTableItemCustom;
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: [
    'organizational_division',
    'company_name',
    'business_name',
    'energy_type',
    'unit',
    'wsu_unit',
    'wsu_source',
  ],
  getError(item, propName, dbStore, validateErrorBtn = {}) {
    const {itemsInView = [], branchData = [], isCheckMappingFacility = false, itemsInViewError = []} = validateErrorBtn;

    let columnValidateNull = notNullList
    let columnValidateNumber = numberField
    let columnValidate10Charactor = max10Charactor
    let columnValidate25Charactor = max25Charactor
    let columnValidate128Charactor = max128Charactor
    const outOfTkmBasicList = item?.type_db === DB_TYPE.MASTER && !isTkmBasicUnitName(item?.name_basic)

    //add more handle validate fill tkm for DB_TYPE.IDEA, DB_TYPE.CUSTOMIZE or db master out of list
    if(item?.wsu_unit === TKM_UNIT_SOURCE && ([DB_TYPE.IDEA, DB_TYPE.CUSTOMIZE].includes(item?.type_db) || outOfTkmBasicList)) {
      columnValidateNull = notNullByCondition.notNullListTkmDbCustomize
      columnValidate25Charactor = max25CharactorByCondition.max25CharactorTkmDbCustomize
      columnValidateNumber = numberFieldBycondition.numberFieldDbCustom
    }
    if(item?.wsu_unit === TKM_UNIT_SOURCE && item?.type_db === DB_TYPE.MASTER && !outOfTkmBasicList) { //add more handle validate fill tkm for basic unit
      columnValidateNull = notNullByCondition.notNullListTkmBasicUnit
      columnValidate10Charactor = max10CharactorBycondition.max10CharactorTkmBasicUnit
      columnValidate25Charactor = max25CharactorByCondition.max25CharactorTkmBasicUnit
      columnValidate128Charactor = max128CharactorByConditon.max128CharactorTkmBasicUnit
      columnValidateNumber = numberFieldBycondition.numberFieldTkmBasic
    }

    if (columnValidateNull.includes(propName)) {
      if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (columnValidateNumber.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL
      } else {
        return validateNumberFieldBySetting(String(item[propName]), propName);
      }
    }

    if (columnValidate25Charactor.includes(propName)) {
      if (columnValidateNumber.includes(propName)) {
        return validateNumberFieldBySetting(String(item[propName]), propName);
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 25);
    }

    if (columnValidate128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }

    if (columnValidate10Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 10);
    }

    if(isCheckMappingFacility && filterColumns.includes(propName)) {
      const layoutsData = {}
      itemsInView.forEach(key => {
        layoutsData[key] = item[key] || null
      })
      const branchId = getBranchId(branchData.filterPatternList, {
        organizational_division: item.organizational_division,
        company_name: item.company_name,
        ...layoutsData
      });

      if(!branchId) {
        return i18n.t('new_validate.error_mapping_layer', { listLayer: itemsInViewError.map(item => item)?.join('/') })
      }
    }
    return null
  },

  cellInputInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'wsu_value',
      'wsu_unit',
      'wsu_source',
      'fuel',
      'memo_1',
      'memo_2',
      'loading_data_maximum',
      'loading_data_rate',
      'loading_data_source',
      'distance',
      'distance_source',
      'attach_file',
      'load_capacity_maximum',
      ...bindingLayout
    ];
  },

  cellRequireInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'wsu_value',
      'wsu_unit',
      'wsu_source',
      ...bindingLayout
    ];
  },

  autoFields: [
    'wsu_value',
    'wsu_unit',
    'wsu_source',
  ],

  patternUrl: '/pattern-s3-c4/5',
  valueSource: 'wsu_source',

  calcEmissions(rowData) {
    return calcEmissions(rowData.wsu_value, rowData.loading_data_maximum, rowData.distance, rowData)
  },

  handleResizeGrid(theGrid, listColumn, listWidth, callBack) {
    let paddingCell = 28

    listColumn.forEach((column, index) => {
      let width = listWidth[index]
      if(width < column.minWidth) {
        width = column.minWidth
        if(column.dataMap) {
          width = column.minWidth - paddingCell
        }
      } else if (width > column.maxWidth) {
        width = column.maxWidth
      }
      callBack(theGrid, index, column.dataMap ? width + paddingCell : width);
    })
  },

  handleResizeWhenCellEdit(theGrid, event, callBackGetWidth, callBackSetWidth) {
    const paddingCell = 28;
    const {row, col} = event.range;
    const cellData = theGrid.getCellData(row, col, false);
    if(!cellData) return 

    const column = theGrid.columnHeaders.columns[col];
    const isDataMapCell = theGrid.columnHeaders.columns[col]?.dataMap;
    let widthCellEditing = isDataMapCell ? callBackGetWidth(cellData) + paddingCell : callBackGetWidth(cellData);
    if(column.binding === 'wsu_source') {
      widthCellEditing = callBackGetWidth(store.getters['registerData/getDbCustomizeOrDbMasterById'][cellData]?.source) + paddingCell
    }
    if(listMaxWidths[col] < widthCellEditing) {
      callBackSetWidth(theGrid, col, widthCellEditing);
      listMaxWidths[col] = widthCellEditing
    }
  },

  handleResizeWhenPasted(theGrid, event, columns, callBackGetWidth, callBackSetWidth) {
    const {row, row2} = event.range;
    const source = theGrid.collectionView.sourceCollection.slice(row, row2);
    const listDataDb = store.getters['registerData/getDbCustomizeOrDbMasterById']

    columns.forEach((column, indexColumn) => {
      let indexMaxLengthOfColumn = source.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = source[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        let curentDataInView = source[indexMaxLengthOfColumn]
        if(column.binding === 'wsu_source') {
          dataLength = listDataDb?.[rowData.wsu_source]?.source?.toString()?.length
          dataLengthInView = listDataDb?.[curentDataInView.wsu_source]?.source?.toString()?.length
        }
        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = source[indexMaxLengthOfColumn];
      let contentMaxWidth = column.binding === 'wsu_source' ? listDataDb?.[dataMaxLengthInview?.wsu_source]?.source : dataMaxLengthInview?.[column.binding]
      const widthOfCellPasteingMaxlengh = callBackGetWidth(contentMaxWidth)

      if(listMaxWidths[indexColumn] < widthOfCellPasteingMaxlengh) {
        callBackSetWidth(theGrid, indexColumn, widthOfCellPasteingMaxlengh)
        listMaxWidths[indexColumn] = widthOfCellPasteingMaxlengh
      }
    })
  },
  bindingValueColumn: 'distance',

  setFilterIndex(filterIndexValue) {
    filterIndex = filterIndexValue;
  },
  updateFilterIndex(filterIndexValue) {
    filterIndex = {...filterIndex, ...filterIndexValue }
  },
  columnsCalcEmission: ['wsu_value', 'distance', 'loading_data_maximum', 'loading_data_rate'],
  isUseBasicUnit: true,
  getAutoBindingDbSource(dataProps) {
    return autoBindingDbSource(dataProps);
  }
};

export default partern2;
