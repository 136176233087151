<template>
  <div class="main main-login">
    <Supplier v-if="isSuplier" :isSuplier="isSuplier" />
    <div v-else class="wrap">
      <div class="login-form" :class="$i18n.locale">
        <div class="left_wrap left_wrap-login">
          <p>Welcome to</p>
          <div class="image-title">
          </div>
        </div>
        <div v-if="!isBanner" class="center_wrap">
          <span style="white-space: pre-line;">{{ $t("banner_login.label_description_contact") }}</span>
          <!-- <span>{{ $t("login.banner_text_description_2") }}</span> -->
        </div>
        <div class="right_wrap" :class="{'has-description' :!isBanner}">
          <v-form class="right_block log_frm" @submit.prevent="submitForm" ref="form" lazy-validation>
            <h1 class="title-text mb-45">
              <span class="title-text-style">{{ $t("login.title_login") }}</span>
            </h1>
            <div class="input-block mb-48">
              <span class="input-title">{{ $t("login.label_email") }}</span>
              <input id="email" class="input-elic mb-0" v-model="email" @focus="handleFocusEmail" @blur="handleBlurEmail" />
              <img
                v-if="email"
                class="clear-data-input-search"
                :src="iconClose"
                alt="close"
                @click="clearDataTitleModel"
                @mouseenter="isActiveCloseIcon = true"
                @mouseleave="isActiveCloseIcon = false"
              />
              <span class="err-mess" style="display: none" id="email_error"></span>
              <img
                id="email_img"
                class="validWarning"
                src="/img/icons/warning.svg"
                style="position: absolute; right: 6px; display: none"
              />
            </div>
            <div class="input-password-block">
              <div class="input-password-block-parts mt-0 mb-0">
                <div class="input-block">
                  <span class="input-title">{{ $t("login.label_password") }}</span>
                  <div class="passBox">
                    <input
                      id="password"
                      v-model="password"
                      class="input-elic mb-0"
                      :type="!showPass ? 'password' : 'text'"
                      @focus="handleFocusPass"
                      @blur="handleBlurPass"
                    />
                    <eye-icon :id="'password_eye'" :changeIcon="() => (this.showPass = !this.showPass)" />
                    <span class="err-mess" style="display: none" id="password_error"></span>
                    <img
                      id="password_img"
                      class="validWarning"
                      src="/img/icons/warning.svg"
                      style="position: absolute; right: 6px; display: none"
                    />
                  </div>
                </div>
                <div class="forgot-block">
                  <span class="forgot-block-link">
                    {{ $t("login.label_forgot_password") }} <a class="forgot-block-link-text" @click="handleCheckLockPass">{{ $t("login.hyperlink_click_here") }}</a><span :class="checkLang"></span></span
                  >
                </div>
              </div>
            </div>

            <input class="btn-submit" type="submit" :disabled="disabled" :value="loginText" />
          </v-form>
        </div>
      </div>
      <div v-if="isBanner" class="banner" :class="$i18n.locale">
        <div class="banner-wrapper">
          <div class="banner-wrapper-top" :style="{background: backgroundImg}">
            <div class="item-right">
              <p class="item-right-description">{{ $t("banner_login.title_plan") }}</p>
              <div v-if="$i18n.locale === 'en'" class="item-right-title"><p class="title-big">{{ $t("banner_login.label_author") }}</p></div>
              <div v-else class="item-right-title">
                <p class="title-big">GX</p>
                <p class="title-medium">リーダー</p>
                <p class="title-small">TM</p>
                <p class="title-medium">顧問</p>
              </div>
            </div>
          </div>
          <div class="banner-wrapper-bottom">
            <div class="banner-wrapper-bottom-content">
              <div class="bottom-description" :class="$i18n.locale">
                {{ $t("banner_login.label_description") }} 
              </div>
              <ul class="bottom-list" :class="$i18n.locale">
                <li class="bottom-list-item">{{ $t("banner_login.label_category1") }} </li>
                <li class="bottom-list-item">{{ $t("banner_login.label_category2") }}</li>
                <li class="bottom-list-item">{{ $t("banner_login.label_category3") }}</li>
              </ul>
              <button class="button-help" :class="$i18n.locale" @click="redirectToLogin()">{{ $t("banner_login.button_contact_us") }}</button>
            </div>
          </div>
        </div>
      </div>
      <error-popup :dialog="dialog" :message="message" @submit="handleClose" />
      <reset-pass-popup :dialog="showCheckTokenError" :message="errorMessage" @submit="handleCloseReset()" />
      <notification-popup :confirmText="'OK'" :dialog="isVerifyEmailOk" :message="$t('2FA.popup_inform_verify_email_ok')" @submit="isVerifyEmailOk = false" />
    </div>
  </div>
</template>

<script>
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import NotificationPopup from '@/components/dialogs/notification-popup.vue'
import ResetPassPopup from '@/components/dialogs/reset-pass-token-popup.vue';
import validate from '@/utils/validate';
import { loginApi, checkBlockAccApi, handleVerifyEmail } from '@/api/auth';
import { ROUTES } from '@/router/constants';
import { validateRequired } from '@/api/others';
import EyeIcon from '@/components/commonApp/EyeIcon.vue';
import { getErrorMessge } from '@/utils/messageHandle';
import { mapGetters } from 'vuex';
import InputField from '@/components/products/input/InputField.vue';
import { getClassesUnderline } from '@/utils/multiLanguage.js';
import { getInforPrimaryDataApiBeforeLogin } from '@/api/supplier';
import { mapActions } from 'vuex';
import Supplier from '@/components/login/supplier';
export default {
  data: () => ({
    showPass: false,
    dialog: false,
    message: '',
    email: '',
    emailRules: [(v) => !!v || 'error', (v) => validate.regexMail.test(v)],
    password: '',
    passwordRules: [(v) => !!v, (v) => validate.regexPassword.test(v)],

    showCheckTokenError: false,
    isActiveCloseIcon: false,
    bannerDisplay: 'flex',
    isVerifyEmailOk: false,
    isResetPassword: false,
    disabled: false
  }),
  computed: {
    ...mapGetters({
      errorMessage: 'userData/getMessage',
    }),
    iconClose() {
      if (!this.isActiveCloseIcon) {
        return require('@/assets/icons/product/close-input.svg');
      }
      return require('@/assets/icons/product/close-input-active.svg');
    },
    loginText() {
      return this.$t('login.button_login');
    },
    checkLang() {
      return getClassesUnderline(this.$i18n.locale);
    },
    isSuplier() {
      return this.$route?.query?.primary_uuid;
    },
    isBanner() {
      return this.$route?.query?.banner != "true";
    },
    backgroundImg() {
      if (this.$vuetify.breakpoint.width < 1024) return `url(${require('@/assets/images/login/bg-login-mobile.jpg')}) lightgray 50% / cover no-repeat`;
      return `url(${require('@/assets/images/login/bg-login-desktop.jpg')}) lightgray 50% / cover no-repeat`;
    },
  },

  created() {
    // if (localStorage.getItem('language')) this.$i18n.locale = localStorage.getItem('language');
    const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
    const locale = urlParams.get('locale');
    if(locale) {
      this.$i18n.locale = locale
    } else {
      if (localStorage.getItem('language')) this.$i18n.locale = localStorage.getItem('language');
    }
    // Only use isRestPassword for the first time
    if (sessionStorage.getItem('isRestPassword')) {
      this.isResetPassword = true;
      sessionStorage.removeItem('isRestPassword');
    }
  },

  mounted() {
    if (this.errorMessage) {
      this.showCheckTokenError = true;
    }
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        this.handleClose();
      }
    });
    const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
    const id = urlParams.get('id');
    const email = urlParams.get('email');

    // handle verify email
    if (id && email) {
      const params = {
        id: id,
        email: email,
      };
      handleVerifyEmail(params).then(() => {
        this.isVerifyEmailOk = true;
        this.isResetPassword = true;
      }).catch((er) => {
        console.log('ERR', er);
      });
    }

    this.isVerifyEmailOk = sessionStorage.getItem('isVerifyEmailOk') || false;
    if (sessionStorage.getItem('isVerifyEmailOk')) {
      this.isResetPassword = true;
      sessionStorage.removeItem('isVerifyEmailOk');
    }

    //handle check valid suplier or not
    const uuidPrimaryData = this.$route?.query?.primary_uuid;
    if (uuidPrimaryData) {
      // todo: call api check primary_uuid
      this.handleCheckPrimaryDataById(uuidPrimaryData);
    }
  },

  methods: {
    ...mapActions('supplier', ['setPrimaryDataForm']),
    clearDataTitleModel() {
      this.email = '';
      this.isActiveCloseIcon = false;
    },
    submitForm() {
      this.disabled = true;
      if (!validateRequired(['email', 'password'])) {
        this.disabled = false;
        return;
      }

      const payloadLogin = {
        email: this.email?.toLowerCase(),
        password: this.password.trim(),
        primary_uuid: this.$route?.query?.primary_uuid,
        is_bypass_otp: this.isResetPassword
      }
      if(this.$route?.query?.locale) {
        payloadLogin.locale = this.$route?.query?.locale
      }
      loginApi(payloadLogin)
        .then((res) => {
          if (res?.is_sending_otp) {
            sessionStorage.setItem("email", this.email);
            sessionStorage.setItem("password", this.password);
            sessionStorage.setItem("isGenerateOTP", true);
            this.$router.push({ path: `${ROUTES.AUTH}/${ROUTES.VERIFY_OTP}`, query: { ...this.$route.query } });
          } else if (!res?.user?.status) {
            this.dialog = true;
          } else {
            if (res.user?.language?.code !== this.$i18n.locale) {
              localStorage.setItem('language', res.user?.language?.code);
              location.reload();
            }
            this.$router.push({ path: ROUTES.TYPE_SELECTION, query: {...this.$route.query} });
            localStorage.setItem('hasGroupsImporting', true)
          }
        })
        .catch((err) => {
          if (err.code === 'block_account') {
            this.message = err.message;
          } else {
            this.message = getErrorMessge(err);
          }
          this.dialog = true;
        }).finally(() => {
          this.disabled = false;
        });
    },
    handleClose() {
      this.password = '';
      this.email = '';
      this.dialog = false;
    },
    handleCloseReset() {
      this.showCheckTokenError = false;
      this.$store.dispatch('userData/updateMessage', '');
    },

    handleCheckLockPass() {
      if (this.email && validate.regexMail.test(this.email)) {
        checkBlockAccApi({ email: this.email })
          .then(() => {
            this.$router.push({ path: '/auth/forgot-password' });
          })
          .catch((err) => {
            this.message = getErrorMessge(err);
            this.dialog = true;
          });
      } else {
        this.$router.push({ path: '/auth/forgot-password', query: this.$route.query });
      }
    },
    handleFocusEmail() {
      const emailError = document.getElementById('email_error');
      const emailImgError = document.getElementById('email_img');
      const email = document.getElementById('email');
      email.style.border = '2px solid #327998';
      emailError.style.display = 'none';
      emailImgError.style.display = 'none';
    },
    handleFocusPass() {
      const password_img = document.getElementById('password_img');
      const password_error = document.getElementById('password_error');
      const password = document.getElementById('password');
      const passwordEye = document.getElementById('password_eye');
      password.style.border = '2px solid #327998';
      password_img.style.display = 'none';
      password_error.style.display = 'none';
      passwordEye.style.display = 'block';
    },
    handleBlurEmail() {
      const email = document.getElementById('email');
      email.style.border = 'none';
    },
    handleBlurPass() {
      const password = document.getElementById('password');
      password.style.border = 'none';
    },
    async handleCheckPrimaryDataById(uuidPrimaryData) {
      //check uuid before login
      try {
        const responseDetail = await getInforPrimaryDataApiBeforeLogin(uuidPrimaryData);
        if (!responseDetail?.data) {
          this.$router.push('/404');
        }
      } catch (error) {
        this.$router.push('/404');
      }
    },
    redirectToLogin() {
      this.bannerDisplay = 'none';
      this.email = '';
      this.password = '';
      const currentQueryParams = { ...this.$route.query };
      currentQueryParams.banner = 'true';
      this.$router.push({ query: currentQueryParams })
    },
  },
  components: {
    ErrorPopup,
    EyeIcon,
    ResetPassPopup,
    InputField,
    Supplier,
    NotificationPopup
  },
  beforeDestroy() {
    document.removeEventListener('keyup', () => {});
  },
};
</script>

<style lang="scss" scoped>
@import './login/index.scss';

.while-color {
  background: #ffffff;
}
.none {
  display: none;
}

.block {
  display: block;
}
.forgot-block {
  margin-top: 16px;
}
.wrap {
  min-height: 270px;
  overflow: auto;
}
.input-block {
  position: relative;
}
.clear-data-input-search {
  position: absolute;
  top: 44px;
  right: 11px;
  cursor: pointer;
}


.banner {
  &.en {
    .banner-wrapper{
      .banner-wrapper-bottom {
        height: 45%;
        .banner-wrapper-bottom-content {
          .bottom-description {
            font-size: 13px;
            letter-spacing: 0.04em;
          }
          .bottom-list {
            font-size: 13px;
            letter-spacing: 1.4px;
            margin-left: 10%;
            margin-bottom: 40px;
            &-item {
              letter-spacing: 0.04em; 
            }
          }
        }
      }
    }
  }
}

@include desktop {
  .banner {
    &.en {
      .banner-wrapper{
        .banner-wrapper-top {
          height: 55%;
          clip-path: polygon(0 0, 100% 0, 100% 91%, 12% 100%);
        }
        .banner-wrapper-bottom {
          .banner-wrapper-bottom-content {
            .bottom-description {
              margin-top: 0;
            }
            .bottom-list {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

@include large-desktop {
  .banner {
    &.en {
      .banner-wrapper{
        .banner-wrapper-bottom {
          .banner-wrapper-bottom-content {
            .bottom-description {
              margin-top: 20px;
            }
            .bottom-list {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@include super-large-desktop {
  .banner {
    &.en {
      .banner-wrapper{
        .banner-wrapper-top {
          height: 60%;
        }
        .banner-wrapper-bottom {
          height: 40%;
        }
      }
    }
  }
}
</style>
