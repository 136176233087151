import * as wjGrid from '@mescius/wijmo.grid';
import store from '@/store';

import { prepareMasterOrCustomizeDB, getDbCustomizeOrDbMasterById, getListDbAddNew, getDbAddNewInObject } from '@/concerns/utils/master-or-customize-db'
import { AutoComplete } from '@mescius/wijmo.input';
import { KEYS_CODE } from '@/constants/keyboard'
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
} from '@/constants/registerData';
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';
import debounce from 'lodash/debounce'
import { formatValue, $_helper_isNumberType, math, formatBigNumber, formatEmission25Number, validateNumberFieldBySetting, $_helper_isNumberType_bySetting, formatNumberBySetting } from '../wijmo.helper'
import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getColumnIndexByBinding, getBranchId, checkFilterData } from '@/concerns/utils/filter-data'
import i18n from '@/lang/i18n.js';
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import { dbIdeaObj } from '@/api/ideaDbSource';
import { handleLostFocus } from '@/utils/csvImportHandler'
import { SCOPE_TYPE, CURRENCY_TYPE, GROUP_COMPANY_KEY } from '@/constants/registerData';
import { getWidthByTextContent } from '@/utils/calcTextWidth';
import { handlerFilterGroupBusinessData, handlerAddGroupBusinessFilterData, handleGetBusinessGroupId, addGroupBusinessFilterByPasted } from '@/concerns/utils/business-groups';
import { toolTipCustom } from "@/utils/tooltipCustom";
import { getWidthOfAttachedButton, getWidthOfSearchButton, getWidthButtonByText } from '@/utils/multiLanguage';
import { formatNumberRealNum } from '@/utils/convertNumber';
import { handleLostFocusBasicUnitPulldown, handleBasicUnitDroppedDownChanged, handleGotFocusBasicUnitPulldown } from '@/utils/registerData';

export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    organizational_division: null,
    business_name: null,
    company_name: null,
    ides_industry: null,
    ides_name_company: null,
    ie_scope: null,
    ie_ghg_emissions: null,
    ie_year: null,
    il_amount: null,
    il_total_amount: null,
    emissions: null,
    attribution_factor: null,
    duration_id: null,
    month: null,
    year: null,
    type: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
    classify_gics: null,
    scope: null,
    category_1: null,
    loan_amount: null,
    currency: null,
    category_2: null,
    total_capital: null,
    value_number: null,
    emissions_factor: null,
    score: null,
    db_relation: null
  }
}

const isZeroValue = value => {
	return ['0.00', '0', 0, null, '', 'null'].includes(value)
}

const calcEmissions = data => {
  let { loan_amount, total_capital, value_number, emissions_factor, isLimitedAttributionFactor = false ,isRemoveEmissionFactorOrValNumber = false, classify_gics, dbStore } = data
  if(isRemoveEmissionFactorOrValNumber) {
    emissions_factor = '1'
  }
  const arrayKeyToCalcEmissions = ['loan_amount', 'total_capital', 'value_number']

  const isAllItemIsNumber = arrayKeyToCalcEmissions.every(key => $_helper_isNumberType_bySetting(data[key])) // check number all value
  if (isAllItemIsNumber && $_helper_isNumberType_bySetting(emissions_factor)) {
    // let val_emissions_factor = null
    // let selectedYear = dbStore.selectedYear
    // if(data?.year && data?.month) { //case list view data approval
    //   selectedYear = data?.year
    // }

    // // get real emission factor by year 
    // if(!isRemoveEmissionFactorOrValNumber) {
    //   const gicObject = dbStore.getFullGicObjectOfAllYear[selectedYear][classify_gics]
    //   val_emissions_factor = math.bignumber(formatValue(gicObject.autoField));
    // } else {
    //   val_emissions_factor = math.bignumber(formatValue(emissions_factor));
    // }
    // define value for bignumber
    const val_amount = math.bignumber(formatNumberBySetting(loan_amount));
    const val_total_capital = math.bignumber(formatNumberBySetting(total_capital));
    const val_value_number = math.bignumber(formatNumberBySetting(value_number));
    const val_emissions_factor = math.bignumber(formatNumberBySetting(emissions_factor));
    const emissionVal = formatBigNumber(math.evaluate(`${val_amount.toString()} / ${val_total_capital.toString()} * ${val_value_number.toString()} * ${val_emissions_factor.toString()}`), 25)

    if(isLimitedAttributionFactor) { //case attribution_factor from 0 => 100
      const isOnlyAmountSmallerZero = math.smaller(val_amount, '0') && !math.smaller(val_total_capital, '0')
      const isOnlyTotalCapitalSmallerZero = !math.smaller(val_amount, '0') && math.smaller(val_total_capital, '0')
      const isAmountAndTotalCapitalSmallerZero = math.smaller(val_amount, '0') && math.smaller(val_total_capital, '0')
      const isAttributionSmallerZeroPercent = isOnlyAmountSmallerZero || isOnlyTotalCapitalSmallerZero
      const isAttributionLarger100Percent =
        math.larger(val_amount, val_total_capital) ||
        (isAmountAndTotalCapitalSmallerZero && math.smaller(val_amount, val_total_capital));

      if(isAttributionSmallerZeroPercent) {
        return '0.00';
      } else if(isAttributionLarger100Percent) {
        return formatBigNumber(math.evaluate(`${val_value_number.toString()} * ${val_emissions_factor.toString()}`), 25)
      } else {
        return emissionVal
      }
    } else {
      return emissionVal
    }
  }

  return '0.00';
}

const notNullList = [
  'organizational_division',
  'company_name',
  // 'pcaf_business_group_name',
	// 'pcaf_business_name',
  'classify_gics',
  'loan_amount',
  'currency',
  'total_capital',
  'scope',
  'value_number',
]

const notNullListIncludeEmissionFactor = [
  'organizational_division',
  'company_name',
  // 'pcaf_business_group_name',
	// 'pcaf_business_name',
  'classify_gics',
  'loan_amount',
  'currency',
  'total_capital',
  'value_number',
  'scope',
  'emissions_factor'
]

const max25Charactor = [
  'value_number',
  'total_capital',
  'loan_amount',
]

const max128Charactor = [
  // 'company_name',
  // 'business_name',
  'scope',
  'classify_gics',
  'memo_1',
  'memo_2',
  'category_1',
  'category_2',
]

const numberField = [
  'value_number',
  'total_capital',
  'loan_amount',
]
const plusWidthMark = 70;
let isReverted = false;
let isBusinessGroupReverted = false;
const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']
let searchID = null;
const ENERGY_CONSUMPTION_OF_THE_BUILDING = '建物の消費エネルギー'
const ESTIMATED_ENERGY_CONSUMPTION_PER_FLOOR_AREA = '床面積毎の推定エネルギー消費量'

const handleAutoFillDefaultValue = dataBinding => {
	const { s, binding, row, currentItem, typeOfPattern, isDeleteTing } = dataBinding

  if (!isDeleteTing) {
    if(!currentItem?.currency ) {
      currentItem['currency'] = CURRENCY_TYPE[0];
    }
    if(!currentItem?.dq_quality_score ) {
      currentItem['dq_quality_score'] = getScoreByType(typeOfPattern.dataTypeIdSelect,typeOfPattern)[0];
    }
  }
}

const autoBindingDbSource = dataBinding => {
  const { s, binding, row, cellData, currentItem, dataStore, isLimitedAttributionFactor, isRemoveEmissionFactorOrValNumber, isCellEditEnd, dbStore, dbAddNewInObject } = dataBinding
  handleAutoFillDefaultValue(dataBinding)

  if (['value_number', 'loan_amount', 'total_capital', 'ides_industry', 'emissions_factor'].includes(binding)) {
    s.deferUpdate(() => {
      const dataForCalcEmissions = {
        loan_amount: currentItem.loan_amount,
        total_capital: currentItem.total_capital,
        value_number: currentItem.value_number,
        emissions_factor: currentItem.emissions_factor,
        isLimitedAttributionFactor,
        classify_gics: currentItem.classify_gics,
        dbStore: dbStore
      }
      currentItem['emissions'] = calcEmissions(dataForCalcEmissions);
    })
  }

  if (binding === 'ides_industry') {
    let dbItem = dbAddNewInObject[cellData] || dataStore[cellData]
    if (searchID && !dbItem) {
      dbItem =  dataStore[searchID]
    }
    currentItem.idDbExternal = dbItem?.type !== 2 ? null : dbItem?.id
    if(dbItem) {
      s.deferUpdate(() => {
        const dataForCalcEmissions = {
          loan_amount: currentItem.loan_amount,
          total_capital: currentItem.total_capital,
          value_number: currentItem.value_number,
          emissions_factor: dbItem?.value_source,
          isLimitedAttributionFactor,
          classify_gics: currentItem.classify_gics,
          dbStore: dbStore
        }
        currentItem['emissions_factor'] = isRemoveEmissionFactorOrValNumber ? 1 : formatNumberRealNum(dbItem?.value_source);
        currentItem['ides_industry'] = dbItem.id;
        currentItem['emissions'] = calcEmissions(dataForCalcEmissions);
      })
    }
  }

  if (binding === 'classify_gics') {
    let gicsList = {}
    if(currentItem?.isApprovalData) { // get gics for case update data approval
      gicsList = dbStore.fullGicsList[currentItem.year]
    } else if(currentItem?.yearTemplate) {
      gicsList = dbStore.fullGicsList[currentItem?.yearTemplate]
    } else {
      const yearStore = store.state.registerData.yearSelected
      gicsList = dbStore.fullGicsList[yearStore] || dbStore.gicsList
    }
    const gicsItem = gicsList?.find(gics => gics.key === cellData);
    if (getColumnIndexByBinding(s, 'emissions_factor') > -1 && (!currentItem.emissions_factor || isCellEditEnd)) { // update emissions_factor if isCellEditEnd true
      currentItem['emissions_factor'] = formatNumberRealNum(gicsItem?.autoField?.substring(0, 25));
    }
    const dataForCalcEmissions = {
      loan_amount: currentItem.loan_amount,
      total_capital: currentItem.total_capital,
      value_number: currentItem.value_number,
      emissions_factor: currentItem.emissions_factor,
      isLimitedAttributionFactor,
      classify_gics: currentItem.classify_gics,
      dbStore: dbStore
    }
    currentItem['emissions'] = calcEmissions(dataForCalcEmissions);
  }
}

const getScoreByType = (typeData, typeOfPattern) => {
  if(!typeData) return [null, null]

  let valueDataType2 = null
  let dq_quality_score = '2'
  switch (typeData) {
    case typeOfPattern.itemsDataType[0]['id']:
      dq_quality_score = '1'
      break;
    case typeOfPattern.itemsDataType[1]['id']:
    case typeOfPattern.itemsDataType[2]['id']:
      dq_quality_score = '2'
      break;
    case typeOfPattern.itemsDataType[3]['id']:
      dq_quality_score = '3'
      break;
    case typeOfPattern.itemsDataType[4]['id']:
      dq_quality_score = '4'
      break;
    case typeOfPattern.itemsDataType[5]['id']:
      dq_quality_score = '5'
      break;
    default:
      dq_quality_score = '5'
      break;
  }
  return [dq_quality_score, valueDataType2]
}
let filterIndex = {};
const parternS3c15pcaf1 = {
  initializeGrid(dataProps) {
    const { 
      flexgrid,
      itemCount,
      branchData = {},
      businessGroupList,
      isLimitedAttributionFactor,
      isRemoveEmissionFactorOrValNumber,
      typeOfPattern,
      getNewBbStore,
      handleDataOnCheckError,
      getDataRowOnCheckError
    } = dataProps


    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};
    let filterBusinessGroupIndex = {};
    let selectedBusinessGroupFirst = null;
    let previousBusinessGroupCellData = null;
		let checkBusinessGroupFilterDataAfterPasted = {};
    const currentYear = new Date().getFullYear();
    const dbStore = getNewBbStore()

    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount

          for (let index = 1; index <= itemCount; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100))

    document.addEventListener('keydown', (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener('keydown', (e) => {
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
          flexgrid.selection = cellRange

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(0, currentSelection.col)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
          flexgrid.selection = cellRange
        }
      }
      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            // flexgrid.itemsSource.clearChanges();
          });
        }
      }

    }, false)

    flexgrid.pasted.addHandler((s, e) => {
      const { col, col2, row, row2 } = e.range
      const view = s.collectionView
      const source = view.sourceCollection
      const dbStore = getNewBbStore()
      const dataStore = dbStore.getDbCustomizeOrDbMasterById
      const emissionsFactorIndex = getColumnIndexByBinding(s, 'emissions_factor');
      const classifyGicsIndex = getColumnIndexByBinding(s, 'classify_gics');
      const dbAddNewInObject = getDbAddNewInObject(getListDbAddNew(dbStore.dbExternalAdded))

      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          const binding = s.columns[colIndex].binding
          const { cellData, currentItem, clientRowId } = handleDataOnCheckError({s, rowIndex, colIndex, colBinding: binding});
          const dataBinding = { s, binding, row: clientRowId, cellData, currentItem, dataStore, isLimitedAttributionFactor, isRemoveEmissionFactorOrValNumber, typeOfPattern, dbStore, isCellEditEnd: false, dbAddNewInObject }

          // add filter index after pasted
          if (filterColumns.includes(s.columns[colIndex].binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s, e, colIndex, rowIndex, filterIndex);
              if (typeof checkFilterDataAfterPasted[rowIndex] === 'undefined') {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }
          if (['value_number', 'loan_amount', 'total_capital', 'emissions_factor', 'classify_gics'].includes(s.columns[colIndex].binding)) {

            autoBindingDbSource(dataBinding)
          }
          handleAutoFillDefaultValue(dataBinding)

          if (GROUP_COMPANY_KEY.includes(s.columns[colIndex].binding)) {
						if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
							addGroupBusinessFilterByPasted(s.columns[colIndex], filterBusinessGroupIndex, rowIndex)
							if (typeof checkBusinessGroupFilterDataAfterPasted[rowIndex] === 'undefined') {
								checkBusinessGroupFilterDataAfterPasted[rowIndex] = {};
							}
							checkBusinessGroupFilterDataAfterPasted[rowIndex] = source[rowIndex];
						}
					}
          if(emissionsFactorIndex > 0) {
            if (binding === "ides_industry") {
              if (!s.getCellData(rowIndex, emissionsFactorIndex, false) && !s.getCellData(rowIndex, classifyGicsIndex, false)) { 
                autoBindingDbSource(dataBinding)
              } else if (emissionsFactorIndex < col || emissionsFactorIndex > col2) {
                autoBindingDbSource(dataBinding)
              } else if (classifyGicsIndex < col || classifyGicsIndex > col2) {
                autoBindingDbSource(dataBinding)
              }
            }
            if (binding === "classify_gics") {
              if (!s.getCellData(rowIndex, emissionsFactorIndex, false)) {
                autoBindingDbSource(dataBinding)
              } else if (emissionsFactorIndex < col || emissionsFactorIndex > col2) {
                autoBindingDbSource(dataBinding)
              }
            }
          }
        }
      }
      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(branchData.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted)
        store.dispatch('registerData/updateListErrorFilterDataMessage', errorMessage)
        checkFilterDataAfterPasted = {}
      }
      getDataRowOnCheckError();
    })

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column = s.columns[e.col];
      const { row, col } = e.range
      const dbStore = getNewBbStore()
      const dataStore = dbStore.getDbCustomizeOrDbMasterById
      const dbAddNewInObject = getDbAddNewInObject(getListDbAddNew(dbStore.dbExternalAdded))
      const isDeleteTing = e.data && (e.data.key === 'Backspace' || e.data.key === 'Delete')
      const binding = column.binding
      const { cellData, currentItem, clientRowId } = handleDataOnCheckError({s, rowIndex: row, colIndex: col, colBinding: binding});
      const dataBinding = { s, binding, row: clientRowId, cellData, currentItem, dataStore, isLimitedAttributionFactor, isRemoveEmissionFactorOrValNumber, typeOfPattern, isCellEditEnd: true, isDeleteTing, dbStore, dbAddNewInObject }
      autoBindingDbSource(dataBinding)

      if (filterColumns.includes(column.binding)) {
        handlerFilterData(s, e, branchData?.filterPatternList, filterIndex, selectedFirst, previousCellData, isReverted);
      }

      if (GROUP_COMPANY_KEY.includes(column.binding)) {
        handlerFilterGroupBusinessData({s, e}, businessGroupList['item'], filterBusinessGroupIndex, selectedBusinessGroupFirst, previousBusinessGroupCellData, isBusinessGroupReverted)
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let column = s.columns[e.col];
      let item = s.rows[e.row].dataItem;
      // const isDisableEmissionFactorAndSourceSelect = item?.type_data === DATA_TYPE[0]['key'] && (['emissions_factor', 'view_detail', 'ides_industry'].includes(column.binding));
      if (['emissions'].includes(column.binding)) {
        e.cancel = true;
      }

      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[Number(item?.clientRowId) - 1 || row] || source[row] || {}

      if (filterColumns.includes(column.binding)) {
        previousCellData = cellData;
        handlerAddFilterData(s, e, filterIndex, selectedFirst, store.state.registerData, branchData);
      }

      if (GROUP_COMPANY_KEY.includes(column.binding)) {
        previousBusinessGroupCellData = cellData;
        handlerAddGroupBusinessFilterData({s, e}, businessGroupList['item'], filterBusinessGroupIndex, selectedBusinessGroupFirst)
      }
    });

    flexgrid?.formatItem.addHandler((s, e) => {
      const colBinding = e.panel.columns[e.col].binding;
      // if (e.panel == s.cells && (['emissions_factor', 'view_detail', 'ides_industry'].includes(colBinding)) && (DATA_TYPE[0]['key'] === rowData?.type_data || DATA_TYPE[0]['value'] === rowData?.type_data)) {
      //   wjcCore.addClass(e.cell, "is-admin-read-only");
      // }

      if (e.panel === s.columnHeaders) {
				if (colBinding === "pcaf_business_group_name" && e.cell.innerText?.split('\n')[1] === i18n.t('56_pattern.table_group_company')) {
					toolTipCustom(
						e,
						"t1",
						i18n.t('56_pattern.tooltip_group_company'), //todo: update text
						dbStore.hdrTips
					);
				}

				// if (colBinding === "category_1" && e.cell.innerText === i18n.t('56_pattern.table_attribution_factor')) {
				// 	toolTipCustom(
				// 		e,
				// 		"t2",
				// 		i18n.t('56_pattern.tooltip_currency'),  //todo: update text
				// 		dbStore.hdrTips
				// 	);
				// }

        if (colBinding === "loan_amount") {
					toolTipCustom(
						e,
						"t4",
						i18n.t('56_pattern.tooltip_loan_amount'),
						dbStore.hdrTips
					);
				}

        if (colBinding === "total_capital") {
					toolTipCustom(
						e,
						"t5",
						i18n.t('56_pattern.tooltip_total_capital'),
						dbStore.hdrTips
					);
        }
        if (colBinding === "emissions_factor") {
					toolTipCustom(
						e,
						"t6",
						i18n.t('56_pattern.tooltip_emission_factor', { year: currentYear}),
						dbStore.hdrTips
					);
				} 
			}
  	});
    flexgrid?.pastingCell.addHandler((s, e) => {
      let column = s.columns[e.col];
      let item = s.rows[e.row]?.dataItem;
      // const isDisableEmissionFactor = item?.type_data === DATA_TYPE[0]['key'] && ['emissions_factor', 'ides_industry'].includes(column.binding)
      // if (isDisableEmissionFactor) {
      //   e.cancel = true;
      // }
    })
  },

  header(dataProps) {
    const { 
      registerData,
      listLayout,
      branchData,
      externalSourcePopup,
      attachFilePopup,
      dbStore,
      grid,
      isRemoveStatusColumn,
      businessGroupList,
      isRemoveEmissionFactorOrValNumber,
      typeOfPattern,
      uniqueOriginalDb,
      isPastingDbIdea = false,
      uniqueOriginalAllDbUnit,
      basicUnitData,
      basicUnitSearchPopup
    } = dataProps

    let companyMap = new wjGrid.DataMap(branchData.company_name, 'value', 'value');
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {
      let validCompany = branchData?.company_name?.filter(
        (company) => company.organizational_division === dataItem,
      );
      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    let businessMap = new wjGrid.DataMap(branchData.business_name, 'value', 'value');
    businessMap.getDisplayValues = (dataItem) => {
      let validBusiness = branchData?.business_name?.filter(
        (company) => company.company_name === dataItem?.company_name,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    businessMap.getDisplay = (dataItem, organizational_division) => {
      let validBusiness = branchData?.business_name?.filter(
        (business) => business.company_name === dataItem && business.organizational_division === organizational_division,
      );

      let uniqueItem = [...new Map(validBusiness.map((item) => [item['name'], item])).values()];
      return uniqueItem.map((item) => {
        return {
          value: item.value,
          key: item.value,
        };
      });
    };

    const current_emissions_type = typeOfPattern.itemsDataType.findIndex(item => item.id === typeOfPattern.dataTypeIdSelect);

    let name_emissions_type_col = typeOfPattern.itemsDataType[current_emissions_type]?.name;
    const typePatternHasMillionYen = [5, 6]
    let headerEmissionFactor = i18n.t('56_pattern.table_emissions_factor')
    if(typePatternHasMillionYen.includes(typeOfPattern.dataTypeIdSelect)) {
      if(i18n.locale !== 'ja') {
        name_emissions_type_col = `${name_emissions_type_col} (${i18n.t('dashboard_main.label_one_million_yen')})`
        headerEmissionFactor = `${i18n.t('56_pattern.table_emissions_factor')} (t-CO2/${i18n.t('dashboard_main.label_one_million_yen')})`
      } else {
        name_emissions_type_col = `${name_emissions_type_col}（${i18n.t('dashboard_main.label_one_million_yen')}）`
        headerEmissionFactor = `${i18n.t('56_pattern.table_emissions_factor')}（t-CO2/${i18n.t('dashboard_main.label_one_million_yen')}）`
      }
    }

    const listDbAddNew = getListDbAddNew(dbStore.dbExternalAdded)
    const dbList = uniqueOriginalAllDbUnit?.length > 0 ? uniqueOriginalAllDbUnit : [ ...uniqueOriginalDb, ...listDbAddNew ]

    return [
      {
        header: '連番',
        binding: 'id', // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
        isRequired: false,
      },
      {
        header: i18n.t('56_pattern.table_organizational_division'),
        binding: 'organizational_division', // phan loai to chuc
        minWidth: 96,
        maxWidth: 980,
        allowResizing: true,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        cssClassAll: 'required-field',
        dataMap: new wjGrid.DataMap(registerData.listType, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: registerData.listType,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: i18n.t('56_pattern.table_company_name'),
        binding: 'company_name',
        minWidth: 85,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        cssClassAll: 'required-field',
        dataMap: companyMap,
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: branchData?.company_name,
          selectedValuePath: 'value',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      ...listLayout,
      {
        header: i18n.t('56_pattern.table_destination'),
        align: 'center',
        columns: [
          {
            header: i18n.t('56_pattern.table_group_company'),
            binding: 'pcaf_business_group_name', //ten doanh nghiep
            width: getWidthByTextContent(i18n.t('56_pattern.table_group_company'), 30),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true,
            dataMap: businessGroupList['pcaf_business_group_name'],
						// cssClassAll: 'required-field',
            editor: new AutoComplete(document.createElement('div'), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: businessGroupList['pcaf_business_group_name'],
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1,
            }),
          },
          {
            header: i18n.t('56_pattern.table_company'),
            binding: 'pcaf_business_name', // todo: update to pull down
            minWidth: 70,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            multiLine: true,
            dataMap: businessGroupList['pcaf_business_name'],
						// cssClassAll: 'required-field',
            editor: new AutoComplete(document.createElement('div'), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: businessGroupList['pcaf_business_name'],
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1,
            }),
          },
        ],
      },
      {
        header: i18n.t('56_pattern.table_gics_value'),
        binding: 'classify_gics',
        minWidth: getWidthByTextContent(i18n.t('56_pattern.table_gics_value')),
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true,
        cssClassAll: 'required-field',
        dataMap: new wjGrid.DataMap(dbStore.gicsList, 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: dbStore.gicsList,
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: i18n.t('56_pattern.table_memo_1'),
        binding: 'memo_1',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true
      },
      {
        header: i18n.t('56_pattern.table_memo_2'),
        binding: 'memo_2',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        wordWrap: true,
        isRequired: false,
        multiLine: true
      },
      {
        header: i18n.t('56_pattern.table_scope'),
        binding: 'scope', // todo: update to pull down
        minWidth: 70,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true,
        cssClassAll: 'required-field',
        dataMap: new wjGrid.DataMap(SCOPE_TYPE),
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: SCOPE_TYPE,
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
      },
      {
        header: i18n.t('56_pattern.table_attribution_factor'), //Attribution factor
        align: 'center',
        columns: [
          {
            header: i18n.t('56_pattern.table_category1'), // hạng mục
            binding: 'category_1',
            minWidth: 160,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
          },
          {
            header: i18n.t('56_pattern.table_amount'), // số tiền cho vay 
            binding: 'loan_amount',
            width: getWidthButtonByText(i18n.t('56_pattern.table_amount'), { fontSize: '13px', fontWeight: 500, letterSpacing: '0.04em', lineHeight: 20 }) + plusWidthMark,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('56_pattern.table_currency'), //tiền tệ 
            binding: 'currency',
            minWidth: 140,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClassAll: 'required-field',
            dataMap: new wjGrid.DataMap(CURRENCY_TYPE),
            editor: new AutoComplete(document.createElement('div'), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: CURRENCY_TYPE,
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1,
            }),
          },
          {
            header: i18n.t('56_pattern.table_category1'), // hạng mục
            binding: 'category_2',
            minWidth: getWidthByTextContent(i18n.t('56_pattern.table_category1')),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
          },
          {
            header: i18n.t('pcaf_update.table_total_equity'), // giá trị tài sản tại thời điểm mua
            binding: 'total_capital',
            width: getWidthByTextContent(i18n.t('pcaf_update.table_total_equity'), 35),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
        ],
      },
      {
        header: i18n.t('56_pattern.table_ie_company_value'), //lượng thải GHG
        align: 'center',
        columns: [
          {
            header: name_emissions_type_col, //giá trị số 1
            binding: 'value_number',
            minWidth: getWidthByTextContent(name_emissions_type_col),
            maxWidth: 1200,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: headerEmissionFactor, //hệ số phát thải
            binding: 'emissions_factor',
            width: getWidthButtonByText(headerEmissionFactor, { fontSize: '13px', fontWeight: 500, letterSpacing: '0.04em', lineHeight: 20 }) + plusWidthMark,
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: 'required-field',
          },
          {
            header: i18n.t('basic_unit_library.label_search_basic_unit'),
            binding: "view_detail",
            allowSorting: false,
            isRequired: false,
            maxWidth    : getWidthOfSearchButton(i18n.locale),  
            minWidth    : getWidthOfSearchButton(i18n.locale),
            wordWrap: true,
            cssClassAll: "search-detail hide-filter",
            cellTemplate: CellMaker.makeButton({
              text:
                `<div class="search-container">
                  ${i18n.t('register_data.button_search')}
                  <img src="${require('@/assets/icons/pulldown/search-external.svg')}" alt="search icon" class="search-icon">
                </div>`,
                click: (e, ctx) => basicUnitSearchPopup(ctx, 'wsu_source', !isRemoveEmissionFactorOrValNumber, isRemoveEmissionFactorOrValNumber)
            }),
          },
          {
            header: i18n.t('basic_unit_library.basic_unit_name'),
            binding: 'ides_industry',
            minWidth: getWidthByTextContent(i18n.t('basic_unit_library.basic_unit_name')),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            dataMap: new wjGrid.DataMap(dbList, 'id', 'source'),
            editor: new AutoComplete(document.createElement('div'), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: dbList,
              isDroppedDownChanged: (sender) => {
                handleBasicUnitDroppedDownChanged({sender, dataProps})
              },
              lostFocus: (sender, ctx) => {
                handleLostFocusBasicUnitPulldown({sender, ctx, dataProps});
                searchID = handleLostFocus({ ctx, sender, dbStore, registerData, listLayout, branchData, externalSourcePopup, attachFilePopup, grid, isRemoveStatusColumn, businessGroupList, isRemoveEmissionFactorOrValNumber, typeOfPattern, uniqueOriginalDb, basicUnitData, category: 15 }, parternS3c15pcaf1.header)
              },
              gotFocus: (sender) => {
                handleGotFocusBasicUnitPulldown({sender, dataProps})
              },
              selectedValuePath: 'id',
              displayMemberPath: 'source',
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1,
            }),
          },
          {
            header: i18n.t('56_pattern.summary_score_dq_quality_score'), // sharpe ratio, pulldown(chọn từ 1,2,3,4,5)
            binding: 'dq_quality_score',
            minWidth: getWidthByTextContent(i18n.t('56_pattern.summary_score_dq_quality_score')),
            maxWidth: 980,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            dataMap: new wjGrid.DataMap([1, 2, 3, 4, 5]),
            editor: new AutoComplete(document.createElement('div'), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: [1, 2, 3, 4, 5],
              maxItems: 1000,
              minLength: 1,
              selectedIndex: -1,
            }),
          },
        ],
      },
      {
        header: i18n.t('56_pattern.table_emissions'),
        binding: 'emissions',
        cssClass: 'auto-increment',
        cssClassAll: 'emission',
        align: 'right',
        minWidth: 90,
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        inputType: 'float',
        dataType: 'Number',
      },
      {
        header: i18n.t('56_pattern.table_attach_file'),
        binding: "attach_file",
        cssClass: 'auto-increment',
        minWidth    : getWidthOfAttachedButton(i18n.locale),
        maxWidth    : getWidthOfAttachedButton(i18n.locale),
        allowSorting: false,
        isRequired: false,
        cssClassAll: "btn-db attach-file hide-filter",
        cellTemplate: (ctx, el) => {
          let text = i18n.t('56_pattern.table_attach_file');
          if (ctx.item?.evidence) {
            text = `<div class="file-attached-container">
              <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
            </div>`
          }
          const button = CellMaker.makeButton({
            text: text,
            click: (e, context) => attachFilePopup(e, context)
          })
          return button(ctx, el);
        }
      },
      {
        header: i18n.t('user_management.table_status'),
        binding: "status",
        minWidth: 100,
        width: 100,
        allowSorting: false,
        isRequired  : false,
        wordWrap: true,
        cssClassAll: 'status-field',
      }
    ];
  },

  handlerDataOnTable(dataProps) {
    const {
      dataTable,
      bindingLayout,
      isSaveTemplate,
      branchData,
      isGetBranchDetail,
      dbStore,
      itemAdded,
      isLimitedAttributionFactor,
      isRemoveEmissionFactorOrValNumber,
      typeOfPattern,
      isDeleteTingEvent,
      binding
    } = dataProps

    const valueAutofill = getScoreByType(typeOfPattern?.dataTypeIdSelect,typeOfPattern)
    if(!dataTable?.dq_quality_score && !isDeleteTingEvent && !dataTable.isDeleteRow) {
			dataTable.dq_quality_score = valueAutofill[0]
		}
    if (!dataTable?.emissions_factor && !isDeleteTingEvent && !dataTable.isDeleteRow && dbStore.selectedYear && binding === 'classify_gics') {
      const gicsItem = dbStore.getFullGicObjectOfAllYear[dbStore.selectedYear][dataTable.classify_gics]
      dataTable.emissions_factor = formatNumberRealNum(gicsItem?.autoField?.substring(0, 25))
    }
    if (!dataTable?.emissions_factor && !isDeleteTingEvent && !dataTable.isDeleteRow && dbStore.selectedYear && binding === 'ides_industry') {
      const dataStore = dbStore.getDbCustomizeOrDbMasterById[dataTable?.ides_industry];
      dataTable.emissions_factor = formatNumberRealNum(dataStore?.value_source);
    }
    if(!dataTable?.currency && !isDeleteTingEvent && !dataTable.isDeleteRow) {
      dataTable.currency = CURRENCY_TYPE[0]
    }

    if(itemAdded && binding === 'ides_industry') {
      dataTable.emissions_factor = formatNumberRealNum(itemAdded?.value_source);
    }
    if (isRemoveEmissionFactorOrValNumber) {
      dataTable.emissions_factor = 1;
      dataTable.ides_industry = null;
    }

    let databaseTypeObject = {};
    const wsuSource = dataTable.ides_industry;
    if(dataTable.idDbExternal && itemAdded && itemAdded?.type === 2) {
      databaseTypeObject = itemAdded
      dataTable.ides_industry = databaseTypeObject?.id
    } else {
      databaseTypeObject = dbStore.getDbCustomizeOrDbMasterById[wsuSource] || {};
      dataTable.idDbExternal = null
      dataTable.db_relation = null
    }
    if (isRemoveEmissionFactorOrValNumber) {
      dataTable.idDbExternal = null
      dataTable.db_relation = null
    }
    if (!isGetBranchDetail) return
    const dataForCalcEmissions = {
      loan_amount: formatNumberBySetting(dataTable.loan_amount),
      total_capital: formatNumberBySetting(dataTable.total_capital),
      value_number: formatNumberBySetting(dataTable.value_number),
      emissions_factor: formatNumberBySetting(dataTable.emissions_factor),
      isLimitedAttributionFactor,
      classify_gics: dataTable.classify_gics,
      dbStore: dbStore
    }
    const emissions = calcEmissions(formatNumberBySetting(dataForCalcEmissions));

    const layoutsData = {}
    bindingLayout.forEach(key => {
      layoutsData[key] = dataTable[key] || null
    })
    const branchId = isGetBranchDetail ? getBranchId(branchData.filterPatternList, {
      organizational_division: dataTable.organizational_division,
      company_name: dataTable.company_name,
      ...layoutsData
    }) : {};

    if (typeof dataTable.evidence === 'string') {
      dataTable.evidence = JSON.parse(dataTable.evidence);
    }
    const businessGroupId = handleGetBusinessGroupId(dataTable.pcaf_business_name, dataTable.pcaf_business_group_name, dbStore.getGroupCompanyObj)

    let dataTableItemCustom = {
      id: dataTable.id,
      company_name: dataTable.company_name || null,
      organizational_division: dataTable.organizational_division || null,
      emissions: formatEmission25Number(emissions),
      business_group_id: businessGroupId || null, //todo: update business_group_id by name
			pcaf_business_group_name: dataTable.pcaf_business_group_name || null,
			pcaf_business_name: dataTable.pcaf_business_name || null,
      classify_gics: dataTable.classify_gics || null,
      scope: dataTable.scope || null,
      memo_1: dataTable.memo_1 || null,
      memo_2: dataTable.memo_2 || null,
      category_1: dataTable.category_1 || null,
      category_2: dataTable.category_2 || null,
      currency: dataTable.currency || null,
      loan_amount: isSaveTemplate ? '' : formatNumberBySetting(dataTable.loan_amount) === 0 ? '0' : formatNumberBySetting(dataTable.loan_amount) || null,
      total_capital: isSaveTemplate ? '' : formatNumberBySetting(dataTable.total_capital) === 0 ? '0' : formatNumberBySetting(dataTable.total_capital) || null,
      value_number: isSaveTemplate ? '' : formatNumberBySetting(dataTable.value_number) === 0 ? '0' : formatNumberBySetting(dataTable.value_number) || null,
      emissions_factor: formatNumberBySetting(dataTable.emissions_factor) || null,
      contractor_id: store.state.userData.contractor, // from store
      month: dbStore.isFlagUpdateDataListEmission ? dataTable.month : store.state.registerData.month, // from store
      year: dbStore.isFlagUpdateDataListEmission ? dataTable.year : store.state.registerData.yearSelected,
      type: databaseTypeObject.type,
      dq_quality_score: dataTable.dq_quality_score || null,
      ...layoutsData,
      branch_id: branchId?.id || null,
      db_customize_id: databaseTypeObject.origin_id || null,
      evidence: dataTable.evidence || null,
      status: dataTable.status ? 1 : 0,
      is_in_approx: isLimitedAttributionFactor ? 1 : 0,
      db_relation: dataTable.db_relation ||  null,
    };

    if (dataTable.evidence) {
      if (dataTable.is_primary_data) {
        delete dataTable.evidence.value;
      }
      else {
        dataTableItemCustom.evidence.value = dataTable[this.bindingValueColumn];
      }
    }
    
    if (dataTableItemCustom.type > 0) {
      dataTableItemCustom.db_customize_id = null
    }
    if((dataTableItemCustom.type === 2 || dataTable.idDbExternal) && !isRemoveEmissionFactorOrValNumber) {
      dataTableItemCustom.db_relation = {
        db_source_detail_id: databaseTypeObject.origin_id
      }
      dataTableItemCustom.type = 2
      dataTableItemCustom.db_customize_id = null
    }

    return dataTableItemCustom;
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
  },

  filterColumns: [ //todo: update or remove
    'organizational_division',
    'company_name',
    'business_name',
    'ides_name_company',
    'scale_index_source',
    'scale_index_unit',
    'wsu_unit',
    'wsu_source',
  ],

  autoFields: [
    // 'emissions_factor',
    'ides_industry',
  ],
  getError(item, propName, dbStore, validateErrorBtn = {}, isRemoveEmissionFactorOrValNumber) {
    const {itemsInView = [], branchData = [], isCheckMappingFacility = false, itemsInViewError = []} = validateErrorBtn;
    const verifyItemKey = !isRemoveEmissionFactorOrValNumber ? notNullListIncludeEmissionFactor : notNullList
    if (verifyItemKey.includes(propName)) {
      if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL
      } else {
        return validateNumberFieldBySetting(String(item[propName]), propName);
      }
    }

    if (max25Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberFieldBySetting(String(item[propName]), propName);
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
    }

    if (max128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }

    if(isCheckMappingFacility && filterColumns.includes(propName)) {
      const layoutsData = {}
      itemsInView.forEach(key => {
        layoutsData[key] = item[key] || null
      })
      const branchId = getBranchId(branchData.filterPatternList, {
        organizational_division: item.organizational_division,
        company_name: item.company_name,
        ...layoutsData
      });

      if(!branchId) {
        return i18n.t('new_validate.error_mapping_layer', { listLayer: itemsInViewError.map(item => item)?.join('/') })
      }
    }
    return null
  },

  cellInputInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'pcaf_business_group_name',
			'pcaf_business_name',
      'classify_gics',
      'scope',
      'memo_1',
      'memo_2',
      'loan_amount',
      'category_1',
			'category_2',
      'total_capital',
      'value_number',
      'ides_industry',
      'attach_file',
      'currency',
      'emissions_factor',
      'dq_quality_score',
      ...bindingLayout
    ];
  },

  cellRequireInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      // 'pcaf_business_group_name',
			// 'pcaf_business_name',
      'classify_gics',
      'scope',
      'memo_1',
      'memo_2',
      'loan_amount',
      'category_1',
			'category_2',
      'total_capital',
      'value_number',
      'attach_file',
      'currency',
      ...bindingLayout
    ];
  },
  patternUrl: '/pattern-s3-c15/7',
  valueSource: 'ides_industry',

  calcEmissions(rowData) {
    return calcEmissions(rowData)
  },
  getQualityScore(typeData, items) {
    return getScoreByType(typeData, items)[0];
  },
  bindingValueColumn: 'value_number',

  setFilterIndex(filterIndexValue) {
    filterIndex = filterIndexValue;
  },
  updateFilterIndex(filterIndexValue) {
    filterIndex = {...filterIndex, ...filterIndexValue }
  },
  columnsCalcEmission: ['loan_amount', 'total_capital', 'value_number'],
  isUseBasicUnit: true,
  getAutoBindingDbSource(dataProps) {
    return autoBindingDbSource(dataProps);
  }
};

export default parternS3c15pcaf1;
