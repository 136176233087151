<template>
  <div class="primary-data-supplier-submit padding-top-layout">
    <div class="wrap">
      <div class="wrap__block">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <h1 class="wrap-title padding-left-layout">
              {{ $t('primary_data_merge.title_primary_data_merge_screen') }}
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div
      class="category-table main-table custom-table approval-table"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <data-table
        :data-source="primaryDataMergedGrid"
        :grid-columns="approvalColumn"
        :init-grid="initGrid"
        :rowFocus="1"
        :allowAddNew="false"
        :filterColumns="filterColumns"
        :showFocus="false"
        :isEmptySource="isEmptySource"
        :labelEmpty="descriptionLabelEmpty"
        :isResizeCustome="false"
        :isHasData="isHasData"
        @changeFullScreen="onChangeFullScreen"
        @flexGridInitialDone="onFlexGridInitialDone"
        @onHandleActionTable="onHandleActionTable"
      />
    </div>
    <dialog-popup
      :dialog="dialogPopup"
      :message="dialogMessage"
      @submit="() => checkboxHandler(false)"
      @close="closePopup()"
    />
    <notification-popup
      :isAdmin="true"
      :dialog="dialogNotification"
      :message="messageNotitfication"
      @submit="closeNotificationPopUp"
    />
    <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
  </div>
</template>
<script>
import DataTable from '@/components/category/data-table';
import DialogPopup from '@/components/dialogs/question-popup';
import { getListWorkflowApi, getListCommentHistory } from '@/api/registerData';
import { ROUTES } from '@/router/constants';
import { STATUS_FIELD } from '@/constants/status';
import { KEYS_CODE } from '@/constants/keyboard';
import { BLANK_ID, CANCEL_TYPE, ACTION_HEADER_TABLE } from '@/constants/registerData';
import { getStatusNameById, getColorTextStatusById } from '@/utils/status';
import { getCategoryName, getDataLayer, getScopeName } from '@/utils/registerData';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import { mapActions, mapState } from 'vuex';
import * as wjcCore from '@mescius/wijmo';
import { CollectionView } from '@mescius/wijmo';
import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
import * as wjGrid from '@mescius/wijmo.grid';
import throttle from 'lodash/throttle';
import { updateDataBeforeRedirect } from '@/utils/updateDataBeforeRedirect';
import { cancelSubmittedData } from '@/api/newRegisterData';
import { getWidthOfDetailButton } from '@/utils/multiLanguage';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { getScopeCategoryMethodName, showItemName } from '@/utils/primaryDataLinked';
import { addThoundSandComma } from '@/utils/convertNumber';
import { formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { ORGANIZATIONAL_DIVISION } from '@/constants/registerData';
import { updateStatusDataWorkFlowDataMerge } from '@/api/primaryDataLinked';
import notificationPopup from '@/components/dialogs/notification-popup.vue';
import CommentLogPopup from "@/components/dialogs/log-confirm-popup";
import { getWidthByTextHeader } from '@/utils/multiLanguage';
import { APPROVAL_STATUS } from '@/constants/registerData';
import { formatNumberRealNum } from '@/utils/convertNumber';

export default {
  name: 'ApprovalPage',
  components: {
    DataTable,
    DialogPopup,
    notificationPopup,
    CommentLogPopup
  },
  data() {
    return {
      primaryDataMergedGrid: null,
      flexGrid: null,
      dataSupplierSubmit: [],
      approvalColumn: [],
      dialogComment: false,
      breadCrumb: [
        {
          text: this.$t('primary_data_merge.hyperlink_primary_data_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('primary_data_merge.hyperlink_primary_data_merge_screen'),
          disabled: true,
          href: ROUTES.PRIMARY_DATA_MERGE,
        },
      ],
      filterColumns: [],
      dialogPopup: false,
      commentHistoryList: [],
      isEmptySource: true,
      dialogMessage: this.$t('primary_data_merge.table_disable_row_data'),
      isEditDataTable: false,
      commentPopup: false,
      layers: [],
      itemsChangeStatus: {},
      dialogNotification: null,
      messageNotitfication: this.$t('popup.popup_keep'),
    };
  },
  async mounted() {
    this.actionUpdateIsLoadingTable(true);
    await this.handleGetDataSupplierSubmit();
    this.actionUpdateIsLoadingTable(false);
    this.updateBreadCrumb(this.breadCrumb);
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
    closeNotificationPopUp() {
      this.dialogNotification = false;
      this.messageNotitfication = '';
    },
    async onCommentClicking(rowData) {
      if (!rowData.item?.id) return;
      await this.handleGetCommentHistory(rowData.item?.id);
      this.commentPopup = true;
      this.dialogCancelBtn = true;
    },
    async handleGetCommentHistory(workflowDataId) {
      const payload = {
        id: workflowDataId
      };
      const commentList = await getListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map(commentHistory => {
        return {
          ...commentHistory,
          date: formatDateTimeDataTable(commentHistory.updated_at),
          name: commentHistory.user.name,
          content: commentHistory.content
        };
      });
    },
    async onHandleActionTable() {
      try {
        this.primaryDataMergedGrid?.itemsEdited?.forEach(item => {
          if(!this.itemsChangeStatus[item.id]) {
            this.itemsChangeStatus[item.id] = item
          }
        })
        const dataChangeStatus = Object.values(this.itemsChangeStatus).map(item => {
          return {
            id: item.id, 
            is_active: item.is_active ? 1: 0,
            title: item?.title?.slice(0, 255)
          }
        });

        await updateStatusDataWorkFlowDataMerge({ data: Object.values(dataChangeStatus) })
        this.dialogNotification = true;
        this.messageNotitfication = this.$t('popup.popup_keep');
        this.itemsChangeStatus = {};
        this.isEditDataTable = false;
      } catch (error) {
        console.warn(error);
      }
    },
    initGrid(grid) {
      this.flexGrid = grid;
      document?.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false,
      );
      grid.beginningEdit.addHandler((sender, event) => {
        let column = sender.columns[event.col];
        let rowValue = sender.rows[event.row]._data;

        const listColumnNotDisable = ['unique_id'];
        if (!listColumnNotDisable.includes(column.binding)) {
          event.cancel = true;
        }
        if (column.binding === 'is_active') {
          this.handleCheckBox(rowValue);
        }
      });
      grid.hostElement.addEventListener('mouseover', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      grid.hostElement.addEventListener('mouseout', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      grid.pasting.addHandler((s, e) => {
        const { col, col2, row, row2 } = e.range;
        const pastingData = e.data.split('\n');
        let count = 0;
        for (let colIndex = col; colIndex <= col2; colIndex++) {
          for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
            const colBinding = s.columns[colIndex]?.binding;
            if (colBinding === 'is_active') {
              const currentData = s.getCellData(rowIndex, colIndex, false).toString();
              const pastingValue = pastingData[count]?.split('\t')[pastingData[count]?.split('\t').length - 1];
              if (pastingValue !== currentData) {
                count += 1;
                this.addDataHasChangeStatus(s.rows[rowIndex]?.dataItem);
                this.isEditDataTable = true;
              }
            }
          }
        }
      });

      grid.onSelectionChanged(null);
    },
    async handleGetDataSupplierSubmit() {
      const payload = {
        action: 'listPrimaryData',
      };
      const primaryDataMergedRes = await getListWorkflowApi(payload);
      this.layers = primaryDataMergedRes?.layer
      .map((layer) => {
        let binding = `layer_${layer.layer_index}`;
        if (layer.layer_index === 1) {
          binding = 'business_name';
        } else if (layer.layer_index === 2) {
          binding = 'country';
        }
        return {
          ...layer,
          binding,
        };
      })
      .sort((a, b) => a.layer_index - b.layer_index);
      if (primaryDataMergedRes.data.length > 0) {
        const branches = primaryDataMergedRes.branch;
        const sources = primaryDataMergedRes.db_customize;
        const masters = primaryDataMergedRes.db_master;
        const ideas= primaryDataMergedRes.db_source_detail;

        this.dataSupplierSubmit = primaryDataMergedRes.data.map((primaryDataLinkedItem) => {
          const { branch_id, db_customize_id, db_master_id } = primaryDataLinkedItem.primary_data;
          const db_relation = primaryDataLinkedItem.primary_data.db_relation?.db_source_detail_id;
          const branch = branches[branch_id];
          const source = sources[db_customize_id];
          const master = masters[db_master_id];
          const idea = ideas[db_relation]
          let status = getStatusNameById(primaryDataLinkedItem.status);
          let color = getColorTextStatusById(primaryDataLinkedItem.status);

          if (primaryDataLinkedItem.status === STATUS_FIELD.STATUS_SUBMIT) {
            status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
            color = getColorTextStatusById(STATUS_FIELD.STATUS_REPRENSENT_RETURN);
          }

          if (primaryDataLinkedItem.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED) {
            status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
          }
          const keySource = showItemName(parseInt(primaryDataLinkedItem.scope),
              parseInt(primaryDataLinkedItem.category),
              parseInt(primaryDataLinkedItem.pattern_id));
          const approveInfo = primaryDataLinkedItem.status === STATUS_FIELD.STATUS_RETURN || primaryDataLinkedItem.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN ? 
          primaryDataLinkedItem?.returner?.name : primaryDataLinkedItem?.approver?.name;
          const dateApprove = primaryDataLinkedItem.status === STATUS_FIELD.STATUS_RETURN || primaryDataLinkedItem.status === STATUS_FIELD.STATUS_REPRENSENT_RETURN ?
          primaryDataLinkedItem?.return_at : primaryDataLinkedItem.approved_at;
          let dataSource = source ? source?.['item_name'] : master ? (primaryDataLinkedItem.scope == 3 && primaryDataLinkedItem.category == 15) ? master?.['item_name'] : master?.['name_basic'] : idea?.source;
          return {
            ...primaryDataLinkedItem,
            ...branch,
            approver_info: approveInfo,
            submitter: primaryDataLinkedItem?.submitter?.name,
            scope_category_method: getScopeCategoryMethodName(
              parseInt(primaryDataLinkedItem.scope),
              parseInt(primaryDataLinkedItem.category),
              parseInt(primaryDataLinkedItem.pattern_id),
            ),
            month_year_registration: this.$t('primary_data_merge.table_register_month_year', {
              year: primaryDataLinkedItem.year,
              month: primaryDataLinkedItem.month,
            }),
            date_submit: formatDateTimeDataTable(primaryDataLinkedItem.submitted_at),
            date_approval: formatDateTimeDataTable(dateApprove),
            approved_by: primaryDataLinkedItem.approved_by,
            title: primaryDataLinkedItem.title || null,
            colorStatus: color,
            is_active: primaryDataLinkedItem.is_active,
            emissions: formatNumberRealNum(primaryDataLinkedItem.emissions, { isAlowMore25digit: false }),
            status: status,
            organizational_division: ORGANIZATIONAL_DIVISION[branch.organizational_division - 1],
            energy_type_source: dataSource,
            iconComment: primaryDataLinkedItem.comment_count > 0 ? require(`@/assets/icons/comment.svg`) : "",
            id: primaryDataLinkedItem.id,
            status_id: primaryDataLinkedItem.status
          };
        });
      }
      this.defineTable();
    },
    formatNumber(num) {
      return addThoundSandComma(formatBigNumber(num, 35).replace(/[^0-9\.\-]+/g, ''), '', false, true);
    },
    handleCheckBox(value) {
      if (value.is_active) {
        this.dialogPopup = true;
        this.dialogCancelBtn = true;
      } else {
        this.checkboxHandler(true);
      }
    },
    checkboxHandler(check) {
      this.isEditDataTable = true;
      this.primaryDataMergedGrid.beginUpdate();
      this.primaryDataMergedGrid.currentItem.is_active = check;
      this.primaryDataMergedGrid.endUpdate();
      this.addDataHasChangeStatus(this.primaryDataMergedGrid.currentItem)
      this.closePopup();
    },
    addDataHasChangeStatus(currentItem) {
      if(this.itemsChangeStatus[currentItem.id]) {
        delete this.itemsChangeStatus[currentItem.id]
        return
      }

      this.itemsChangeStatus[currentItem.id] = currentItem
      if(Object.values(this.itemsChangeStatus)) {
        this.isChangeStatus = true
      }
    },
    closePopup() {
      this.dialogPopup = false;
      this.dialogCancelBtn = true;
      this.dialogMessage = this.$t('primary_data_merge.popup_disable_data_workflow_data_merge');
    },
    initialView() {
      if (this.primaryDataMergedGrid) {
        this.dataSupplierSubmit = this.primaryDataMergedGrid.items.filter(
          (primaryDataLinkedItem) => primaryDataLinkedItem.id && primaryDataLinkedItem.id !== BLANK_ID,
        );
      }
      this.primaryDataMergedGrid = new CollectionView([...this.dataSupplierSubmit], {
        trackChanges: true,
      });
      this.flexGrid.columnGroups = this.getDataSupplierSubmit();
      this.primaryDataMergedGrid.currentItem = null;

      this.flexGrid.formatItem.addHandler((s, e) => {
        const isDisableRow = s.rows[e.row]?._data?.is_active;
        const colBinding = s.columns[e.col].binding;

        let rowValue = s.rows[e.row]?._data;
        if (isDisableRow && colBinding === 'status' && s.cells.cellType === wjGrid.CellType.Cell) {
          e.cell.classList.add(rowValue?.colorStatus);
        } else {
          e.cell.classList.remove(rowValue?.colorStatus);
        }
        if (!isDisableRow && colBinding !== 'unique_id') {
          e.cell.classList.add('disabled_row');
        }
      });

      this.primaryDataMergedGrid.collectionChanged.addHandler(() => {
        if(this.primaryDataMergedGrid.itemsEdited.length > 0) {
          this.isEditDataTable = true
        }
      })

      setMinMaxSizeColumns(this.flexGrid, this.dataSupplierSubmit);
      this.markUnreadRows();
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    getDataSupplierSubmit() {
      const mapLayers = this.layers.map((layer) => ({
        header: layer.layer_name,
        binding: layer.binding,
        minWidth: 114,
        maxWidth: 980,
        allowSorting: false,
        multiLine: true,
        wordWrap: true,
        cssClassAll: 'primary-data-merged',
      }));
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: ' ',
          binding: 'unique_id',
          minWidth: getWidthOfDetailButton(),
          maxWidth: getWidthOfDetailButton(),
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          cssClass: 'btn-db',
          cellTemplate: CellMaker.makeButton({
            text: this.$t('primary_data_merge.button_detail'),
            click: (event, context) => this.onIdClicking(context),
          }),
          cssClassAll: 'height34 approval-cell_custom hide-filter',
        },
        {
          header: this.$t('primary_data_merge.table_status'),
          binding: 'status',
          minWidth: getWidthByTextContent(this.$t('primary_data_merge.table_status')),
          maxWidth: 350,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cellTemplate: (ctx) => `<span class="${ctx.item.is_active && ctx.item.colorStatus}">${ctx.item.status}</span>`,
          cssClassAll: 'height34 approval-cell_status',
        },
        {
          header: " ",
          binding: "iconComment",
          minWidth: 32,
          maxWidth: 32,
          allowSorting: false,
          isRequired: false,
          cssClass: "btn-db",
          cellTemplate: CellMaker.makeImage({
            click: (event, context) => this.onCommentClicking(context)
          }),
          cssClassAll: "primary-data-merged approval-cell_comment hide-filter comment-icon",
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('primary_data_merge.table_name_form_data_merge'),
          binding: 'title',
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('primary_data_merge.table_approver_info'),
          binding: 'approver_info',
          minWidth: getWidthByTextContent(this.$t('primary_data_merge.table_approver_info')),
          maxWidth: 300,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('primary_data_merge.table_date_submit'),
          binding: 'date_submit',
          minWidth: 148,
          maxWidth: 200,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('primary_data_merge.table_date_approved'),
          binding: 'date_approval',
          minWidth: 148,
          maxWidth: 200,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        // TODO: mapLayers
        {
          header: this.$t('primary_data_merge.table_organizational_division'),
          binding: 'organizational_division',
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_organizational_division')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('primary_data_merge.table_company_name'),
          binding: 'company_name',
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_company_name')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        ...mapLayers,
        {
          header: this.$t('primary_data_merge.table_scope_category_method'), //primary_data_merge.table_scope_category_method
          binding: 'scope_category_method',
          minWidth: 403,
          maxWidth: 980,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('basic_unit_library.basic_unit_name'),
          binding: 'energy_type_source',
          minWidth: 408,
          maxWidth: 980,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('primary_data_merge.table_value_source'),
          binding: 'emissions',
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_value_source')),
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: '',
          align: 'right',
        },
        {
          header: this.$t('primary_data_merge.table_unit_source'),
          binding: 'unit',
          minWidth: 66,
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          wordWrap: true,
          cssClassAll: '',
        },
        {
          header: this.$t('primary_data_merge.table_note'),
          binding: 'note',
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'primary-data-merged',
        },
        {
          header: this.$t('primary_data_merge.table_month_year_registration'),
          binding: 'month_year_registration',
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_month_year_registration')),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          cssClass: 'btn-db',
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('primary_data_merge.table_checked'),
          binding: 'is_active',
          minWidth: getWidthByTextHeader(this.$t('primary_data_merge.table_checked')),
          allowSorting: false,
          dataType: 'Boolean',
          wordWrap: true,
          cssClassAll: 'single-row',
        },
      ];
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      const idsWorkflow = this.primaryDataMergedGrid.items.map(item => item.id);
      sessionStorage.setItem("dataListMoving", JSON.stringify(idsWorkflow));
      await updateDataBeforeRedirect(rowData.item);
      // TODO
      this.$router.push({ path: `/emissions/register/primary-data/${rowData.item.id}` });
    },
    defineTable() {
      this.approvalColumn = this.getDataSupplierSubmit();
      this.initialView();

      this.$nextTick(() => {
        this.scrollToTop();
      });
      this.isEmptySource = this.dataSupplierSubmit.length <= 0;
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    onFlexGridInitialDone(flexgrid) {
      this.flexgrid = flexgrid;
      if (!flexgrid) {
        return;
      }
    },
    markUnreadRows() {
      if (!this.flexgrid) return;
      this.flexgrid.formatItem.addHandler((handler, eventHandler) => {
        let accessRowIndex = eventHandler.row;
        let currentItem = this.primaryDataMergedGrid.items[accessRowIndex];
        if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
          let currentItemUnread =
            currentItem !== undefined && currentItem['unread_flg'] !== undefined ? currentItem['unread_flg'] : 0;
          if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
            if (currentItemUnread) {
              eventHandler.cell.classList.add('wj-has-unread-mark');
            }
          }
        }
      });
    },
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState('registerData', ['isFullScreen']),

    descriptionLabelEmpty() {
      return this.$t('primary_data_merge.description_label_empty');
    },
    isHasData() {
      return this.isEditDataTable;
    },
  },
};
</script>
<style lang="scss">
.primary-data-supplier-submit {
  .wj-flexgrid {
    .wj-cells {
      .wj-state-active:not(.wj-state-multi-selected) {
        background: $monoOffWhite !important;
      }
      .wj-row {
        .disabled_row {
          background: $monoOffWhite !important;
          color: $monoMid !important;
          &.wj-state-multi-selected {
            background: $blueExtraLight !important;
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
@import './styles/index.scss';
</style>
  