<template>
  <div class="type-selection padding-left-layout padding-top-layout padding-right-layout">
    <page-title :title="textTitle" />
    <div class="page-header">{{ $t("type_selection.description_please_select_type") }}<br>{{ $t("type_selection.description_easily_switched") }}</div>
    <div class="page-description">{{ $t("type_selection.description_type_depending_plan") }}</div>
    <div class="type-selection-wrapper">
      <div
        v-for="(item, index) in typeSelectionItems"
        :key="index"
        class="type-selection-item"
        @click="redirectToPage(item.isProduct)"
        :class="{'disabled' : item.isProduct && !isEnableProduct}"
        :id="item.id"
      >
        <div class="type-selection-text">
          <img :src="getIcon(item.icon)" alt="" />
          <div class="item-text">
            <div class="item-name">{{ item.name }}</div>
            <div class="item-description">{{ item.description }}</div>
          </div>
        </div>
        <div class="arrow-svg">
          <img src="@/assets/images/setting/arrow.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import { handleVerifyEmail, logoutApi } from '@/api/auth';
import { ROUTES } from "@/router/constants";
export default {
  components: {
    PageTitle,
  },
  data() {
    return {
      typeSelectionItems: [
        {
          name: this.$t("type_selection.button_organizational_unit"),
          description: this.$t("type_selection.label_ghg_emission_register"),
          icon: 'organizational-unit.svg',
          isProduct: false,
          id: 'standard-select'
        },
        {
          name: this.$t("type_selection.button_product_unit"),
          description: this.$t("type_selection.label_ghg_emission_register_product"),
          icon: 'product-unit.svg',
          isProduct: true,
          id: 'product-select',
        }
      ],
      isEnableProduct: false,
    }
  },
  computed: {
    ...mapState("userData", ["planType"]),
    textTitle() {
      return this.$t("type_selection.title_type_selection");
    }
  },
  async mounted() {
    this.isEnableProduct = this.planType?.is_cfp;
    const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
    const id = urlParams.get('id');
    const email = urlParams.get('email');

    // handle verify email
    if (id && email) {
      const params = {
        id: id,
        email: email,
      };
      handleVerifyEmail(params).then(() => {
        logoutApi().then(() => this.$router.push({ path: "/auth/login" }).catch(() => { }))
        sessionStorage.setItem("isVerifyEmailOk", true);
        sessionStorage.setItem("preventReloadLogin", true);
      });
    }
    window.history.pushState(null, null, window.location.href);

    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, null, window.location.href);
    });
  },
  methods: {
    ...mapActions("userData", ["updateIsInProductPage"]),
    getIcon(icon) {
      return require(`@/assets/icons/type-selection/${icon}`);
    },
    async redirectToPage(isProduct) {
      if (isProduct) {
        if (!this.isEnableProduct) return;
        await this.updateIsInProductPage(isProduct);
        this.$router.push({path: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`}).catch(() => {});
      } else {
        await this.updateIsInProductPage(isProduct);
        this.$router.push({path: ROUTES.DASHBOARD}).catch(() => {});
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.type-selection {
  // font-family: 'Source Han Sans JP';
  font-style: normal;
  &.padding-left-layout {
    padding-left: 0 !important;
  }
  .page-header {
    color: $monoBlack;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.05em;
  }
  .page-description {
    color: $monoDark;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-top: 16px;
  }
  .type-selection-wrapper {
    margin-top: 48px;
    .type-selection-item {
      background-color: $monoOffWhite;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11);
      border-radius: 4px;
      width: 100%;
      height: 68px;
      display: flex;
      align-items: center;
      padding: 8px 8px 8px 12px;
      justify-content: space-between;
      transition: 0.2s ease-out;
      &:not(.disabled):hover {
        cursor: pointer;
      }
      &:first-child {
        margin-bottom: 16px;
      }
      .type-selection-text {
        display: flex;
        align-items: center;
        img {
          width: 48px;
          height: 48px;
        }
        .item-text {
          color: $monoBlack;
          width: 207px;
          padding-left: 12px;
          .item-name {
            font-weight: 700;
            font-size: 16px;
            line-height: 28px;
            letter-spacing: 0.1em;
          }
          .item-description {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.03em;
          }
        }
      }
      .arrow-svg {
        width: 36px;
        height: 52px;
      }
      &.disabled {
        background-color: $monoExtraLight;
        pointer-events: none;
      }
      &:hover {
        background-color: $monoWhite;
        box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17);
      }
    }
  }
}
@include desktop {
  .type-selection {
    min-height: calc(100vh - 144px);
    &.padding-left-layout {
      padding-left: 40px !important;
    }
    .type-selection-wrapper {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      margin-top: 0;
      min-height: calc(100vh - 362px);
      .type-selection-item {
        width: 304px;
        height: 168px;
        justify-content: center;
        &:first-child {
          margin-right: 24px;
          margin-bottom: 0;
        }
        .arrow-svg {
          display: none;
        }
        .type-selection-text {
          flex-flow: column;
          .item-text {
            padding-left: unset;
            margin-top: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: column;
            .item-name {
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {    
  .type-selection {
    padding-bottom: 100px;
  }
}
</style>