<template>
  <div class="wrap padding-top-layout">
    <div class="wrap__block">
      <div class="wrap__block__header wrap__header">
        <div class="wrap__block__header__content">
          <span>{{ $t('customized_db_management.title_db_customize') }}</span>
          <div class="helper-icon">
            <img
              :src="getSettingIcon('helper-icon.svg')"
              @mouseover="changeTooltipImage($event)"
              @mouseleave="changeTooltipImage($event, 'mouseleave')"
              alt=""
            />
            <div
              v-if="showTooltip"
              class="helper-icon tooltip"
              v-html="$t('customized_db_management.tooltip_db_customize')"
            ></div>
          </div>
        </div>
      </div>
      <div class="wrap__status">
        <div class="wrap__status__item">
          <img src="@/assets/icons/clock.svg" alt="" />
          <div class="wrap__status__item__wrap">
            <p>{{$t('common.last_update')}}</p>
            <p>{{ dateTimeFormat(statusUpdate.time) }}</p>
          </div>
        </div>
        <div class="wrap__status__item">
          <img src="@/assets/icons/userTable.svg" alt="" />
          <div class="wrap__status__item__wrap">
            <p>{{$t('common.updated_by')}}</p>
            <p>{{ statusUpdate.user || "" }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="casbee-menu">
      <default-pull-down-menu
        v-if="isCasbee"
        v-model="casbeeModel"
        :items="casbeeList"
        :initialValue="''"
        :placeHolderText="placeholderAddBasicUnit"
        class="casbee-pulldown casbee-type"
        :width="'auto'"
        :minWidth="'256px'"
      />
    </div>
    <div
      class="category-table main-table custom-table center-table"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <data-table
        :data-source="dbCustomViews"
        :init-grid="flexInitialized"
        :grid-columns="gridColumns"
        :totalData="totalData"
        :rowFocus="2"
        :isHasData="isHasData"
        :cellInput="cellInput"
        :allowRightClick="true"
        tableName="db-customize"
        @changeFullScreen="onChangeFullScreen"
        @onHandleActionTable="checkValidationData"
        @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
        @checkTableHasData="checkTableChangeData"
        class="table-db_custom"
      />
    </div>
    <question-popup :dialog="dialogPopup" :message="popupMessage" @submit="checkboxHandler" :closeText="closeText" @close="closePopup" />
    <radio-popup
      :dialog="radioDialog"
      :radioType="radioType"
      :titlePopup="titlePopup"
      :items="items"
      :showDescription="false"
      :currentSelect.sync="currentSelected"
      @onChange="onChangeRadio"
      @close="closeRadioPopup"
      style="z-index: 9999"
    />
    <notification-popup :dialog="dialogNotification" :message="messageSavedChange" @submit="dialogNotification = false" />
    <notification-popup :dialog="isShowMessageCasbe" :message="messageCase" @submit="handleClosePopupCasbee" />
    <notification-popup :dialog="dialogValidate" :message="$t('popup.popup_field_require_not_fill')" @submit="dialogValidate = false" />
    <error-popup :dialog="errorDialog" :message="errorMessage" @submit="errorDialog = false" />
    <EvidenceStoragePopup :dialog.sync="openDialogEvidenceStorage" :typeForm="typeFormEvidenceStorage" :isShowDetail="false" :evidences="evidences" @onSubmitSuccess="handleAddAttachFile" @onDelete="handleDeleteAttachFile"></EvidenceStoragePopup>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { ROUTES } from "@/router/constants";
import * as wjGrid from "@mescius/wijmo.grid";
import DataTable from "@/components/category/data-table";
import RadioPopup from "@/components/dialogs/radio-popup";
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import {
  Tooltip,
  PopupPosition,
  CollectionView
} from "@mescius/wijmo";
import * as wjcCore from "@mescius/wijmo";
import { createDbCustom, getDbCustom, updateDbCustom } from "@/api/dbCustomization";
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import {
  validateMaximumCharactor as validateMaximumCharacter,
  validateMaximumCharactorOnlyFacility,
  validateBlankRowData,
  validateInputIsYearGHG
} from "@/utils/validate";
import { formatDateTime, formatDateTimeDataTable, generateYearsArray, getMonthList } from "@/utils/datetimeFormat";
import QuestionPopup from "@/components/dialogs/question-popup.vue";
import { AutoComplete } from "@mescius/wijmo.input";
import { UndoStack } from "@mescius/wijmo.undo";
import { GridEditAction } from "../managements/wijmo-extends";
import { toolTipCustom } from "@/utils/tooltipCustom";
import { getUserInfo } from "@/api/auth";
import { KEYS_CODE } from "@/constants/keyboard";
import { BLANK_ID } from "@/constants/registerData";
import debounce from "lodash/debounce";
import { batchReplacementViewCollection, emptyPromise } from "@/concerns/registerData/wijmo.helper";
import DefaultPullDownMenu from '@/components/pulldown/DefaultPullDownMenu';
import { CASBEE, CASBEE_TYPE } from '@/constants/casbee';
import { getWidthOfDetailButton, isWordWrapTable, getWidthOfAttachedButton } from '@/utils/multiLanguage'
import { $_helper_isNumberType, preventKeydownNumber, $_helper_isNumberType_bySetting, removeSpecialChars, validateNumberFieldBySetting, formatNumberBySetting } from '@/concerns/newRegisterData/wijmo.helper';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { convertNestedFullWidthToHalfWidth } from '@/utils/convertHalfWidth';
import EvidenceStoragePopup from '@/views/emissions/EvidenceStorage/index.vue';
import { getMonthListCustome } from '@/utils/registerData';

import { formatNumberByConditions } from '@/utils/convertNumber';
import { formatNumberRealNum } from '@/utils/convertNumber';
export default {
  components: {
    DataTable,
    QuestionPopup,
    RadioPopup,
    NotificationPopup,
    DefaultPullDownMenu,
    EvidenceStoragePopup,
    ErrorPopup,
  },
  data() {
    return {
      currentSelected    : null,
      diffText           : this.$t('customized_db_management.radio_select_other'),
      popupMessage       : this.$t('customized_db_management.description_disable_this_intensity'),
      confirmText        : this.$t('popup.button_change'),
      selectionButtonText: this.$t("customized_db_management.button_choice"),
      addedSource        : [],
      totalData          : null,
      statusUpdate       : {
        time: null,
        user: null
      },
      context            : null,
      items              : null,
      activeRow          : null,
      radioType          : null,
      titlePopup         : '',
      radioDialog        : false,
      types              : {
        category: "Categories",
        scope   : "Scopes"
      },
      dialogPopup        : false,
      dialogCancelBtn    : false,
      dialogValidate     : false,
      dialogMessage      : this.$t('popup.popup_uncheck'),
      dialogBaseMessage  : this.$t('popup.popup_uncheck'),
      // item
      dbCustomsFlex       : null,
      selectedItem        : "",
      gridColumns         : [],
      dbCustoms           : null,
      dbCustomsOriginal: null,
      dbCustomViews       : null,
      scopes              : null,
      categories          : null,
      sources             : ["IDEA"],
      hdrTips             : new Tooltip({
        position   : PopupPosition.Above,
        showAtMouse: true,
        showDelay  : 600,
        cssClass   : "hdr-tip"
      }),
      idsUpdate           : [],
      dataRedo            : [],
      restoreSelection    : null,
      pastingData         : null,
      undoStack           : null,
      canUndo             : false,
      canRedo             : false,
      actionCount         : 0,
      checkAction         : false,
      dataDisabledAction  : null,
      stackUndoWhenUncheck: null,
      eventChangeStatus   : null,
      stackUndoScope      : null,
      stackUndoCategory   : null,
      buttonEvent         : null,
      bindings            : {
        name        : "name",
        item_name   : "item_name",
        unit        : "unit",
        value_source: "value_source",
        unit_source : "unit_source",
        source      : "source",
        scope_id    : "scope_id",
        category_id : "category_id",
        year        : "year",
        note        : "note",
        updated_at  : "updated_at",
        status      : "status",
        type_ghg    : 'type_ghg',
        computational_operations: 'computational_operations'
      },
      userName            : "",
      showTooltip         : false,
      listOriginalData    : [],
      dialogNotification: false,
      cellInput: ['name', 'item_name', 'unit', 'value_source', 'unit_source', 'source', 'year', 'note'],
      isCasbee: false,
      casbeeList: [],
      casbeeModel: null,
      isShowMessageCasbe: false,
      messageCase: '',
      selectedFilterColumn: null,
      isEditDataTable: false,
      idEditStatusList: [],
      evidences: {},
      openDialogEvidenceStorage: false,
      typeFormEvidenceStorage: 'view',
      categoriesScope2: {
        1: this.$t('customized_db_management.electricity_label'),
        2: this.$t('customized_db_management.heat_other_label'),
      },
      yearList: generateYearsArray(2010, 2035).reverse(),
      monthList: getMonthListCustome(2024, 1),
      errorDialog: false,
      errorMessage: this.$t('popup.popup_month_after_start_date'),
    };
  },
  async mounted() {
    this.actionUpdateIsFullScreen(false);
    this.casbeeList.push(
      {
        name: CASBEE.CASBEE,
        value: CASBEE.CASBEE
      },
    );
    this.updateBreadCrumb(this.breadCrumbItems);
    if(this.$route.query?.registeCasbe) {
      this.isShowMessageCasbe = true;
      const numberAdd = this.$route.query.registeCasbe;
      this.messageCase = this.$t("popup.popup_add_casbee", { numberAdd });
    }
    try {
      this.actionUpdateIsLoadingTable(true)
      await this.checkUserInfo();
      await this.fetchDbCustoms();
      this.actionUpdateIsLoadingTable(false)
    } catch (error) {
      this.actionUpdateIsLoadingTable(false)
    }

    this.createUndoStack();
  },
  computed: {
    ...mapState("userData", ["contractor", "currentUser"]),
    ...mapState("commonApp", ["loadMore", "isFilter", "isExpand", "statusAction"]),
    ...mapState("actionsTable", ["startAction"]),
    ...mapState("actionsTable", ["dataAction"]),
    ...mapState("registerData", ["isFullScreen"]),
    ...mapGetters('settingApp', ['getDigitsBySetting']),

    isHasData() {
      return this.isEditDataTable || this.canUndo || this.canRedo;
    },
    placeholderAddBasicUnit() {
      return this.$t('customized_db_management.pulldown_select_add_basic_unit')
    },
    messageSavedChange() {
      return this.$t('customized_db_management.message_saved_changes')
    },
    closeText() {
      return this.$t('popup.button_cancel')
    },
    isProductPage() {
      return this.$route.name === "CreateProductDataCustomize";
    },
    breadCrumbItems() {
      if (this.isProductPage) {
        return [
          {
            text    : this.$t('customized_db_management.hyperlink_home'),
            disabled: false,
            href    : `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
          },
          {
            text    : this.$t('customized_db_management.hyperlink_setting'),
            disabled: false,
            href    : `${ROUTES.PRODUCTS_EMISSION}${ROUTES.SETTING}`,
          },
          {
            text    : this.$t('customized_db_management.label_db_customize'),
            disabled: true,
            href    : `${ROUTES.PRODUCTS_EMISSION}${ROUTES.CREATE_DATA_CUSTOMIZE}`,
          }
        ]
      } else {
        return [
          {
            text    : this.$t('customized_db_management.hyperlink_home'),
            disabled: false,
            href    : ROUTES.DASHBOARD
          },
          {
            text    : this.$t('customized_db_management.hyperlink_setting'),
            disabled: false,
            href    : ROUTES.SETTING
          },
          {
            text    : this.$t('customized_db_management.label_db_customize'),
            disabled: true,
            href    : ROUTES.CREATE_DATA_CUSTOMIZE
          }
        ]
      }
    }
  },
  methods : {
    ...mapActions("actionsTable", ["actionUpdate", "actionUpdateStatusBtn"]),
    ...mapActions("registerData", ["actionUpdateIsFullScreen"]),
    ...mapActions("commonApp", ["updateBreadCrumb", "updateStatusAction", "actionUpdateIsLoadingTable"]),

    getGridColumn() {
      const tableUpdatedAttWidth = getWidthByTextContent(this.$t('customized_db_management.table_updated_at'));
      const widthTooltip = 20;
      return [
        {
          header      : "#",
          binding     : "id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false,
          minWidth    : 0
        },
        {
          header      : this.$t('customized_db_management.table_item_name'),
          binding     : "item_name",
          allowSorting: false,
          isRequired  : false,
          minWidth    : getWidthByTextContent(this.$t('customized_db_management.table_item_name'), 40),
          maxWidth    : 980,
          wordWrap    : isWordWrapTable(),
          cssClassAll : "single-row cell-background"
        },
        {
          header      : this.$t('customized_db_management.table_unit'),
          binding     : "unit",
          allowSorting: false,
          isRequired  : false,
          align       : "left",
          minWidth    : getWidthByTextContent(this.$t('customized_db_management.table_unit')) + 25,
          wordWrap    : isWordWrapTable(),
          maxWidth    : 980,
          cssClassAll : "single-row cell-background"
        },
        {
          header      : this.$t('customized_db_management.table_value_source'),
          binding     : "value_source",
          allowSorting: false,
          isRequired  : false,
          inputType   : "float",
          align       : "right",
          minWidth    : getWidthByTextContent(this.$t('customized_db_management.table_value_source')) + 25,
          wordWrap    : isWordWrapTable(),
          maxWidth    : 400,
          wordWrap    : true,
          cssClassAll : "single-row cell-background"
        },
        {
          header      : this.$t('customized_db_management.table_unit_source'),
          binding     : "unit_source",
          allowSorting: false,
          isRequired  : false,
          align       : "left",
          minWidth    : getWidthByTextContent(this.$t('customized_db_management.table_unit_source')) + 25,
          wordWrap    : isWordWrapTable(),
          maxWidth    : 400,
          cssClassAll : "single-row cell-background"
        },
        {
          header       : this.$t('customized_db_management.table_source'),
          binding      : "source",
          allowSorting : false,
          editor       : new AutoComplete(document.createElement("div"), {
            placeholder: this.$t('56_pattern.placeholder'),
            itemsSource: new wjcCore.ObservableArray(this.sources),
            maxItems   : 1000,
            minLength  : 1,
            lostFocus: (sender) => {
              let item = sender.text;
              if (item && sender.itemsSource.indexOf(item) < 0) {
                sender.itemsSource.push(item);
              }
            }
          }),
          isRequired   : false,
          dataMapEditor: "DropDownList",
          align        : "left",
          minWidth     : 343,
          maxWidth     : 980,
          wordWrap     : true,
          cssClassAll  : "single-row cell-background"
        },
        {
          header      : this.$t('customized_db_management.table_scope_id'),
          binding     : "scope_id",
          allowSorting: false,
          align       : "center",
          cssClass    : "btn-db",
          cellTemplate: (ctx, el) => {
            const button = CellMaker.makeButton({
              text: ctx?.item?.scope_id ? ctx.item.scope_id : this.$t('customized_db_management.button_choice'),
              click: (e, ctx) => this.onScopeClicking(ctx),
            });

            return button(ctx, el);
          },
          isRequired  : false,
          minWidth    : getWidthByTextContent(this.$t('customized_db_management.table_scope_id')) + 25,
          maxWidth    : 460,
          wordWrap    : true,
          cssClassAll : "single-row dbCustomize-row cell-background"
        },
        {
          header      : this.$t('customized_db_management.table_category_id'),
          binding     : "category_id",
          allowSorting: false,
          align       : "center",
          cellTemplate: (ctx, el) => {
            const button = CellMaker.makeButton({
              text: ctx?.item?.category_id ? ctx?.item?.category_id?.toString() : this.$t('customized_db_management.button_choice'),
              click: (e, ctx) => this.onCategoryClicking(ctx),
            });

            return button(ctx, el);
          },
          cssClass    : "btn-db",
          minWidth    : getWidthByTextContent(this.$t('customized_db_management.table_category_id')) + 25,
          maxWidth    : 460,
          wordWrap    : true,
          cssClassAll : "single-row dbCustomize-row cell-background"
        },
        {
          header      : this.$t('basic_unit_library.label_year_from'),
          binding     : "year_from",
          allowSorting: false,
          isRequired  : false,
          dataMapEditor: "DropDownList",
          inputType   : "float",
          align       : "left",
          minWidth    : getWidthByTextContent(this.$t('basic_unit_library.label_year_from'), widthTooltip),
          wordWrap    : isWordWrapTable(),
          maxWidth    : 400,
          cssClassAll : "single-row cell-background",
          editor       : new AutoComplete(document.createElement("div"), {
            placeholder: this.$t('56_pattern.placeholder'),
            itemsSource: new wjcCore.ObservableArray(this.yearList),
            maxItems   : 1000,
            minLength  : 1,
            isDroppedDownChanged: (sender) => {
              if (sender.isDroppedDown && !sender.selectedValue) {
                const options = sender.dropDown.children;
                if (options) {
                  for (var i = 0; i < options.length; i++) {
                  if (options[i].innerHTML === new Date().getFullYear().toString()) {
                    sender.dropDown.scrollTo({
                      top: options[i].offsetTop,
                      behavior: "smooth",
                    });
                  }
                }
                }
              }
            }
          }),
        },
        {
          header      : this.$t('basic_unit_library.label_month_from'),
          binding     : "month_from",
          allowSorting: false,
          isRequired  : false,
          inputType   : "float",
          align       : "left",
          minWidth    : getWidthByTextContent(this.$t('basic_unit_library.label_month_from'), widthTooltip),
          wordWrap    : isWordWrapTable(),
          maxWidth    : 400,
          cssClassAll : "single-row cell-background",
          dataMap: new wjGrid.DataMap(this.monthList, 'value', 'text'),
          editor: new AutoComplete(document.createElement("div"), {
            placeholder: this.$t('56_pattern.placeholder'),
            itemsSource: this.monthList,
            selectedValuePath: 'value',
            displayMemberPath: 'text',
            maxItems   : 12,
            minLength  : 1,
          })
        },
        {
          header      : this.$t('basic_unit_library.label_year_end'),
          binding     : "year_end",
          allowSorting: false,
          isRequired  : false,
          dataMapEditor: "DropDownList",
          inputType   : "float",
          align       : "left",
          minWidth    : getWidthByTextContent(this.$t('basic_unit_library.label_year_end'), widthTooltip),
          wordWrap    : isWordWrapTable(),
          maxWidth    : 400,
          cssClassAll : "single-row cell-background",
          editor       : new AutoComplete(document.createElement("div"), {
            placeholder: this.$t('56_pattern.placeholder'),
            itemsSource: new wjcCore.ObservableArray(this.yearList),
            maxItems   : 1000,
            minLength  : 1,
            isDroppedDownChanged: (sender) => {
              if (sender.isDroppedDown && !sender.selectedValue) {
                const options = sender.dropDown.children;
                if (options) {
                  for (var i = 0; i < options.length; i++) {
                  if (options[i].innerHTML === new Date().getFullYear().toString()) {
                    sender.dropDown.scrollTo({
                      top: options[i].offsetTop,
                      behavior: "smooth",
                    });
                  }
                }
                }
              }
            }
          }),
        },
        {
          header      : this.$t('basic_unit_library.label_month_end'),
          binding     : "month_end",
          allowSorting: false,
          isRequired  : false,
          inputType   : "float",
          align       : "left",
          minWidth    : getWidthByTextContent(this.$t('basic_unit_library.label_month_end'), widthTooltip),
          wordWrap    : isWordWrapTable(),
          maxWidth    : 400,
          cssClassAll : "single-row cell-background",
          dataMap: new wjGrid.DataMap(this.monthList, 'value', 'text'),
          editor: new AutoComplete(document.createElement("div"), {
            placeholder: this.$t('56_pattern.placeholder'),
            itemsSource: this.monthList,
            selectedValuePath: 'value',
            displayMemberPath: 'text',
            maxItems   : 12,
            minLength  : 1,
          })
        },
        {
          header      : this.$t('basic_unit_library.label_type_ghg'),
          binding     : "type_ghg",
          allowSorting: false,
          isRequired  : false,
          minWidth    : getWidthByTextContent(this.$t('basic_unit_library.label_type_ghg'), widthTooltip),
          wordWrap    : isWordWrapTable(),
          maxWidth    : 980,
          cssClassAll : "single-row cell-background"
        },
        {
          header      : this.$t('basic_unit_library.label_target_calc'),
          binding     : "computational_operations",
          allowSorting: false,
          isRequired  : false,
          minWidth    : getWidthByTextContent(this.$t('basic_unit_library.label_target_calc')),
          wordWrap    : isWordWrapTable(),
          maxWidth    : 980,
          cssClassAll : "single-row cell-background"
        },
        {
          header      : this.$t('customized_db_management.table_note'),
          binding     : "note",
          allowSorting: false,
          align       : "left",
          isRequired  : false,
          minWidth    : getWidthByTextContent(this.$t('customized_db_management.table_note')) + 30,
          maxWidth    : 980,
          wordWrap    : true,
          cssClassAll : "single-row cell-background"
        },
        {
          header      : this.$t('customized_db_management.table_updated_at'),
          binding     : "updated_at",
          allowSorting: false,
          isReadOnly  : true,
          align       : "center",
          isRequired  : false,
          minWidth    : tableUpdatedAttWidth > 140 ? tableUpdatedAttWidth : 140,
          maxWidth    : 860,
          wordWrap    : true,
          cssClassAll : "no-tooltip single-row cell-background"
        },
        {
          header      : this.$t('customized_db_management.table_status'),
          binding     : "status",
          allowSorting: false,
          isRequired  : false,
          align       : "center",
          dataType    : "Boolean",
          minWidth    : getWidthByTextContent(this.$t('customized_db_management.table_status')) + 25,
          maxWidth    : 460,
          wordWrap    : true,
          cssClassAll : "single-row cell-background"
        },
        {
          header      : this.$t('56_pattern.table_attach_file'),
          binding     : "attach_file",
          cssClass: 'auto-increment',
          align       : "center",
          minWidth    : getWidthOfAttachedButton(this.$i18n.locale),
          maxWidth    : getWidthOfAttachedButton(this.$i18n.locale),
          allowSorting: false,
          isRequired  : false,
          cssClassAll : "single-row casbee-detail hide-filter btn-attach-file",
          cellTemplate: (ctx, el) => {
            let text = this.$t('56_pattern.table_attach_file');
            if (ctx.item?.evidence) {
              text = `<div class="file-attached-container">
                <img src="${require('@/assets/icons/file_attached.svg')}" alt="file attached icon" class="file_attached">
              </div>`
            }
            const button = CellMaker.makeButton({
              text: text,
              click: (e, context) => this.attachFilePopup(e, context)
            })
            return button(ctx, el);
          } 
        },
        {
          header      : " ",
          binding     : "view_detail",
          allowSorting: false,
          isRequired  : false,
          align       : "center",
          minWidth    : getWidthOfDetailButton(),
          maxWidth    : getWidthOfDetailButton(),
          wordWrap    : true,
          cssClassAll : "single-row casbee-detail",
          cellTemplate: CellMaker.makeButton({
            text : this.$t('customized_db_management.button_detail'),
            click: (e, ctx) => this.onViewDetailClicking(ctx)
          }),
        }
      ];
    },
    defineTable() {
      this.gridColumns = this.getGridColumn();

      this.initialView();
    },

    attachFilePopup(e, context) {
      this.evidences = {};
      this.selectedRow = context.row.index;

      let evidenceData = context?.item?.evidence;
      if(evidenceData && evidenceData.file_name) {
        this.evidences.data = evidenceData;
        this.typeFormEvidenceStorage = 'view';
      }
      else {
        this.typeFormEvidenceStorage = 'create';
      }
      this.openDialogEvidenceStorage = true;
    },
    handleDeleteAttachFile() {
      this.evidences = {};
      const selectedItem = this.dbCustomViews.items[this.selectedRow];
      this.dbCustomViews.editItem(selectedItem);
      selectedItem.evidence = null;
      this.dbCustomViews.commitEdit();
      this.isEditDataTable = true;
    },
    handleAddAttachFile(dataEvidence) {
      const selectedItem = this.dbCustomViews.items[this.selectedRow];
      if(selectedItem) {
        this.dbCustomViews.editItem(selectedItem);
        if (dataEvidence.file_name) {
          selectedItem.evidence = dataEvidence;
        } else {
          selectedItem.evidence.note = dataEvidence.note;
          selectedItem.evidence.updated_at = dataEvidence.updated_at;
        }
        this.dbCustomViews.commitEdit();
      } else {
        let dataBlank = this.blankData(this.selectedRow + 1)
        dataBlank.evidence = dataEvidence
        this.dbCustomViews.addNew(dataBlank)
      }
      this.isEditDataTable = true;
    },
    isVerifiedData(data) {
      let isVerified = true;
      data?.forEach((item) => {
        let {
              note,
              category_id,
              status,
              id,
              ...restItem
            }     = item;
        let check = Object.values(restItem).every((value) => value);
        if (!check) {
          isVerified = false;
        }

        if (item.scope_id == "1" || item.scope_id == "その他") {
          return;
        }

        // Category or scope_id is undefined when pasting
        if (!category_id || !item.scope_id) {
          isVerified = false;
        }

        // Select scope 3 and not select category
        if ((item.scope_id == 3 || item.scope_id == 2) && category_id == this.selectionButtonText) {
          this.undoStack.pushAction(this.stackUndoScope);
          this.stackUndoScope = null;
          isVerified          = false;
        }
      });

      return isVerified;
    },
    flexInitialized(flexgrid) {
      this.dbCustomsFlex = flexgrid;
      let columnNum = null;
      flexgrid.scrollPositionChanged.addHandler(
        debounce((s, e) => {
          if (!this.$store.state.registerData.isFullScreen) {
            return;
          }

          if (s.viewRange.bottomRow >= s.rows.length - 1) {
            s.deferUpdate(() => {
              const lastClientId = flexgrid.itemsSource.itemCount;
              for (let index = 1; index <= 100; index++) {
                s.itemsSource.addNew(this.blankData(lastClientId + index));
              }

              s.itemsSource.commitNew();
              s.itemsSource.clearChanges();
            });
          }
        }, 100)
      );
      flexgrid.selectionChanged.addHandler((s, e) => {
        const column = s.columns[e.col];
        if(column.binding !== columnNum) {
          columnNum = column.binding;
        }
      });
      document.addEventListener("keydown", (e) => {
        if (
          (e.metaKey || e.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER
          ].includes(e.keyCode)
        ) {
          e.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        "keydown",
        (e) => {
          // console.log('keydown: ', e);
          preventKeydownNumber({
            event: e,
            getDigitsBySetting: this.getDigitsBySetting,
            columnNum,
            formatItems: ['value_source'],
          })
          if (e.metaKey || e.ctrlKey) {
            if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection     = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(0, currentSelection.col);
              flexgrid.selection     = cellRange;
            } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
              flexgrid.selection     = cellRange;
            } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(currentSelection.row, 2);
              flexgrid.selection     = cellRange;
            }
          }

          if (e.keyCode === KEYS_CODE.ENTER) {
            if (flexgrid.selection.row === flexgrid.rows.length - 1) {
              const lastClientId = flexgrid.itemsSource.itemCount;

              flexgrid.deferUpdate(() => {
                flexgrid.itemsSource.addNew(this.blankData(lastClientId + 1));

                flexgrid.itemsSource.commitNew();
                flexgrid.itemsSource.clearChanges();
              });
            }
          }
        },
        false
      );

      flexgrid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (s.cells === e.panel && s.columns[e.col].binding === this.bindings.category_id) {
          if (!s.rows[e.row].dataItem || (s.rows[e.row].dataItem && s.rows[e.row].dataItem.scope_id != 3 && s.rows[e.row].dataItem.scope_id != 2)) {
            wjcCore.addClass(e.cell, "btn-disabled");
          } else {
            wjcCore.removeClass(e.cell, "btn-disabled");
          }
        }
        if (s.cells === e.panel && s.columns[e.col].binding === this.bindings.scope_id) {
          if (!s.rows[e.row].dataItem) {
            wjcCore.addClass(e.cell, "btn-disabled");
          }
        }
        if (e.panel == s.cells && colBinding === 'source') {
          if (Object.values(CASBEE_TYPE).includes(s.rows[e.row]._data?.pattern_type)) {
            e.cell.innerHTML = e.cell.innerHTML.replace('<span class="wj-glyph-down"></span>', '');
          }
        }
        if (e.panel == s.cells && colBinding === 'view_detail') {
          if (s.rows[e.row]._data?.pattern_type === null || !s.rows[e.row]._data?.pattern_type) {
            e.cell.innerHTML = '';
          }
        }
        if(e.panel == s.cells && colBinding === 'attach_file') {
          if (e.cell.querySelector('button') && e.cell.querySelector('img')) {
            const img = e.cell.querySelector('img');
            e.cell.querySelector('button').addEventListener('mouseenter', function () {
              if (img) {
                img.setAttribute('src', require('@/assets/icons/file_attached_active.svg'));
              }
            });
          
            e.cell.querySelector('button').addEventListener('mouseleave', function () {
              if (img) {
                img.setAttribute('src', require('@/assets/icons/file_attached.svg'));
              }
            });
          }
        }
        const colBindingTooltip = [
          "name",
          "item_name",
          "unit",
          "value_source",
          "unit_source",
          "value_unit",
          "source",
          "year",
          "note",
          "status",
          "scope_id",
          "category_id",
          "view_detail"
        ];
        if (!colBindingTooltip.includes(colBinding)) {
          return;
        }
        if (e.panel === s.columnHeaders) {
          if (colBinding === "item_name") {
            toolTipCustom(
              e,
              "t1",
              this.$t('customized_db_management.tooltip_item_name'),
              this.hdrTips
            );
          }
          if (colBinding === "unit") {
            toolTipCustom(e, "t2", this.$t('customized_db_management.tooltip_unit'), this.hdrTips);
          }
          if (colBinding === "value_source") {
            toolTipCustom(e, "t3", this.$t('customized_db_management.tooltip_value_source'), this.hdrTips);
          }
          if (colBinding === "value_unit") {
            toolTipCustom(e, "t4", this.$t('customized_db_management.tooltip_unit_source'), this.hdrTips);
          }
          if (colBinding === "unit_source") {
            toolTipCustom(e, "t5", this.$t('customized_db_management.tooltip_unit_source'), this.hdrTips);
          }
          if (colBinding === "source") {
            toolTipCustom(
              e,
              "t6",
              this.$t('customized_db_management.tooltip_source'),
              this.hdrTips
            );
          }
          if (colBinding === "scope_id") {
            toolTipCustom(e, "t7", this.$t('customized_db_management.tooltip_scope_id'), this.hdrTips);
          }
          if (colBinding === "category_id") {
            toolTipCustom(e, "t8", this.$t('customized_db_management.tooltip_category_id'), this.hdrTips);
          }
          if (colBinding === "note") {
            toolTipCustom(
              e,
              "t9",
              this.$t('customized_db_management.tooltip_note'),
              this.hdrTips
            );
          }
          if (colBinding === "status") {
            toolTipCustom(
              e,
              "t10",
              this.$t('customized_db_management.tooltip_status'),
              this.hdrTips
            );
          }
          if (colBinding === "view_detail") {
            e.cell.innerHTML = e.cell.innerHTML.replace('<span class="wj-glyph-filter"></span>', '');
          }
        }
      });

      flexgrid.beginningEdit.addHandler((s, e) => {
        let column = s.columns[e.col];
        const { row, col } = e.range
        let rowValue            = s.rows[e.row]._data;

        if(Object.values(CASBEE_TYPE).includes(rowValue.pattern_type) && column.binding !== "status") {
          e.cancel = true;
          return;
        }

        if(column.binding === 'value_source') {
          const cellData = s.getCellData(row, col, false)
          const decimalSeparatorComma = [1,3].includes(this.getDigitsBySetting);
          const formatCell = decimalSeparatorComma ? removeSpecialChars(cellData)?.replace(/\./g, ',') : removeSpecialChars(cellData);
          s.setCellData(row, col, formatCell, false, true);
        }

        if (column.binding !== "status") {
          return;
        }

        this.dataDisabledAction = rowValue;

        this.stackUndoWhenUncheck = new GridEditAction(s, e);
        this.stackUndoScope       = new GridEditAction(s, e);
        this.stackUndoCategory    = new GridEditAction(s, e);
        e.cancel                  = true;

        if (!rowValue.status) {
          this.updateStatus(rowValue);
          return;
        }

        this.dialogPopup     = true;
        this.dialogCancelBtn = true;
      });

      flexgrid.pastingCell.addHandler((s, e) => {
        const { row } = e.range
        const view = s.collectionView
        const source = view.items; // Use view.items for filter and visible items
        const currentItem = source[row] || {}
        if(currentItem.pattern_type) {
          e.cancel = true
          return
        }
        // The handle while pasting value is only found once in the source.
        // Add into source before checking in editing cell handler.
        // Khi coppy rows trường hợp data dán vào chứa field source(dropdown) chưa tồn tại trong list -> Handle nó
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel == s.cells && colBinding == this.bindings.source) {
          let pasteData = e.data;
          const itemsSource = flexgrid.columns[e.col].editor.itemsSource;
          if (itemsSource.indexOf(pasteData) < 0) {
            itemsSource.push(pasteData);
          }
        }
      });
      flexgrid.pasted.addHandler((s, e) => {
        this.checkTableChangeData()
        const pasteData = e.data.split("\n").map((rawData) => rawData.split("\t"));
        const { col, col2, row, row2 } = e.range
        const view = s.collectionView
        const source = view.items; // Use view.items for filter and visible items
        pasteData.forEach((rowData, index) => {
          const currentItem = source[row] || {}
          if(currentItem?.pattern_type && currentItem?.pattern_type > 0) return
          if(rowData.length === 1 && rowData[0] === '') return
          const scopeValueData = rowData[5];
          const isValidScope = ['1','2', '3', 'その他'].includes(scopeValueData)
          if (scopeValueData && isValidScope) {
            flexgrid.setCellData(e.range.row + index, "scope_id", scopeValueData);
          } else if(scopeValueData && !isValidScope) {
            flexgrid.setCellData(e.range.row + index, "scope_id", '');
          }

          const categoryData = rowData[6];
          const isValidCategory = [
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
            '13',
            '14',
            '15',
            'その他',
            this.$t('customized_db_management.electricity_label'),
            this.$t('customized_db_management.heat_other_label'),
          ].includes(categoryData);
          if (categoryData && isValidCategory) {
            flexgrid.setCellData(e.range.row + index, "category_id", categoryData);
          } else {
            if (scopeValueData == "1" || scopeValueData == "その他") {
              flexgrid.setCellData(e.range.row + index, "category_id", "選択");
            } else if(categoryData && !isValidCategory) {
              flexgrid.setCellData(e.range.row + index, "category_id", "");
            }
          }
        });

        if (e.range.row === e.range.row2) {
          // paste one row
          // s.onItemsSourceChanged();
        }

        const dataPasted = s.collectionView.sourceCollection.filter((item, index) => index >= row && index <= row2);
        dataPasted.forEach(rowItem => {
          if(this.isItemActive(rowItem)) {
            rowItem.status = true
          }
        })
        for (let colIndex = col; colIndex <= col2; colIndex++) {
          for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
            const colBinding = s.columns[colIndex].binding
            if (colBinding === 'value_source') {
              let cellData = s.getCellData(rowIndex, colIndex, false)?.toString()
              const isNumber = $_helper_isNumberType_bySetting(cellData);
              s.setCellData(rowIndex, colIndex, isNumber ? formatNumberRealNum((removeSpecialChars(cellData))) : '', false, true);
            }
          }
        }
      });

      flexgrid?.cellEditEnded.addHandler((s, e) => {
        const { row, col } = e.range;
        const yearColumns = ['year_from', 'year_end']
        const binding = s.columns[e.col].binding

        if (binding === 'value_source') {
          let cellData = s.getCellData(row, col, false)?.toString()
          const isNumber = $_helper_isNumberType_bySetting(cellData);
          s.setCellData(row, col, isNumber ? formatNumberRealNum((removeSpecialChars(cellData))) : '', false, true);
        }
        if (yearColumns.includes(binding)) {
          let cellData = s.getCellData(row, col, false)?.toString()
          const isValidYear = $_helper_isNumberType(cellData) && this.yearList.includes(parseInt(cellData));
          s.setCellData(row, col, isValidYear ? cellData : '', false, true);
        }

        const view = s.collectionView;
        const source = view.sourceCollection;
        this.handleConvertFullWidthToHaftWidth(s, e);
        const currentItem = source[row] || {};
        this.checkTableChangeData()
        if(this.isItemActive(currentItem)) {
          currentItem.status = true
        }
      });

      flexgrid.deletedRow.addHandler(() => {
        this.checkTableChangeData();
      })

      flexgrid.pastedCell.addHandler((s, e) => {
        const {col, row} = e.range
        const binding = s.columns[col].binding;
        const yearColumns = ['year_from', 'year_end']

        if (yearColumns.includes(binding)) {
          let cellData = s.getCellData(row, col, false)?.toString()
          const isValidYear = $_helper_isNumberType(cellData) && this.yearList.includes(parseInt(cellData));
          s.setCellData(row, col, isValidYear ? cellData : '', false, true);
        }
      })
    },
    preprocessFetchedData(data) {
      let preprocessedData = [];
      data.forEach((element) => {
        const subStringRang = element?.value_source?.toString()?.includes('-') ? 26 : 25
        let itemData = {
          id          : element.id,
          name        : this.userName,
          scope_id    : element.scope_id == 4 ? this.diffText : element.scope_id,
          category_id : element.category_id == 16 ? this.diffText : element.category_id,
          item_name   : element.item_name,
          unit        : element.unit,
          value_source: formatNumberByConditions(element.value_source?.substring(0, subStringRang), { isRealNumber: true}),
          unit_source : element.unit_source,
          source      : element.source,
          status      : element.status ? true : false,
          year_from: element.year_from?.toString(),
          year_end: element.year_end?.toString(),
          month_from: element.month_from || null,
          month_end: element.month_end || null,
          type_ghg: element.type_ghg || null,
          computational_operations: element.computational_operations || null,
          note: element.note || null,
          updated_at  : formatDateTimeDataTable(element.updated_at),
          pattern_type: element.pattern_type,
          db_customize_detail_id: element.db_customize_detail_id,
          evidence: element.evidence,
        };

        if (parseInt(element?.scope_id) === 2) {
          itemData.category_id = this.categoriesScope2[element?.category_id];
        }

        if (element.scope_id != 3 && element.scope_id != 2) {
          itemData.category_id = this.selectionButtonText;
        }
        preprocessedData.push(itemData);
      });
      return preprocessedData;
    },
    preprocessAddData(data) {
      // Preprocess data for creating new custom DB.
      if (!data?.scope_id || !data?.category_id) return;
      let itemData = {
        scope_id     : data.scope_id === this.diffText ? 4 : data.scope_id,
        category_id  : data.category_id === this.diffText ? 16 : data.category_id,
        contractor_id: this.contractor,
        item_name    : data.item_name,
        unit         : data.unit,
        value_source : formatNumberBySetting(data.value_source),
        unit_source  : data.unit_source,
        source       : data.source,
        status       : data.status === false ? 0 : 1,
        year_from: data.year_from || null,
        year_end: data.year_end || null,
        month_from: parseInt(data.month_from) || null,
        month_end: parseInt(data.month_end) || null,
        type_ghg: data.type_ghg?.trim() || null,
        computational_operations: data.computational_operations?.trim() || null,
        note         : data.note === undefined ? "" : data.note,
        index: this.dbCustomViews.items.indexOf(data) + 1,
        evidence : data.evidence || null,
      };

      if (parseInt(data?.scope_id) === 2) {
        if (data?.category_id === this.categoriesScope2[1]) {
          itemData.category_id = 1;
        } else if (data?.category_id === this.categoriesScope2[2]) {
          itemData.category_id = 2;
        } else {
          itemData.category_id = data.category_id;
        }
      }

      if (parseInt(data.scope_id) === 1) {
        itemData.category_id = null;
      }
      if (data.scope_id === this.diffText) {
        itemData.category_id = 1;
      }
      return itemData;
    },
    preprocessUpdateData(data, index) {
      // Preprocess data for updating custom DB.
      if (!data || data?.scope_id === null) return;
      if ((parseInt(data?.scope_id === 3) || parseInt(data?.scope_id) === 2) && data?.category_id === '選択') return;
      let itemData = {
        id           : data?.id,
        scope_id     : data?.scope_id === this.diffText ? 4 : data?.scope_id,
        category_id  : data?.category_id === this.diffText ? 16 : data?.category_id,
        contractor_id: this.contractor,
        item_name    : data?.item_name,
        unit         : data?.unit,
        value_source : formatNumberBySetting(data?.value_source),
        unit_source  : data?.unit_source,
        source       : data?.source,
        status       : data.status === null ? 1 : data.status ? 1 : 0,
        year_from: data.year_from || null,
        year_end: data.year_end || null,
        month_end: parseInt(data.month_end) || null,
        month_from: parseInt(data.month_from) || null,
        type_ghg: data.type_ghg?.trim() || null,
        computational_operations: data.computational_operations?.trim() || null,
        note         : data?.note,
        index: index ? index + 1 : this.dbCustomViews.items.indexOf(data) + 1,
        evidence : data.evidence || null,
      };

      if (parseInt(data?.scope_id) === 2) {
        if (data?.category_id === this.categoriesScope2[1]) {
          itemData.category_id = 1;
        } else if (data?.category_id === this.categoriesScope2[2]) {
          itemData.category_id = 2;
        } else {
          itemData.category_id = data?.category_id;
        }
      }

      if (parseInt(data?.scope_id) === 1) {
        itemData.category_id = null;
      }
      if (data?.scope_id === this.diffText) {
        itemData.category_id = 1;
      }
      return itemData;
    },
    isValidData(item, isAddByClick = false) {
      if (!item) return false;
      let isValid = true;
      Object.keys(item).forEach((key) => {
        switch (key) {
          case "item_name":
            if (!item[key] || item[key].length > 128) {
              isValid = false;
            }
            break;
          case "unit":
          case "unit_source":
            if (!item[key] || item[key].length > 20) {
              isValid = false;
            }
            break;
          case "value_source":
            const maxLengthForValueSource = item[key]?.includes('-') ? 26 : 25
            if (!item[key] || item[key].length > maxLengthForValueSource || this.validateNumberField(item[key]) !== null) {
              isValid = false;
            }
            break;
          case "source":
            if (!item[key] || item[key].length > 255) {
              isValid = false;
            }
            break;
          case "scope_id":
            if (item[key]?.length > 10 || this.validateScope(item[key]) !== null) {
              isValid = false;
            }
            break;
          case "category_id":
            if (this.validateCategory(item) !== null) {
              isValid = false;
            }
            break;
          case "year_from":
          case "year_end":
            if (item[key]?.length > 4) {
              isValid = false;
            }
            break;
          case "month_from":
          case "month_end":
            if (item[key]?.length > 2 || (item[key] && item[key] > 12)) {
              isValid = false;
            }
            break;
          case "note":
          case "type_ghg":
          case "computational_operations":
            if (item[key]?.length > 255) {
              isValid = false;
            }
            break;
        }
      });
      if (!isValid && !isAddByClick) this.dialogValidate = true
      return isValid;
    },
    async fetchDbCustoms() {
      await getDbCustom({
        contractor_id: this.contractor
      }).then((res) => {
        this.isCasbee = res.is_casbee;
        this.totalData    = res.data.length;
        this.dbCustoms    = this.preprocessFetchedData(res.data);
        this.dbCustomsOriginal = this.preprocessFetchedData(res.data);
        this.statusUpdate = {
          time: res.updated_at_latest,
          user: res.user_updated
        };
        // Update source from table with status is true
        // Update source according to update time
        let activeSources = this.dbCustoms
          .filter(value => !Object.values(CASBEE_TYPE).includes(value?.pattern_type))
          .map((value) => {
            return value.source;
          });
        this.sources      = Array.from(new Set(activeSources.concat(this.sources)));
        this.categories   = res.categories;
        this.scopes       = res.scopes.map((index) => {
          return {
            id  : index,
            name: `${index !== 4 ? ' ' + this.$t('customized_db_management.table_scope_id') + ' ' + index : this.diffText}`
          };
        });
        this.defineTable();
      });
    },
    updateStatus(currentRow) {
      // if (!currentRow.id || currentRow.id === BLANK_ID) {
      //   return;
      // }
      let currentStatus = currentRow.status;
      this.changeCheckBoxStatus(currentStatus);
      this.closePopup();
      this.isEditDataTable = true
    },
    checkboxHandler() {
      const rowData = this.dbCustomsFlex.rows[this.dbCustomsFlex.selection.row].dataItem;
      this.updateStatus(rowData);
      // this.isEditDataTable = true
    },
    handleConvertFullWidthToHaftWidth(s, e) {
      const { row, col } = e.range;
      let cellData = s.getCellData(row, col, false)?.toString();
      const isNumber = $_helper_isNumberType(cellData);
      cellData = cellData && isNumber ? cellData : convertNestedFullWidthToHalfWidth(cellData);
      s.setCellData(row, col, cellData, false, true);
    },
    changeCheckBoxStatus(isChecked) {
      const rowIndex = this.dbCustomsFlex.selection.row
      const item = this.dbCustomsFlex.rows[rowIndex].dataItem
      this.dbCustomViews.editItem(this.dbCustomViews.items[rowIndex]);
      this.dbCustomViews.items[rowIndex].status = !isChecked;
      this.dbCustomViews.commitEdit();
      // this.dbCustomsFlex.beginUpdate();
      // item.status = !isChecked;
      // this.dbCustomsFlex.endUpdate();
      if (this.stackUndoWhenUncheck) {
        this.undoStack.pushAction(this.stackUndoWhenUncheck);
        this.stackUndoWhenUncheck = null;
      }

      const oldOriginValStatus = this.listOriginalData[rowIndex]?.status
      if(item?.id && item?.id !== BLANK_ID && oldOriginValStatus === isChecked) {
        this.idEditStatusList.push(item?.id)
      } else {
        this.idEditStatusList = this.idEditStatusList.filter(id => id !== item?.id)
      }
    },
    closePopup() {
      this.dialogMessage   = this.dialogBaseMessage;
      this.dialogPopup     = false;
      this.dialogCancelBtn = true;
    },
    closeRadioPopup() {
      this.radioDialog = false;
      this.radioType   = null;
      this.titlePopup = '';
    },
    onChangeRadio(newValue) {
      if(!newValue) {
        this.closeRadioPopup();
        return
      }
      // Get data when change
      if (this.radioType == this.types.category && newValue != this.activeRow.category_id) {
        this.dbCustomsFlex.beginUpdate();
        if (this.dbCustomsFlex.rows[this.context.row._idx].dataItem.scope_id == 3) {
          this.dbCustomsFlex.rows[this.context.row._idx].dataItem.category_id =
            newValue == 16 ? this.diffText : newValue;
        }
        if (this.dbCustomsFlex.rows[this.context.row._idx].dataItem.scope_id == 2) {
          this.dbCustomsFlex.rows[this.context.row._idx].dataItem.category_id = this.categoriesScope2[newValue];
        }
        this.dbCustomsFlex.endUpdate();
        if (this.stackUndoCategory) {
          this.undoStack.pushAction(this.stackUndoCategory);
          this.stackUndoCategory = null;
        }
      }
      if (this.radioType == this.types.scope && newValue != this.activeRow.scope_id) {
        this.dbCustomsFlex.beginUpdate();
        this.dbCustomsFlex.rows[this.context.row._idx].dataItem.scope_id = newValue == 4 ? this.diffText : String(newValue);
        if ([1, 2, 3, 4].includes(newValue)) {
          this.dbCustomsFlex.rows[this.context.row._idx].dataItem.category_id = this.selectionButtonText;
        }
        this.dbCustomsFlex.endUpdate();
        if (this.stackUndoScope) {
          this.undoStack.pushAction(this.stackUndoScope);
          this.stackUndoScope = null;
        }
      }
      if(this.isItemActive(this.activeRow)) {
        this.dbCustomsFlex.beginUpdate();
        this.dbCustomsFlex.rows[this.context.row._idx].dataItem.status = true;
        this.dbCustomsFlex.endUpdate();
      }
      const rowId = this.context.row.dataItem.id;
      if (rowId && rowId !== BLANK_ID) {
        if (this.idEditStatusList.indexOf(rowId) < 0) {
          this.idEditStatusList.push(rowId);
        }
      }
      this.context = null;
      this.isEditDataTable = true;
      this.closeRadioPopup();
    },
    onDoubleButtonEventScope(context) {
      let scopeEvent         = {};
      let categoryEvent      = {};
      scopeEvent.col         = context.col.index;
      scopeEvent.col2        = context.col.index;
      scopeEvent.row         = context.row.index;
      scopeEvent.row2        = context.row.index;
      categoryEvent.col      = context.col.index + 1;
      categoryEvent.col2     = context.col.index + 1;
      categoryEvent.row      = context.row.index;
      categoryEvent.row2     = context.row.index;
      this.stackUndoScope    = new GridEditAction(this.dbCustomsFlex, scopeEvent);
      this.stackUndoCategory = new GridEditAction(this.dbCustomsFlex, categoryEvent);
    },
    onDoubleButtonEventCategory(context) {
      let scopeEvent         = {};
      let categoryEvent      = {};
      scopeEvent.col         = context.col.index - 1;
      scopeEvent.col2        = context.col.index - 1;
      scopeEvent.row         = context.row.index;
      scopeEvent.row2        = context.row.index;
      categoryEvent.col      = context.col.index;
      categoryEvent.col2     = context.col.index;
      categoryEvent.row      = context.row.index;
      categoryEvent.row2     = context.row.index;
      this.stackUndoScope    = new GridEditAction(this.dbCustomsFlex, scopeEvent);
      this.stackUndoCategory = new GridEditAction(this.dbCustomsFlex, categoryEvent);
    },
    onScopeClicking(context) {
      if (Object.values(CASBEE_TYPE).includes(context?.item?.pattern_type)) return; // close event if item is casbee
      this.doubleButtonEvent = true;
      if (context.item && context.item.scope_id == this.diffText) {
        this.currentSelected = 4;
      } else {
        this.currentSelected = Number(context?.item?.scope_id) || null;
      }
      this.context     = context;
      this.radioType   = this.types.scope;
      this.activeRow   = context.item;
      this.items       = this.scopes;
      this.radioDialog = true;
      this.onDoubleButtonEventScope(this.context);
    },
    onCategoryClicking(context) {
      if (Object.values(CASBEE_TYPE).includes(context?.item?.pattern_type)) return; // close event if item is casbee
      this.doubleButtonEvent = true;
      if (context?.item?.scope_id == 3) {
        if (context?.item?.category_id === this.diffText) {
          this.currentSelected = 16;
        } else {
          this.currentSelected = Number(context?.item?.category_id) || null;
        }
      }
      if (context?.item?.scope_id == 2) {
        this.titlePopup = this.$t("customized_db_management.select_energy_type_title");
        if (context?.item?.category_id === this.categoriesScope2[1]) {
          this.currentSelected = 1;
        } else if (context?.item?.category_id === this.categoriesScope2[2]) {
          this.currentSelected = 2;
        } else {
          this.currentSelected = null;
        }
      }
      this.context    = context;
      this.activeRow  = context.item;
      this.radioType  = this.types.category;
      let validScopes = Object.keys(this.categories);
      if (validScopes.includes(String(context?.item?.scope_id)) && context?.item?.scope_id == 3) {
        this.items = this.categories[context.item.scope_id].map((category) => {
          return {
            id  : category,
            name: `${category != 16 ? ` ${this.$t('customized_db_management.table_category_id')} ` + category : this.diffText}`
          };
        });
      } else if (validScopes.includes(String(context?.item?.scope_id)) && context?.item?.scope_id == 2) {
        this.items = this.categories[context.item.scope_id].map((categoryId) => {
          return {
            id  : categoryId,
            name: this.categoriesScope2[categoryId],
          };
        });
      } else {
        this.items = [
          {
            id  : 0,
            name: `${this.$t('customized_db_management.table_category_id')} 1`
          }
        ];
      }
      this.onDoubleButtonEventCategory(this.context);
      this.radioDialog = true;
    },
    formatValue(valueInput) {
      if (!valueInput && valueInput !== 0) {
        return null;
      }
      return (valueInput || 0).toString().trim().replace(/,/g, '');
    },
    validateNumberField(input) {
      return validateNumberFieldBySetting(input)
      if (input) {

        if(this.formatValue(input).includes('-') && this.formatValue(input).length < 2) {
          return this.$t('validation.error_number_field') //error_number_field
        }

        let numberData = input.replace(/[^0-9\.\-]+/g, ''); // old regex: replace(/[^\d.]/g, "")
        
        if (numberData && input.length - numberData.length == 0) {
          return null;
        } else {
          return this.$t('validation.error_number_field');
        }
      }
    },
    validateScope(item) {
      if (item === undefined || item === null || item === this.$t('customized_db_management.button_choice')) {
        return this.$t('validation.error_required_field');
      }
      return null;
    },
    validateCategory(item) {
      if (item.scope_id === null || item.scope_id === undefined || item.scope_id === this.$t('customized_db_management.button_choice')) {
        return this.$t('validation.error_required_field');
      }
      if (
        (item.scope_id && Number(item.scope_id) === 3 && (item.category_id === null || item.category_id === undefined || item.category_id === this.$t('customized_db_management.button_choice'))) || 
        (item.scope_id && Number(item.scope_id) === 2 && (item.category_id === null || item.category_id === undefined || item.category_id === this.$t('customized_db_management.button_choice')))
      ) {
        return this.$t('validation.error_required_field');
      }
      return null;
    },
    validateData(item, propName) {
      // Update name validation.
      if (validateBlankRowData(
        item, 
        [
          'item_name',
          'unit',
          'value_source',
          'unit_source',
          'source',
          'scope_id',
          'category_id',
          'year_from',
          'year_end',
          'month_from',
          'month_end',
          'type_ghg',
          'computational_operations',
          'note'
        ], 
        true, 
        this.$t('customized_db_management.button_choice')
      )
      ) {
        return null;
      }
      switch (propName) {
        case this.bindings.item_name:
          return validateMaximumCharacter(item[propName], 128);
        case this.bindings.unit:
          return validateMaximumCharacter(item[propName], 20);
        case this.bindings.value_source:
          return validateMaximumCharacter(formatNumberBySetting(item[propName]), formatNumberBySetting(item[propName]).includes('-') ? 26 : 25) || this.validateNumberField(item[propName]);
        case this.bindings.unit_source:
          return validateMaximumCharacter(item[propName], 20);
        case this.bindings.source:
          return this.emptyCell(item[propName]) || validateMaximumCharacter(String(item[propName]), 255);
        case this.bindings.scope_id:
          return validateMaximumCharacter(String(item[propName]), 10) || this.validateScope(item[propName]);
        case this.bindings.category_id:
          return this.validateCategory(item);
        case this.bindings.note:
          return validateMaximumCharactorOnlyFacility(item[propName], 255);
        case this.bindings.updated_at:
          return validateMaximumCharacter(String(item[propName]), 30);
        case this.bindings.type_ghg:
        case this.bindings.computational_operations:
          return validateMaximumCharactorOnlyFacility(item[propName], 255);
      }
      return null;
    },
    emptyCell(item) {
      if (item === undefined || item === null) {
        return this.$t('validation.error_required_field');
      }
      return null;
    },
    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },
    async checkUserInfo() {
      await getUserInfo()
        .then((res) => {
          this.userName = res.name;
        })
        .catch(() => {
        });
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src                        = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip                        = false;
      } else {
        event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip                        = true;
      }
    },
    blankData(clientRowId) {
      return {
        clientRowId  : clientRowId,
        id           : BLANK_ID,
        scope_id     : this.$t("customized_db_management.button_choice"),
        category_id  : this.$t("customized_db_management.button_choice"),
        contractor_id: null,
        item_name    : null,
        unit         : null,
        value_source : null,
        unit_source  : null,
        source       : null,
        status       : false,
        note         : null,
        year_from: null,
        year_end: null,
        month_from: null,
        month_end: null,
        type_ghg: null,
        computational_operations: null,
      };
    },
    addBlankItemsToView(count) {
      const lastClientId = this.dbCustomViews.itemCount;
      for (let index = 1; index <= count; index++) {
        this.dbCustomViews.addNew(this.blankData(lastClientId + index));
      }

      this.dbCustomViews.commitNew();
      this.dbCustomViews.clearChanges();
    },
    scrollToTop() {
      if (!this.dbCustomsFlex) {
        return;
      }

      let rc                            = this.dbCustomsFlex.cells.getCellBoundingRect(0, 0, true);
      this.dbCustomsFlex.scrollPosition = new wjcCore.Point(this.dbCustomsFlex.scrollPosition.x, -rc.top);
    },
    initialView() {
      if (this.dbCustomViews) {
        this.dbCustoms = this.dbCustomViews.items.filter((item) => item.id && item.id !== BLANK_ID);
      }
      const formatItems = ['value_source', 'category_id'];
      this.dbCustomViews = new CollectionView([...this.dbCustoms], {
        trackChanges: true,
        sortComparer: (a, b) => {
          if (!formatItems.includes(this.selectedFilterColumn)) return null;
          if ($_helper_isNumberType(a) && $_helper_isNumberType(b)) {
            const numA = Number(this.formatValue(a));
            const numB = Number(this.formatValue(b));
            return numA - numB;
          }
          return null;
        }
      });

      this.addBlankItemsToView(10);
      this.dbCustomsFlex.columnGroups = this.gridColumns;
      this.dbCustomViews.currentItem = null;
      this.listOriginalData           = JSON.parse(JSON.stringify(this.dbCustomViews.items));
      setMinMaxSizeColumns(this.dbCustomsFlex, this.dbCustoms);
      this.dbCustomViews.collectionChanged.addHandler(() => {
        this.dbCustomViews?.itemsAdded?.forEach((itemAdd) => {
          if(this.isItemActive(itemAdd)) {
            itemAdd.status = true
          }
        })
      })
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.addBlankItemsToView(100);
        this.scrollToTop();
      } else {
        // this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    createUndoStack() {
      this.undoStack = new UndoStack("#undoable-table", {
        maxActions  : 50,
        stateChanged: (s) => {
          this.canUndo = s.canUndo;
          this.canRedo = s.canRedo;
        }
      });
    },
    checkValidationData() {
      this.dbCustomViews.getError = this.validateData;
      this.submitData();
    },
    async submitData() {
      let edited      = {};
      let editedBlank = {};
      let added       = {};
      let self        = this;
      const listKeyBinding = [
        'item_name',
        'unit',
        'value_source',
        'unit_source',
        'source',
        'scope_id',
        'category_id',
        'year',
        'note',
        'year_from',
        'year_end',
        'month_from',
        'month_end',
        'type_ghg',
        'computational_operations'
      ]
      const dbCustom = JSON.parse(JSON.stringify(self.dbCustomViews.items));

      const isInValidDate = this.dbCustomViews.itemsEdited.some((item) => {
        if (parseInt(item.year_from) > parseInt(item.year_end)) return true;
        if (parseInt(item.year_from) === parseInt(item.year_end) && parseInt(item.month_from) > parseInt(item.month_end)) return true;
        return false;
      })

      if (isInValidDate) {
        this.errorDialog = true; 
        return;
      }
      
      this.dbCustomViews.itemsEdited.forEach((item, index) => {
        if (validateBlankRowData(item, listKeyBinding, true, this.$t('customized_db_management.button_choice'))) {
          return;
        }
        const prepareItem = this.preprocessUpdateData(item)
        if(!prepareItem) {
          return
        }
        if (!this.isValidData(prepareItem, item?.isAddedByClick)) {
          if(item?.isAddedByClick) {
            return
          }
          this.dialogValidate = true
          return;
        }
        if (item.id === BLANK_ID) {
          delete prepareItem.id;
          editedBlank['edit'+index] = prepareItem;
        } else {
          if(item.id && item.id !== BLANK_ID) {
            edited[item.id] = prepareItem;
          }
        }
      })

      this.dbCustomViews.itemsAdded.forEach((item, index) => {
        if (validateBlankRowData(item, listKeyBinding, true, this.$t('customized_db_management.button_choice'))) {
          return;
        }
        const prepareItem = this.preprocessAddData(item)
        if (!prepareItem) {
          return;
        }
        if (!this.isValidData(prepareItem)) {
          this.dialogValidate = true
          return;
        }
        added[index] = this.preprocessAddData(item);
      });

      if(this.idEditStatusList.length > 0) {
        dbCustom.forEach((item, index) => {
          if(this.idEditStatusList.includes(item?.id)) {
            const prepareItem = this.preprocessUpdateData(item, index)
            edited[item.id] = prepareItem;
          }
        })
      }

      const isAnyValidData = Object.values(edited).length || Object.values(editedBlank).length || Object.values(added).length

      if(isAnyValidData) this.dialogValidate = false 

      // call api
      const editedPromise = Object.values(edited).length ? updateDbCustom({ data: Object.values(edited) }).then((res) => {
        // update origin data when change data table success
        for (let key in edited) { // key is index
          this.dbCustomsOriginal[key] = edited[key]
        }
        this.isEditDataTable = false
        this.listOriginalData = JSON.parse(JSON.stringify(this.dbCustomViews.items));
        this.idEditStatusList = []
        this.dialogNotification = true;
        this.undoStack.clear();
      }).catch((error) => {
        console.warn(error);
      }) : emptyPromise();

      const editedBlankPromise = Object.values(editedBlank).length ? createDbCustom({ data: Object.values(editedBlank) }).then((res) => {
        const itemsWithoutNull = this.dbCustomViews.itemsEdited.filter(item => !validateBlankRowData(item, listKeyBinding, true, this.$t('customized_db_management.button_choice')));
        this.afterDataSuccess(itemsWithoutNull, editedBlank, res);
      }).catch((error) => {
        console.warn(error);
      }) : emptyPromise();

      const addedPromise = Object.values(added).length ? createDbCustom({ data: Object.values(added) }).then((res) => {
        const itemsWithoutNull = this.dbCustomViews.itemsAdded.filter(item => !validateBlankRowData(item, listKeyBinding, true, this.$t('customized_db_management.button_choice')));
        this.afterDataSuccess(itemsWithoutNull, added, res);
      }).catch((error) => {
        console.warn(error);
      }) : emptyPromise();

      Promise.all([editedPromise, editedBlankPromise, addedPromise]);
    },
    validateItems(item) {
      for (const key in item) {
        if ((item?.category_id !== "選択" || item?.scope_id !== "選択") && item[key] !== null) {
          this.isValidData(item);
          return;
        }
      }
    },
    afterDataSuccess(collection, items, response, isEdited = false) {
      const addedReplacement = {
        id        : "id",
        status    : "status",
        updated_at: "updated_at"
      };
      this.statusUpdate      = {
        time: response.latest_record.updated_at_latest,
        user: response.latest_record.user_updated
      };
      let recordInsert       = null;
      if (isEdited) {
        recordInsert = Object.values(items).map((item) => {
          return {
            ...item,
            updated_at: formatDateTimeDataTable(response.latest_record?.updated_at_latest)
          };
        });
      } else {
        recordInsert = response.record_insert.map((item) => {
          return {
            ...item,
            updated_at: formatDateTimeDataTable(item.updated_at)
          };
        });
      }
      this.dbCustomViews.deferUpdate(() => {
        batchReplacementViewCollection(collection, recordInsert, Object.keys(items), addedReplacement);
        collection.length = 0;
      });
      this.listOriginalData = JSON.parse(JSON.stringify(this.dbCustomViews.items));
      this.dialogNotification = true;
      this.undoStack.clear();
    },
    isItemActive(item) {
      const listCellRequire = ['item_name', 'scope_id', 'source', 'unit', 'value_source']
      let activeByScope = true
      if((parseInt(item.scope_id) === 3 || parseInt(item.scope_id) === 2) && !item.category_id) {
        activeByScope = false
      }
      return listCellRequire.every(key => item[key]) && (!item.id || item.id === BLANK_ID) && activeByScope
    },
    handleClosePopupCasbee() {
      this.isShowMessageCasbe = false;
      const path = this.isProductPage ? ROUTES.PRODUCTS_EMISSION + ROUTES.CREATE_DATA_CUSTOMIZE : ROUTES.CREATE_DATA_CUSTOMIZE;
      this.$router.replace({ path: path }).catch(() => {})
    },
    onViewDetailClicking(context) {
      if (!Object.values(CASBEE_TYPE).includes(context?.item?.pattern_type)) return;
      return this.$router.push({ name: this.isProductPage ? 'ProductsCasbeeDetail' : 'CasbeeDetail', params: {id: context?.item?.db_customize_detail_id}, query: {'id_db_customize': context?.item?.id} });
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    checkTableChangeData() {
      setTimeout(() => {
        this.isEditDataTable = this.dbCustomViews?.itemsEdited.length > 0 || this.dbCustomViews?.itemsAdded.length > 0
        const itemsInView = ['item_name', 'name', 'note', 'source', 'unit', 'unit_source', 'value_source', 'year']
        this.dbCustomViews?.itemsEdited.some((item) => {
          const everyColumnsAreNull = itemsInView.every(
            (field) => item[field] === null || item[field] === '' || item[field] === undefined,
          );
          const isValidScopeCategory = item.scope_id !== this.$t('customized_db_management.table_scope_id')
          if (!everyColumnsAreNull && isValidScopeCategory) {
            this.isEditDataTable = true;
            return true;
          }
        });

        this.dbCustomViews?.itemsAdded.some((item) => {
          const everyColumnsAreNull = itemsInView.every(
            (field) => item[field] === null || item[field] === '' || item[field] === undefined,
          );
          const isValidScopeCategory = item.scope_id !== this.$t('customized_db_management.table_scope_id')
          if (!everyColumnsAreNull && isValidScopeCategory) {
            this.isEditDataTable = true;
            return true;
          }
        });
      }, 100)
    },
  },
  watch   : {
    startAction(newValue, _) {
      if (newValue.undo) {
        this.undoStack.undo();
      } else if (newValue.redo) {
        this.undoStack.redo();
      }
      this.checkTableChangeData()
    },

    canUndo(newValue) {
      this.actionUpdateStatusBtn({
        undo: newValue,
        redo: this.canRedo
      });
    },

    canRedo(newValue) {
      this.actionUpdateStatusBtn({
        undo: this.canUndo,
        redo: newValue
      });
    },

    casbeeModel(newValue) {
      if (newValue === CASBEE.CASBEE) {
        const path = this.isProductPage ? ROUTES.PRODUCTS_EMISSION + ROUTES.CASBEE : ROUTES.CASBEE;
        return this.$router.push({ path: path });
      }
    }
  }
};
</script>
<style lang="scss">
.dbCustomize-row.wj-cell {
  .wj-cell-maker {
    display: flex;
    max-height: 23px;
    justify-content: center;
    background: $monoLight;
    color: $monoBlack !important;
    border-radius: 4px;
    padding: 1px 6px 3px;
    text-align: center;
    font-size: 13px;
    font-family: "Source Han Sans";
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    position: unset !important;
    &:hover {
      color: $monoWhite !important;
      background: #0072a3;
    }
  }
}
.wj-flexgrid .casbee-detail.wj-cell{
  padding: 4px !important;
  .wj-cell-maker {
    color: $goldMid !important;
    background: unset;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border: 1px solid $goldLight;
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    // min-height: 24px;
    min-width: 38px;
    position: unset !important;
    button {
      margin: auto;
    }
    &:hover {
      color: $monoWhite !important;
      background: #0072a3;
    }
  }
}
.table-db_custom {
  .btn-disabled {
    .wj-cell-maker {
      color: $monoMid !important;
    }
  }
  .wj-colheaders {
    .wj-row {
      .wj-cell {
        &.hide-filter {
          .wj-btn-glyph {
            display: none;
          }
        }
      }
    }
  }
  .file-attached-container {
    width: 18px;
    height: 18px;
    .file_attached {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
    }
  }
}
</style>
<style scoped lang="scss">
@import '../managements/styles/index.scss';
.casbee-menu {
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
}
@include desktop {
  .casbee-menu {
    justify-content: flex-end;
  }
}
</style>
