<template>
  <div class="wrap">
    <div class="header-title">
      <pageTitle :title="$t('basic_unit_library.label_title')" />
      <div class="header-wrapper">
        <div class="header-form">
          <div class="header-form-field">
            <label class="header-form-field-label">{{ $t('basic_unit_library.label_search') }}</label>
            <InputField :getOriginalValue="true" v-model="payloadGetListBasicUnit.keyword" :isReadOnly="isLoadingTableOnly" @handlePressEnterButton="handlePressEnterButton"/>
          </div>
          <div class="header-form-field condition-pulldown">
            <label class="header-form-field-label">{{ $t('basic_unit_library.label_pulldown_filter') }}</label>
            <div class="condition-search-btn">
              <v-select
                solo
                flat
                :items="conditionList"
                v-model="conditionData"
                item-text="text"
                item-value="value"
                class="select-type list-menu--select select-item custom-pulldown-select select-condition"
                :menu-props="{ contentClass: 'select-menu export-select' }"
                @change="onChangeConditionPulldown"
              />
              <CommonButton
                :label="$t('register_data.button_search')"
                type="colored"
                @action="handleSeachFilter()"
                :isDisable="isLoadingTableOnly"
                v-ripple="false"
              />
            </div>
          </div>
        </div>
        <div class="header-form">
          <div class="header-form-field">
            <label class="header-form-field-label">{{ $t('basic_unit_library.label_displayed_results') }}</label>
            <v-select
              solo
              flat
              :items="pagesizeList"
              :value="currentPageSize"
              class="select-type list-menu--select select-item custom-pulldown-select select-pagesize"
              :menu-props="{ contentClass: 'select-menu export-select' }"
              @change="onChangePulldownPageSize"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="category-table main-table custom-table submited-table">
      <data-table
        :data-source="listBasicUnitGrid"
        :grid-columns="listSubmitColumn"
        :init-grid="initGrid"
        :rowFocus="1"
        :allowAddNew="false"
        :isShowToolbar="false"
        :showFocus="false"
        :isEmptySource="isEmptySource"
        :isResizeCustome="false"
        :currentPage="currentPage"
        :currentPageSize="currentPageSize"
        :labelEmpty="$t('basic_unit_library.empty_source')"
        class="table-list-data"
        @changeFullScreen="onChangeFullScreen"
        @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
        @initializedFilter="initializedFilter"
      />
    </div>
    <Paging
      :key="reRenderPaging"
      :totalDataList="totalDataList"
      :currentPageSize="currentPageSize"
      :isEmptySource="isEmptySource"
      @handleSelectedPage="handleSelectedPage"
      @handlePressEnterButton="handlePressEnterButtonPaging"
    />
  </div>
</template>

<script>
import * as wjcCore from '@mescius/wijmo';
import DataTable from '@/components/category/data-table';
import { ROUTES } from '@/router/constants';
import { KEYS_CODE } from '@/constants/keyboard';
import { mapActions, mapState } from 'vuex';
import { Point } from '@mescius/wijmo';
import { CollectionView } from '@mescius/wijmo';
import { CellRange } from '@mescius/wijmo.grid';
import { $_helper_isNumberType, formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import Paging from '@/components/commonApp/Paging';
import { getListBasicUnit } from '@/api/basic-unit.js';
import { CONDITIONLIST, DEFAULT_PAGING, PAGE_SIZE_LIST } from '@/constants/basic-unit.js';
import { formatNumberRealNum } from '@/utils/convertNumber';
import pageTitle from '@/components/pageTitle/index.vue';
import InputField from '@/components/products/input/InputField.vue';
import CommonButton from '@/components/utils/button.vue';
import { rangeDateFormat } from '@/utils/datetimeFormat';

export default {
  components: {
    DataTable,
    Paging,
    pageTitle,
    InputField,
    CommonButton,
  },
  data() {
    return {
      breadCrumb: [
        {
          text: this.$t('list_submited.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('basic_unit_library.label_title'),
          disabled: true,
          href: ROUTES.LIST_EMISSION_TEMPORARY,
        },
      ],
      pagesizeList: PAGE_SIZE_LIST,
      listSubmitColumn: [],
      listBasicUnitGrid: null,
      flexGrid: null,
      listBasicUnit: [],
      dialog: false,
      logContents: [],
      showTooltip: false,
      selectedFilterColumn: null,
      isEmptySource: true,
      conditionList: CONDITIONLIST,
      conditionData: '1',
      payloadGetListBasicUnit: {
        condition: '',
        keyword: '',
      },
      currentPageSize: DEFAULT_PAGING.pageSize,
      currentPage: 1,
      reRenderPaging: 0,
      filter: null
    };
  },
  async mounted() {
    this.actionUpdateIsLoadingTable(true);
    await Promise.all([this.updateBreadCrumb(this.breadCrumb), this.handleGetListBasicUnit(this.payloadGetListBasicUnit)]);
    this.defineTable();
    this.actionUpdateIsLoadingTable(false);
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
    ...mapActions('newRegisterData', ['updateIsApproved']),
    ...mapActions('registerData', ['updateDataScope', 'updateDataCategory', 'updateDataMonth', 'updateDuration']),

    initGrid(grid) {
      this.flexGrid = grid;

      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new CellRange(currentSelection.row, 1);
            }
          }
          if (event.ctrlKey && event.key === 'c') {
            let selection = grid.selection;
            if ((selection.leftCol === 1 && selection.leftCol === selection.rightCol) ||
              (selection.leftCol === 2 && selection.leftCol === selection.rightCol) ||
              (selection.leftCol === 1 && selection.rightCol === 2)
            ) {
              const newArr = [...new Set(window.getSelection().toString().replace(/\n/g, ',').replace(/\t/g, ',').split(','))];
              if (navigator.clipboard) {
                navigator.clipboard.writeText(newArr.join(' ').trim());
              }  else {
                // Fallback method for older browsers
                const textarea = document.createElement('textarea');
                textarea.value = newArr.join(' ').trim();
                document.body.appendChild(textarea);
                textarea.select();
                document.execCommand('copy');
                document.body.removeChild(textarea);
              }
            }
          }
        },
        false,
      );

      grid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel == s.cells && colBinding === 'computational_operations') {
          const heightOfACell = 32;
          const cellElement = e.cell;
          if (cellElement.textContent && cellElement.offsetHeight === heightOfACell) {
            const newDiv = document.createElement('div')
            newDiv.classList.add('vertical-center');
            newDiv.textContent = cellElement.textContent;
            cellElement.innerHTML  = '';
            cellElement.appendChild(newDiv);
          }
          if (cellElement.offsetHeight > 32) {
            wjcCore.addClass(e.cell, 'align-center');
          }
        }
      });
    },
    async handleGetListBasicUnit(payload) {
      try {
        const res = await getListBasicUnit(payload);
        this.listBasicUnit = res?.map((item) => {
          return {
            ...item,
            value_source: formatNumberRealNum(item?.value_source),
            type_ghg: item.type_db == 0 && (item?.type_ghg === null || item?.type_ghg == '') ? this.$t("basic_unit_library.text_not_set") : item.type_ghg,
            computational_operations: item.type_db == 0 && (item?.computational_operations === null || item?.computational_operations == '') ? this.$t("basic_unit_library.text_not_set") : item.computational_operations,
            expired_time: rangeDateFormat({
              year: item?.year_from,
              month: item?.month_from,
            }) + ' - ' + rangeDateFormat({
              year: item?.year_end,
              month: item?.month_end,
            })
          };
        });
        
        this.isEmptySource = this.listBasicUnit.length <= 0;
        // lazy loading data
        // this.handleLoadMoreData({payload, totalItem: res.total});
      } catch (error) {
        console.warn(error);
      }
    },
    async handleLoadMoreData(params) {
      const { payload, totalItem } = params;
      if (payload.per_page >= totalItem) return;
      try {
        const res = await getListBasicUnit({
          ...payload,
          per_page: totalItem
        });
        this.listBasicUnitGrid.sourceCollection = res;
        this.listBasicUnitGrid.refresh();
        this.handleSelectedPage(this.currentPage);
      } catch (error) {
        console.warn(error);
      } 
    },
    initialView() {
      this.listBasicUnitGrid = new CollectionView([...this.listBasicUnit], {
        pageSize: DEFAULT_PAGING.pageSize,
        sortComparer: (a, b) => {
          if (this.selectedFilterColumn !== 'category_name') return null;
          const valueA = a?.split('カテゴリ')[1]?.trim();
          const valueB = b?.split('カテゴリ')[1]?.trim();
          if ($_helper_isNumberType(valueA) && $_helper_isNumberType(valueB)) {
            const numA = Number(formatValue(valueA));
            const numB = Number(formatValue(valueB));
            return numA - numB;
          }
          return null;
        },
      });
      this.flexGrid.columnGroups = this.getListSubmitColumn();
      this.listBasicUnitGrid.currentItem = null;
      setMinMaxSizeColumns(this.flexGrid, this.listBasicUnit);
      this.handleTableFilter();
    },
    initializedFilter(filter) {
      this.filter = filter;
    },
    getListSubmitColumn() {
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: this.$t("basic_unit_library.table_ghg_type"),
          binding: 'type_ghg',
          align: 'center',
          minWidth: getWidthByTextContent(this.$t("basic_unit_library.table_ghg_type")),
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          allowMerging: true,
          cssClass: 'align-left',
        },
        {
          header: this.$t("basic_unit_library.table_active_cover"),
          binding: 'computational_operations',
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          allowMerging: true,
        },
        {
          header: this.$t("basic_unit_library.basic_unit_name"),
          binding: 'name_basic',
          minWidth: 500,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t("56_pattern.table_qd_value"),
          binding: 'value_source',
          minWidth: 70,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          align: 'right',
        },
        {
          header: this.$t("56_pattern.table_qd_unit"),
          binding: 'unit_source',
          minWidth: 70,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          cssClassAll: '',
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t("56_pattern.table_wsu_source"),
          binding: 'source',
          minWidth: 300,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t("basic_unit_library.label_expired_time"),
          binding: 'expired_time',
          width: '*',
          minWidth: getWidthByTextContent(this.$t('basic_unit_library.label_expired_time')),
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
      ];
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    defineTable() {
      this.listSubmitColumn = this.getListSubmitColumn();
      this.initialView();
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    async handleSeachFilter() {
      this.actionUpdateIsLoadingTable(true);
      try {
        this.onChangeConditionPulldown(this.conditionData);
        await this.handleGetListBasicUnit({ ...this.payloadGetListBasicUnit});
        this.handleAddDataCollection();
        this.handleSelectedPage(1);
        this.reRenderPaging++; // force re-render paging
        this.actionUpdateIsLoadingTable(false);
      } catch (err) {
        console.warn(err);
        this.actionUpdateIsLoadingTable(false);
      }
    },
    onChangeConditionPulldown(value) {
      const selectedCondition = CONDITIONLIST.find((item) => item.value == value)?.text;
      this.payloadGetListBasicUnit = {
        ...this.payloadGetListBasicUnit,
        condition: selectedCondition,
      };
    },
    handleSelectedPage(currentPage) {
      this.currentPage = currentPage;
      this.listBasicUnitGrid.moveToPage(currentPage - 1);
      setMinMaxSizeColumns(this.flexGrid, this.listBasicUnitGrid.sourceCollection);
    },
    onChangePulldownPageSize(pageSizeSelected) {
      this.listBasicUnitGrid.pageSize = parseInt(pageSizeSelected);
      this.currentPageSize = parseInt(pageSizeSelected);
    },
    handleAddDataCollection() {
      this.listBasicUnitGrid.sourceCollection = [];
      this.listBasicUnitGrid.sourceCollection = [...this.listBasicUnit];
      this.listBasicUnitGrid.refresh();
    },
    handleTableFilter() {
      this.$nextTick(() => {
        this.filter.filterChanged.addHandler((s, e) => {
          const column = s.grid.getColumn(e.col);
          if (['name_basic', 'value_source', 'unit_source', 'source'].includes(column.binding)) {
            const columnFilter = s.getColumnFilter(column.binding);
            const nameBasicFilterText = s.getColumnFilter('name_basic').valueFilter.filterText;
            const valueSourceFilterText = s.getColumnFilter('value_source').valueFilter.filterText;
            const unitSourceFilterText = s.getColumnFilter('unit_source').valueFilter.filterText;
            const sourceFilterText = s.getColumnFilter('source').valueFilter.filterText;

            if (!columnFilter.conditionFilter.isActive) {
              // handle case filter not use condition
              const checkedValues = columnFilter.valueFilter.showValues || {};

              if (!columnFilter.valueFilter.filterText && Object.keys(checkedValues).length == 0) {
                // handle case when clear filter
                columnFilter.valueFilter.clear()
              } else if (columnFilter.valueFilter.filterText) {
                e.cancel = true;
                const showValues = {}
                s.grid.collectionView.filter = (item) => {
                  if (nameBasicFilterText && !item['name_basic'].toLowerCase().includes(nameBasicFilterText)) {
                    return false
                  }
                  if (valueSourceFilterText && !item['value_source'].toLowerCase().includes(valueSourceFilterText)) {
                    return false
                  }
                  if (unitSourceFilterText && !item['unit_source'].toLowerCase().includes(unitSourceFilterText)) {
                    return false
                  }
                  if (sourceFilterText && !item['source'].toLowerCase().includes(sourceFilterText)) {
                    return false
                  }
                  if (Object.keys(checkedValues).length > 0 && Object.keys(checkedValues).length < 250) {
                    // handle for case has filter text and check options
                    if (!Object.keys(checkedValues).includes(item[column.binding])) {
                      return false;
                    }
                  }
                  showValues[item[column.binding]] = true
                  return true
                }
                columnFilter.valueFilter.showValues = showValues;
              }
            }
          }
        })
      })
    },
    handlePressEnterButton() {
      this.handleSeachFilter()
    },
    handlePressEnterButtonPaging(pageInput) {
      if($_helper_isNumberType(pageInput)) {
        this.handleSelectedPage(pageInput)
      }
    }
  },
  computed: {
    ...mapState('commonApp', ['isExpand', 'isLoadingTableOnly']),
    totalDataList() {
      return this.listBasicUnitGrid?.totalItemCount;
    },
    
  },
};
</script>

<style scoped lang="scss">
.custom-table {
  margin-bottom: 26px;
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}
::v-deep .submited-table {
  .wj-flexgrid .wj-cell[aria-readonly] {
    color: $monoBlack !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly].template-detail-header {
    padding: 4px !important;
  }

  .wj-flexgrid .wj-cell-maker {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $monoBlack;
    padding: 0;
  }

  .wj-flexgrid .wj-cell[aria-readonly] {
    &.align-left {
      justify-content: flex-start;
    }
    &.align-center {
      display: flex;
      align-items: center;
      white-space: unset;
    }
    .vertical-center {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.category-table {
  ::v-deep .wrap-table {
    max-height: 687px !important;
    &.wj-flexgrid [wj-part=root] {
      max-height: 687px !important;
    }
  }
}
@include desktop {
  .custom-table {
    padding-left: 0 !important;
  }
}

.header-title {
  margin: 40px 0 48px 0;
  ::v-deep .v-virtual-scroll {
    .v-virtual-scroll__container {
      .v-virtual-scroll__item {
        .dropdown-item {
         width: 100% !important;
        }
      }
    }
  }
  .header-wrapper {
    display: flex;
    flex-flow: column;
    gap: 16px;
    margin-top: 48px;
    .header-form {
      display: flex;
      flex-flow: column;
      gap: 8px;
      &-field {
        display: flex;
        flex-flow: column;
        gap: 8px;
        &.condition-pulldown {
          min-width: 155px;
          .condition-search-btn {
            display: flex;
            gap: 8px;
            width: fit-content;
          }
        }
        .custom-pulldown-select {
          width: 108px;
          &.select-pagesize {
            width: 90px;
          }
        }
        .input-field {
          width: 277px;
        }
        &-label {
          color: $monoBlack;
          font-family: "Source Han Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.42px;
        }
        ::v-deep {
          .select-item {
            .v-input__slot {
              background: $monoWhite !important;
            }
          }
          .select-condition {
            width: 80px;
            .v-select__selection {
              font-weight: 700;
              font-size: 11px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}
@include desktop {
  .header-title {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 48px;
    margin: 40px 40px 24px;
    .header-wrapper {
      flex-flow: row;
      margin-top: unset;
      gap: 56px;
      .header-form {
        flex-flow: row;
      }
    }
  }
}
</style>
