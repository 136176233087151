<template>
  <div>
    <v-dialog
      v-model="getDialog"
      fullscreen
      ref="dialog"
      :persistent="true"
      content-class="modal-fullscreen width-100pc"
      transition="dialog-bottom-transition"
      @click:outside="handleClickOutSite"
    >
      <div class="content-main">
        <div class="head-category-popup">
          <span class="log-confirm_text mr-auto">{{$t('b_export_report.title_export_data')}}</span>
          <div class="list-emission-close" @click="getDialog = false">
            <img
              class="head-image-dialog-popup mr-0 log-confirm--btn close"
              src="@/assets/images/dialog/close.svg"
            />
            <img
              class="head-image-dialog-popup mr-0 log-confirm--btn close-active"
              src="@/assets/images/dialog/close_active.svg"
            />
            <div class="list-emission-close__text">{{$t('b_export_report.button_close')}}</div>
          </div>
        </div>
        <div class="head-emission-category-border"></div>
        <div class="page-header">{{ getTitle }}</div> 
        <div class="export-report-popup">
          <div class="title-btn">
            <span>{{$t('b_export_report.label_output_method')}}</span>
            <div class="title-require">{{$t('b_register_product.label_essential')}}</div>
          </div>
          <div class="selection__item">
            <export-pulldown :key="reRenderKey.methods" :dataList="dataList.methods" :selectedData.sync="dataExport.methods" :itemCount="itemCountItemMethods" :placeholder="$t('b_export_report.placeholder_selected_calculation_method')" :isCollapsedProp="false" :disabled="disabledLayer" class="box-shadow-pull-down" type="listScopeCategories" :isShowTextPcaf="true"/>
          </div>
          <div class="export-icon">
            <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
          </div>
          <div class="title-btn">
            <span>{{$t('b_export_report.label_period')}}</span>
            <div class="title-require">{{$t('b_export_report.label_required')}}</div>
          </div>
          <div class="export-pull-down">
            <div class="warp-select-item listMenu-btn-text">
              <v-select
                solo
                flat
                :items="startYearList"
                v-model="dataExport.yearStartAt"
                item-text="text"
                item-value="value"
                :label="$t('list_menu.placeholder_select_year')"
                class="select-type list-menu--select select-item"
                :menu-props="{ contentClass: 'select-menu export-select export pulldown-year' }"
              />
            </div>
            <div class="warp-select-item listMenu-btn-text">
              <v-select
                solo
                flat
                :items="startMonthList"
                v-model="dataExport.monthStartAt"
                item-text="text"
                item-value="value"
                :label="$t('list_menu.placeholder_select_month')"
                class="select-type list-menu--select select-item"
                :menu-props="{ contentClass: 'select-menu export-select export pulldown-year' }"
              />
            </div>
          </div>
          <div class="subtracted-divider">|</div>
          <div class="export-pull-down">
            <div class="warp-select-item listMenu-btn-text">
              <v-select
                solo
                flat
                :items="endYearList"
                v-model="dataExport.yearEndAt"
                item-text="text"
                item-value="value"
                :label="$t('list_menu.placeholder_select_year')"
                class="select-type list-menu--select select-item"
                :menu-props="{ contentClass: 'select-menu export-select export pulldown-year' }"
              />
            </div>
            <div class="warp-select-item listMenu-btn-text">
              <v-select
                solo
                flat
                :items="endMonthList"
                v-model="dataExport.monthEndAt"
                item-text="text"
                item-value="value"
                :label="$t('list_menu.placeholder_select_month')"
                class="select-type list-menu--select select-item"
                :menu-props="{ contentClass: 'select-menu export-select export pulldown-year' }"
              />
            </div>
          </div>
          <div class="export-icon">
            <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
          </div>
          <div class="title-btn">
            <span>{{$t('b_export_report.label_narrow_down')}}</span>
          </div>
          <div class="export-pull-down margin-pull-down">
            <export-pulldown :key="reRenderKey.organizational" :dataList="dataList.organizational" :selectedData.sync="dataExport.organizational" :itemCount="dataList.organizational.length - 1" :placeholder="$t('b_export_report.placeholder_organizational_classification')" :arrowDownBtn="true" class="box-shadow-pull-down"/>
          </div>
          <div class="export-pull-down">
            <export-pulldown :key="reRenderKey.companies" :dataList="dataList.companies" :selectedData.sync="dataExport.companies" :itemCount="dataList.companies.length - 1" :placeholder="$t('b_export_report.placeholder_select_a_corporation')" :hasSearch="true" class="box-shadow-pull-down select-corporation-btn"/>
          </div>
          <hr style="width: 100%; margin: 48px 0 32px;color: #4d4d4d; opacity: .4;">
          <div class="warp-submit">
            <common-button
              class="submit-btn"
              :label="$t('b_export_report.button_export_popup')"
              type="colored"
              v-ripple="false"
              :disabled="validateData()"
              @action="handlerExportData"
            />
            <FormatExportText :isExportPopup="true" />
          </div> 
        </div>
      </div>
    </v-dialog>
    <error-popup
      :dialog="errorDialog"
      :message="errorMessage"
      @submit="errorDialog = false"
    />
  </div>
</template>
<script>
import { scopeCategoriesData, GET_ALL, oldmethod, newPcafMethod, newPcafMethodOriginal } from '@/constants/export-report.js'
import CommonButton from '@/components/utils/button.vue';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import ExportPulldown from '@/components/pulldown/exportReport';
import { SCOPE, CATEGORY } from '@/constants/registerData'
import { DESCRIPTIONS } from '@/constants/descriptions';
import { getExportReport, getExportReportSummary } from '@/api/export-report';
import moment from "moment";
import { mapState } from 'vuex'
import { getMonthListCustome, calcYearFromStartMonthCustome } from '@/utils/registerData';
import { getStartMonth } from "@/api/duration";
import { setDataTypeForPcafMethod, handleUpdateRowNumpattern } from '@/utils/pcaf';
import { getDataTypeApi } from '@/api/pcaf'
import { PCAF_CATEGORY_TYPE_METHOD_1, PCAF_CATEGORY_TYPE_METHOD_2 } from '@/constants/pcaf';
import FormatExportText from '@/components/csvFormat/export-text';
export default {
  components: { CommonButton, ErrorPopup, ExportPulldown, FormatExportText },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    method: {
      type: Number,
      default: 1,
    },
    methodLayer2: {
      type: Number,
      default: 1,
    },
    monthYearSelectionList: {
      type: Array,
      default: () => [],
    },
    yearList: {
      type: Array,
      default: () => [],
    },
    monthList: {
      type: Array,
      default: () => [],
    },
    branchData: {
      type: Object,
      default: () => {},
    },
    isSummaryScore: {
      type: Boolean,
      default: false,
    },
    summaryScoreType: {
      type: Number,
      default: 1,
    },
    type_classify: {
      type: Number,
      default: null,
    },
    type_data: {
      type: Number,
      default: null,
    },
    dataDate: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      scope: 1,
      category: 1,
      workFlowData: {},
      dataExport: {
        methodExport: 1,
        companies: [],
        organizational: [],
        methods: [],
        monthStartAt: this.dataDate?.startMonth || null,
        yearStartAt: this.dataDate?.startYear || null,
        monthEndAt: this.dataDate?.endMonth || null,
        yearEndAt: this.dataDate?.endYear || null,
      },
      dataList: {
        organizational: [],
        companies: [],
        methods: [],
        companyBranchIds: [],
      },
      msgSelectDuration: '',
      isCollapsed: [],
      isCollapsedLayer1: [],
      showPullDown: false,
      selectList: [],
      checked: false,
      getSelectedData: [],
      getAllText: this.$t("b_export_report.checkbox_select_all"),
      errorDialog: false,
      errorMessage: '',
      filterIndex: [],
      reRenderKey: {
        organizational: 0,
        companies: 0,
        methods: 0,
      },
      disabledLayer: false,
      itemCount: 0,
      startMonth: null,
      startYearList: [],
      endYearList: [],
      startMonthList: [],
      endMonthList: [],
      dataListMethods: [],
      itemCountItemMethods: 0,
      allDataType: {},
    };
  },
  async mounted() {
    this.scope = parseInt(this.$route.params.scope);
    this.category = parseInt(this.$route.params.category);
    if (this.$route.path.includes('emissions/view/score-summary')) {
      // check condition in score summary screen
      this.scope = 3;
      this.category = 15;
    }
    await this.handleGetDataType();

    this.defaultData();
    this.convertData();
    await Promise.all([this.getDataStartMonth()])
    this.setDataYearAndMonth()
    this.selectList = []
  },
  computed: {
    ...mapState("userData", ["isSettingPcaf", "planType", "language"]),
    getDialog: {
      get() {
        let htmlElement = document.getElementsByTagName('html')[0];
        htmlElement.style.overflow = this.dialog ? 'hidden' : 'auto';
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      }
    },
    getTitle() {
      if(!this.scope) return ''
      if (this.scope > SCOPE.SCOPE_2) {
        if (this.category === CATEGORY.CATEGORY_16) {
          const title = `「Scope ${this.scope} > ` + DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'] + `」`;
          return this.$t("b_export_report.label_output_the_data", { nameCode: title});
        } else if (this.scope === SCOPE.SCOPE_4) {
          const title = `「${this.$t("register_data.title_emission_scope4")} > ` + DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'] + `」`;
          return this.$t("b_export_report.label_output_the_data", { nameCode: title});
        } else {
          const title = `「Scope ${this.scope} > ${this.category}. ` +
            DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'] + `」`;
          return this.$t("b_export_report.label_output_the_data", { nameCode: title});
        }
      } else {
        const title = `「Scope ${this.scope} > ` + DESCRIPTIONS['scope' + this.scope]['category' + this.category]['title'] + `」`;
        return this.$t("b_export_report.label_output_the_data", { nameCode: title});
      }
    },
  },
  watch:{
    'dataExport.organizational': {
      handler() {
        this.addFilterIndex('organizational');
        if (this.dataExport.organizational.length === 0) {
          this.addFilterIndex('organizational', true);
        }
        this.updateFilter();
      },
      deep: true
    },
    'dataExport.companies': {
      handler() {
        this.addFilterIndex('company');
        if (this.dataExport.companies.length === 0) {
          this.addFilterIndex('company', true);
        }
        this.updateFilter();
      },
      deep: true
    },
    'dataExport.monthStartAt': {
      handler() {
        this.startYearList = calcYearFromStartMonthCustome(this.startMonth, this.dataExport.yearStartAt)
        const indexYear = this.startYearList.findIndex(item => item.value === this.dataExport.yearStartAt)
        if(indexYear < 0) {
          this.dataExport.yearStartAt = null
        }
        this.checkDuration('startAt');
      }
    },
    'dataExport.yearStartAt': {
      handler() {
        this.startMonthList = getMonthListCustome(this.dataExport.yearStartAt, this.startMonth)
        const indexMonth =  this.startMonthList.findIndex(item => item.value === this.dataExport.monthStartAt)
        if(indexMonth < 0) {
          this.dataExport.monthStartAt = null
        }
        this.checkDuration('startAt');
      }
    },
    'dataExport.monthEndAt': {
      handler() {
        this.endYearList = calcYearFromStartMonthCustome(this.startMonth, this.dataExport.yearEndAt)
        const indexYear = this.endYearList.findIndex(item => item.value === this.dataExport.yearEndAt)
        if(indexYear < 0) {
          this.dataExport.yearEndAt = null
        }
        this.checkDuration('endAt');
      }
    },
    'dataExport.yearEndAt': {
      handler() {
        this.endMonthList = getMonthListCustome(this.dataExport.yearEndAt, this.startMonth)
        const indexMonth =  this.endMonthList.findIndex(item => item.value === this.dataExport.monthEndAt)
        if(indexMonth < 0) {
          this.dataExport.monthEndAt = null
        }
        this.checkDuration('endAt');
      }
    },
    'dataList.methods': {
      handler() {
        if (this.dataList.methods.length) {
          this.dataListMethods = [];
          this.dataList.methods.map(item => {
            if (item?.methods) {
              item?.methods?.map(itemLayer1 => {
                if (!itemLayer1?.methods) {
                  this.dataListMethods.push(`${item.text}_${itemLayer1.text}`);
                } else {
                  itemLayer1?.methods?.map(itemLayer2 => {
                    this.dataListMethods.push(`${item.text}_${itemLayer1.text}_${itemLayer2.text}`);
                  })
                }
              })
            }
          });
          this.itemCountItemMethods = this.dataListMethods.length;
        }
      },
      deep: true
    }
  },
  methods: {
    validateData() {
      return !(this.dataExport.methods.length && this.dataExport.monthStartAt !== null && this.dataExport.yearStartAt !== null && this.dataExport.monthEndAt !== null && this.dataExport.yearEndAt !== null);
    },
    handleClickOutSite() {
      this.getDialog = false;
    },
    checkDuration(isStart = '') {
      if(this.dataExport.monthStartAt !== null && this.dataExport.yearStartAt !== null && this.dataExport.monthEndAt !== null && this.dataExport.yearEndAt !== null) {
        const monthStart = moment(this.dataExport.monthStartAt, 'MM').format('MM');
        const monthEnd = moment(this.dataExport.monthEndAt, 'MM').format('MM');
        const yearStart = moment(this.dataExport.yearStartAt, 'YYYY').format('YYYY');
        const yearEnd = moment(this.dataExport.yearEndAt, 'YYYY').format('YYYY');
        const dateStart = moment(`${monthStart} ${yearStart}`, 'MM YYYY');
        const dateEnd = moment(`${monthEnd} ${yearEnd}`, 'MM YYYY');

        if (dateStart.isAfter(dateEnd)) {
          this.errorMessage = isStart === 'startAt' ? this.$t("popup.popup_month_before_end_date") : this.$t("popup.popup_month_after_start_date");
          this.openScopePopup();
          return false;
        }
        return true;
      }
      return false;
    },
    openScopePopup() {
      this.errorDialog = true;
    },
    defaultData() {
      this.dataList.organizational.push({
        text: GET_ALL,
        hasCheckbox: true,
      })
      this.branchData.organizational_division?.filter(item => item.status === true)?.map(item => {
      this.dataList.organizational.push({
        text: item.value,
        hasCheckbox: true,
        branch_ids: item.branch_ids,
        id: item.key,
      })
    });
      this.dataList.companies.push({
        text: GET_ALL,
        hasCheckbox: true,
      });
      this.branchData.company_name?.filter(item => item.status === true)?.map(item => {
        this.dataList.companies.push({
          text: item.value,
          hasCheckbox: true,
        })
      });
      this.branchData.companyBranchIds?.map(item => {
        this.dataList.companyBranchIds.push({
          organizational_division: item.organizational_division,
          company_name: item.company_name,
          branch_ids: item.branch_ids,
          organizational_division_id: item.organizational_division_id
        })
      });
    },
    async handleGetDataType() {
      try {
        const responseDataType = await getDataTypeApi()
        this.allDataType = responseDataType.data
      } catch (error) {
        console.warn(error);
      }
    },
    convertData(){
      if(!this.scope) return
      this.dataExport.methods = []
      let scopeCategoriesDataPattern = scopeCategoriesData
      if(this.isSettingPcaf) {
        const indexGhg = scopeCategoriesDataPattern.findIndex(item => item.categories === 15)
        scopeCategoriesDataPattern[indexGhg].methods = this.isSummaryScore ?  newPcafMethodOriginal : newPcafMethod
      } else {
        const indexGhg = scopeCategoriesDataPattern.findIndex(item => item.categories === 15)
        scopeCategoriesDataPattern[indexGhg].methods = oldmethod
      }
      let newScopeCategories = scopeCategoriesDataPattern.find(item => item.scope === this.scope && item.categories === this.category);
      this.dataList.methods = [
        {
          text: this.getAllText,
          hasCheckbox: true,
        }
      ]
      if(this.planType?.is_pcaf && this.$route.fullPath.includes('/emissions/view/3/15') && !this.isSummaryScore) {
        const pcafMethod = setDataTypeForPcafMethod(newPcafMethod, this.allDataType)
        newScopeCategories.methods = pcafMethod;
        this.dataList.methods.push(newScopeCategories);
      }
      else {
        this.dataList.methods.push(newScopeCategories);
      }
      if (!newScopeCategories?.methods) {
        this.disabledLayer = true;
        this.dataExport.methods.push(`${newScopeCategories.text}`);
      } else {
        this.dataList.methods.map(item => {
          let existItemLayer1 = item?.methods?.find(method => method.method === this.method);
          if (this.methodLayer2) {
            existItemLayer1 = item?.methods?.find(method => method.method === this.method && method.methodLayer2 === this.methodLayer2);
          }
          if (existItemLayer1) {
            if(this.planType?.is_pcaf && this.$route.fullPath.includes('/emissions/view/3/15') && !this.isSummaryScore) {
              let itemSelectTypePCAF = {};
              if (this.type_classify) {
                itemSelectTypePCAF = existItemLayer1.methods.find(method => method.data_type === this.type_data && method?.type_classify === this.type_classify)
              } else {
                itemSelectTypePCAF = existItemLayer1.methods.find(method => method.data_type === this.type_data)
              }
              this.dataExport.methods.push(`${item.text}_${existItemLayer1.text}_${itemSelectTypePCAF.text}`);
            } else {
              this.dataExport.methods.push(`${item.text}_${existItemLayer1.text}`);
            }
          } else {
            item?.methods?.map(itemLayer1 => {
              let existItemLayer2 = itemLayer1?.methods?.find(method => method.method === this.method)
              if (this.methodLayer2) {
                existItemLayer2 = itemLayer1?.methods?.find(method => method.method === this.method && method.methodLayer2 === this.methodLayer2)
              }
              if (existItemLayer2) {
                this.dataExport.methods.push(`${item.text}_${itemLayer1.text}_${existItemLayer2.text}`);
              }
            })
          }
        })
      }
      this.reRenderKey.methods++;
    },
    toggleCollapse(index) {
      this.$set(this.isCollapsed, index, !this.isCollapsed[index]);
    },
    getMethodLayer1(index) {
      return this.dataList.methods[index]?.methods;
    },
    getMethodLayer2(layer1Index, index) {
      return this.dataList.methods[index]?.methods[layer1Index]?.methods;
    },
    formatDuration(monthStart, yearStart) {
      return moment(`${monthStart} ${yearStart}`, 'MM YYYY').format('YYYY-MM');
    },
    async handlerExportData() {
      if (!this.checkDuration() || this.dataExport.methods.length === 0) return
      let payload = {
        contractor_id : this.$store.state.userData.contractor,
        start_at : this.formatDuration(this.dataExport.monthStartAt, this.dataExport.yearStartAt),
        end_at : this.formatDuration(this.dataExport.monthEndAt, this.dataExport.yearEndAt),
        organizational_division_branch_ids : [],
        company_name_branch_ids: [],
        list_row_num_pattern : [],
      }
      
      payload.list_row_num_pattern = this.dataExport.methods.map(item => {
        const splitItem = item.split('_');
        let data = scopeCategoriesData.find(method => method.text === splitItem[0]);
        if(data.categories === 15 && this.isSettingPcaf && !this.isSummaryScore) { //handle for pcaf
          const listDataTypeSelect = []
          const methodSelect = data.methods.find(item => item.text === splitItem[1])
          const nameTypeSelect = splitItem.slice(1).join('_')
          const detailType = methodSelect.methods.find(method => method.text_select === nameTypeSelect)
          //get type_classify from PCAF_CATEGORY_TYPE_METHOD_1 or 2
          const typeClassifyObj = detailType?.row_num_pattern === 47 ? PCAF_CATEGORY_TYPE_METHOD_1 : PCAF_CATEGORY_TYPE_METHOD_2
          const typeDetail = typeClassifyObj.find(typeItem => typeItem.name === splitItem[2])
          if(detailType?.data_type) {
            listDataTypeSelect.push(detailType.data_type)
          }
          return {
            row_num_pattern: detailType?.row_num_pattern,
            type_data: listDataTypeSelect,
            type_classify: typeDetail?.id 
          }
        } else {
          for(let i = 1; i < splitItem.length; i++) {
            data = data?.methods.find(method => method.text === splitItem[i]);
          }
          return data?.row_num_pattern;
        }
      
      })
      payload.list_row_num_pattern = handleUpdateRowNumpattern(payload.list_row_num_pattern)
      if (this.dataExport.companies.length === 0 && this.dataExport.organizational.length !== 0) {
        this.dataExport.organizational.map(item => {
          const branchIds = this.dataList.organizational.find(organi => organi.text === item);
          payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(branchIds?.branch_ids);
        })
      } else if (this.dataExport.organizational.length === 0 && this.dataExport.companies.length !== 0) {
        this.dataExport.companies.map(item => {
          this.dataList.companyBranchIds.filter(company => company.company_name === item)
          .map(branchId => {
            payload.company_name_branch_ids = payload.company_name_branch_ids.concat(branchId?.branch_ids);
          })
        }) 
      } else {
        if (this.filterIndex.length > 0) {
          if (this.filterIndex[0] === 'organizational') {
            let newArray = [...this.dataList.companyBranchIds];
            let newOrgari = [];
            for (let i = newArray.length - 1; i >= 0; i--) {
              if (this.dataExport.companies.includes(newArray[i].company_name) && this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newOrgari.push(newArray[i].organizational_division);
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(newArray[i].branch_ids);
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(newArray[i].branch_ids);
                newArray.splice(i, 1);
              } else if (!this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newArray.splice(i, 1);
              }
            }
            newArray.map(item => {
              if (!newOrgari.includes(item.organizational_division)) {
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(item.branch_ids);
              }
            })
          } else {
            let newArray = [...this.dataList.companyBranchIds];
            let newCompaies = [];
            for (let i = newArray.length - 1; i >= 0; i--) {
              if (this.dataExport.companies.includes(newArray[i].company_name) && this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newCompaies.push(newArray[i].company_name);
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(newArray[i].branch_ids);
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(newArray[i].branch_ids);
                newArray.splice(i, 1);
              } else if (!this.dataExport.companies.includes(newArray[i].company_name)) {
                newArray.splice(i, 1);
              }
            }
            newArray.map(item => {
              if (!newCompaies.includes(item.company_name)) {
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(item.branch_ids);
              }
            })
          }
        }
      }
      if(this.isSummaryScore) {
        payload["type"] = this.summaryScoreType;
      }
      this.getDialog = false;

      if(!this.isSummaryScore) {
        await getExportReport(payload, true).then(res => {
          this.getCsvExport(res, payload);
        }).catch(error => {
          console.log(error);
        });
      } else {
        // handle export summary
        getExportReportSummary(payload).then(res => {
          this.getCsvExportSummary(res)
        }).catch(error => {
          console.log(error);
        });
      }
      
    },
    getCsvExportSummary(content) {
      const fullDate  = moment().format("YYYYMMDD");
      const fileType = this.summaryScoreType === 1 ? this.$t('setting_pcaf.summary_score_item_asset_class') : this.$t('setting_pcaf.summary_score_item_sector');
      const nameFileExport = `Scope 3_15-${this.$t('register_data.title_scope_3_category_15')}-${fileType}_`
      const fullNameExport = `${nameFileExport}${fullDate}.csv`;
      let url = window.URL.createObjectURL(new Blob([content]))
      const link = document.createElement("a");
      link.href  = url;
      link.setAttribute("download", fullNameExport);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getCsvExport(content, payload) {
      const fullDate  = moment().format("YYYYMMDD");
      let excelName = `${this.$t('a_export_report.zip_emission_data')}_${fullDate}.zip`;
      const scoped2 = payload.list_row_num_pattern.includes(4);
      if (this.dataExport.methods.length === 1 && !scoped2) {
        const listTextMethod = this.dataExport.methods.toString().split('_');
        let textCsv = "";
        
        let nameScope = "";
        const patternNumber = payload.list_row_num_pattern[0]
        if(patternNumber === 45) {
          nameScope = `${this.$t('a_export_report.label_scope_3')} 3-16. ${this.$t('a_export_report.file_name_scope3_category16')}`;
        } else if (patternNumber === 46) {
          nameScope = this.$t('a_export_report.file_name_scope4');
        } else {
          nameScope = listTextMethod[0];
        }

        textCsv = nameScope.replace(/-|\.\s/g, (match) => {
          if (match === "-") {
            return "_";
          } else {
            return "-";
          }
        });
        if(listTextMethod.length > 2) {
            if (typeof patternNumber == 'number') {
              if(patternNumber > 40) {
                textCsv = textCsv + '_' + listTextMethod[1] + '_' + listTextMethod[2];
              } else {
                textCsv = textCsv + '_' + listTextMethod[2];
              }
            } else {
              if(this.planType?.is_pcaf && patternNumber.row_num_pattern >= 47 && patternNumber.row_num_pattern <= 53 && !this.isSummaryScore) {
                if(patternNumber.row_num_pattern === 47 || patternNumber.row_num_pattern === 48) {
                  textCsv = textCsv + '_' + listTextMethod.splice(2).join('_');
                } else {
                  textCsv = textCsv + '_' + listTextMethod.splice(1).join('_');
                }
              }
            }
          } else if (listTextMethod.length == 2) {
            textCsv = textCsv + '_' + listTextMethod[1];
          }

        textCsv = textCsv.replace('/', '_')
        if(textCsv.length > 190) {
          textCsv = textCsv.slice(0, 186)
        }
        excelName = `${textCsv}_${fullDate}.csv`;
      }
      let url = window.URL.createObjectURL(new Blob([content]))
      const link = document.createElement("a");
      link.href  = url;
      link.setAttribute("download", excelName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    addFilterIndex(type, isRemove = false) {
      const existIndex = this.filterIndex.findIndex((item) => {
        return item === type;
      });
      if (isRemove) {
        if (this.filterIndex[0] === type) {
          this.dataExport.companies.length = 0;
          this.dataExport.organizational.length = 0;
          this.filterIndex.length = 0;
          this.dataList.companyBranchIds.map(item => {
            this.addOrganiToList(item);
            this.addCompanyToList(item);
          })
          this.organiListSort();
          type === 'organizational' ? this.reRenderKey.companies++ : this.reRenderKey.organizational++;
        } else {
          this.filterIndex.splice(existIndex, 1);
        }
      }
      if (existIndex < 0) {
        this.filterIndex.push(type);
      }
    },
    updateFilter() {
      if (this.filterIndex.length === 0) {
        this.dataList.companyBranchIds.map(item => {
          this.addOrganiToList(item);
          this.addCompanyToList(item);
        })
        this.organiListSort();
      } else {
        if (this.filterIndex[0] === 'organizational') {
          this.dataList.companies.length = 0;
          this.dataList.companies.push({
            text: GET_ALL,
            hasCheckbox: true,
          })
          this.dataExport.organizational.map(organi => {
            this.dataList.companyBranchIds.filter(companyItem => companyItem.organizational_division === organi)
            .map(item => {
              this.addCompanyToList(item);
            })
          })
          const companies = this.dataList.companies.map(company => company.text);
          this.dataExport.companies.map((item, index) => {
            if (!companies.includes(item)) {
              this.dataExport.companies.splice(index, 1);
            }
          })
        } else { // this.filterIndex[0] === 'company'
          this.dataList.organizational.length = 0;
          this.dataList.organizational.push({
            text: GET_ALL,
            hasCheckbox: true,
          })
          this.dataExport.companies.map(company => {
            this.dataList.companyBranchIds.filter(organiItem => organiItem.company_name === company)
            .map(item => {
              this.addOrganiToList(item);
            })
          })
          const organiDivisions = this.dataList.organizational.map(organi => organi.text);
          this.dataExport.organizational.map((item, index ) => {
            if (!organiDivisions.includes(item)) {
              this.dataExport.organizational.splice(index, 1);
            }
          })
          this.organiListSort();
        }
      }
    },
    addOrganiToList(item) {
      const existIndex = this.dataList.organizational.findIndex(organi => organi.text === item.organizational_division);
      if (existIndex < 0) {
        this.dataList.organizational.push({
          text: item.organizational_division,
          hasCheckbox: true,
          branch_ids: item.branch_ids,
          id: item.organizational_division_id,
        })
      }
    },
    addCompanyToList(item) {
      const existIndex = this.dataList.companies.findIndex(company => company.text === item.company_name);
      if (existIndex < 0) {
        this.dataList.companies.push({
          text: item.company_name,
          hasCheckbox: true,
        })
      }
    },
    toggleDropdown() {
      this.showPullDown = !this.showPullDown;
    },
    organiListSort() {
      this.dataList.organizational.sort((a, b) => {
        return a.id - b.id;
      })
    },
    async getDataStartMonth() {
      try {
        const res = await getStartMonth(this.$store.state.userData.contractor)
        this.startMonth = res.data?.start_month
      } catch (error) {
        console.warn(error);
      }
    },
    setDataYearAndMonth() {
      //startYearList, endYearList
      this.startYearList = calcYearFromStartMonthCustome(this.startMonth, 2017)
      this.endYearList = [ ...this.startYearList ]
      this.startMonthList =  getMonthListCustome(this.dataExport.yearStartAt, this.startMonth)
      this.endMonthList = getMonthListCustome(this.dataExport.yearEndAt, this.startMonth)
    },
    getAllMethods() {
      let scopeCategoriesList = []
      this.dataList.methods.forEach(item => {
        if (!item?.methods && item.row_num_pattern) {
          scopeCategoriesList.push({
            text: item.text,
            value: item.row_num_pattern,
          });
        } else if (item.methods) {
          item.methods.forEach(itemLayer1 => {
            if (!itemLayer1.methods && itemLayer1.row_num_pattern) {
              scopeCategoriesList.push({
                text: `${item.text}_${itemLayer1.text}`,
                value: itemLayer1.row_num_pattern,
              });
            } else if (itemLayer1.methods) {
              itemLayer1.methods.forEach(itemLayer2 => {
                scopeCategoriesList.push({
                  text: `${item.text}_${itemLayer1.text}_${itemLayer2.text}`,
                  value: itemLayer2.row_num_pattern,
                });
              })
            }
          })
        }
      })
      return scopeCategoriesList;
    },
  },
};
</script>
<style lang="scss">
.modal-fullscreen {
  background: $bgExtraLight;
  .listMenu-btn-text {
    .v-text-field.v-text-field--solo .v-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoMid;
    }
  }
}
</style>
<style lang="scss" scoped>
.modal-fullscreen {
  position: relative;
  max-width: 100%;
  &.width-100pc {
    max-width: 100%;
    width: 100%;
  }
  .content-main {
    height: 100%;
    margin-bottom: 80px;
    @include tablet {      
      display: grid;
      grid-template-rows: auto auto auto 1fr;
      align-items: center;
      padding-bottom: 48px;
    }
    .head-category-popup {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 20px;

      .log-confirm_text {
        height: 32px;
        // font-family: 'Source Han Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.03em;
        color: $goldMid;
      }
      .list-emission-close {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 66px;
        height: 62px;
        background: #F7F7F2;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        border-radius: 4px;
        cursor: pointer;
        &__text {
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.03em;
          color: $monoBlack;
        }
        .close-active {
          display: none;
        }
        .close {
          display: block;
        }
        &:hover {
          background: $goldLight;
          .close-active {
            display: block;
          }
          .close {
            display: none;
          }
          .list-emission-close__text  {
            color: $monoWhite;
          }
        }
        &:active {
          background: $monoOffWhite;
          border: 2px solid $blueMid;
          .close-active {
            display: none;
          }
          .close {
            display: block;
          }
          .list-emission-close__text  {
            color: $monoBlack;
          }
        }
      }
    }
    .head-emission-category-border {
      width: 100%;
      height: 1px;
      background: rgba(121, 134, 134, 0.12);
    }
    .page-header {
      margin-top: 48px;
      margin-bottom: 48px;
      color: $monoBlack;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      padding: 0 40px;
      word-break: break-all;
    }
    .export-report-popup {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 560px;
      //height: 592px;
      margin: 0 auto;
      padding: 32px 0;
      background: $bgLight;
      .selection__item {
        width: 100%;
      }
      .description-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 32px;
        // font-family: 'Source Han Sans JP';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        background: $monoOffWhite;
        position: absolute;
        .selected-count {
          color: $monoMid;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 0.05em;
        }
      }
    }
  }
  .dialog-title {
    font-size: 18px;
  }
  .tooltip-none {
    font-size: 14px;
    padding: 20px 0px;
    title {
      .title-icon {
        display: none;
      }
    }
  }
  .selection__item {
    padding: 0 32px;
    .select-item .v-input__slot .v-input__append-inner {
      display: none;
    }
    .pull-down-text-color {
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      transition: 0.2s ease-in-out;

      .item {
        padding: 5px 10px;
        border-bottom: 1px solid #ccc;
        .input-checkbox {
          margin-right: 8px;
        }
      }
    }
  }
  .title-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    margin: 0 32px 8px;
    .title-require {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 4px 2px;
      width: fit-content;
      height: 20px;
      background: $goldMid;
      border-radius: 4px;
      color: $monoWhite;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.03em;
    }
  }
  .export-pull-down {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 32px;
    &.margin-pull-down {
      margin-bottom: 16px;
    }
    .warp-select-item {
      width: 100%;
      &:last-child {
        margin-left: 16px;
      }
    }
  }
  .subtracted-divider {
    color: $monoMid;
    margin: 16px 0;
    text-align: center;
    width: 100%;
  }
  .err-item {
      color: $redMid;
      font-size: 13px;
      margin: 0;
    }
  .export-icon {
    width: 36px;
    margin: 24px auto;
    display: flex;
    justify-items: center;
  }
  .warp-submit{
    width: 100%;
    padding: 0 32px;
    .submit-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 20px;
      background: $goldMid;
      width: 100%;
      height: 40px;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      border-radius: 4px;
      color: $monoWhite;
      &:hover {
        /* Gold/Light */
  
        background: $goldLight;
        box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24),
          0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48);
      }
      &:focus-within {
        border: 2px solid $blueMid;
        padding: 6px 0px 16px;
        color: $monoWhite;
      }
    }
  }
  .strikethrough {
    position: absolute;
    left: 0;
    right: 0;
    color: #f7f7f2;
    opacity: .5;
  }
}
.box-shadow-pull-down.select-corporation-btn {
  ::v-deep #dropdownInputText {
    &:hover {
      background: $goldLight;
      box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48), 0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31), 0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24), 0px 4px 14px 0px rgba(160, 181, 186, 0.17);
      .placeholder,.input-text {
        color: $monoWhite;
      }
    }
  }
  &::v-deep.pulldown-wrapper.disabled {
    #dropdownInputText {
      &:hover {
        background: $monoLight;
        box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      }
      .placeholder,.input-text {
        color: $monoMid;
      }
      &.focus-pull-down {
        border: none;
      }
    }
  }
}
@include desktop {
  .modal-fullscreen {
    .content-main {
      .head-category-popup {
        padding: 16px 40px;
      }
    }
  }
}

@media (max-width: 768px) {
  .head-category-popup {
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 2;
    background: #eff5f5;
    width: 100%;
    border-bottom: solid 1px rgba(121, 134, 134, 0.12);;
  }

  .page-header {
    margin-top: 142px !important;
  }
}
</style>
