<template>
  <div id="idApp">
    <router-view />
    <div v-for="(popup, index) in popups" :key="index">
      <notification-popup :dialog="true" :message="popup.message" @submit="closePopup(index)" :confirmText='$t("register_data.button_close")' />
    </div>
  </div>
</template>

<script>
import NotificationPopup from '@/components/dialogs/notification-popup';
import { getNotiPopUpWhenImportCsvSuccess } from "@/api/auth";
import { ROUTES } from "@/router/constants";
export default {
  name: 'App',
  data() {
    return {
      dialogNotiImportSuccess: false,
      messageDialogImportSuccess: '',
      checkInterval: null, // ID của setInterval
      popups: []
    };
  },
  components: {
    NotificationPopup,
  },
  methods: {
    // Kiểm tra trạng thái trong localStorage
    isImportingGroupsActive() {
      return localStorage.getItem("hasGroupsImporting") === "true" && !this.isAuthChildRoute();
    },
    isAuthChildRoute() {
      const authPath = ROUTES.AUTH;
      return this.$route.path.startsWith(authPath);
    },

    closePopup(index) {
      this.popups.splice(index, 1);
      // reload component list all to get data
      if (this.popups.length === 0) {
        const channel = new BroadcastChannel('reloadListAllAfterImportSuccess');
        channel.postMessage({ type: 'reloadListAllAfterImportSuccessEvent' });
      }
    },

    // Gọi API để lấy thông báo
    async fetchMessages() {
      try {
        const response = await getNotiPopUpWhenImportCsvSuccess();
        if (response.data?.length) {
          this.popups = response.data.map((item) => ({
            message: item.message || ''
          }));
          localStorage.setItem('hasGroupsImporting', "false"); // Reset trạng thái
        }
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    },

    // Theo dõi định kỳ trạng thái localStorage
    startCheckingStorage() {
      this.checkInterval = setInterval(() => {
        if (this.isImportingGroupsActive()) {
          this.fetchMessages();
        }
      }, 5000);
    }
  },
  mounted() {
    if (document.querySelector('[data-app]') === null) {
      const appElement = document.createElement('div');
      appElement.setAttribute('data-app', '');
      document.body.appendChild(appElement);
    }

    if (this.isImportingGroupsActive()) {
      this.fetchMessages();
    }

    this.startCheckingStorage();
  },
  beforeDestroy() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  },
};
</script>
