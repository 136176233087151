<template>
  <div class="data-transfer">
    <div class="data-transfer__label">{{ $t("batch_import_csv.label_data_movement") }}</div>
    <div class="data-transfer__buttons">
      <button class="data-transfer__button" :class="isDisablePrevious && 'disabled'" outlined
        @click="handleClickBtnDataMoving()">
        <img src="@/assets/images/emissions/button/previous.svg" alt="" class="button-icon" />{{
          $t("batch_import_csv.button_data_movement_previous") }}
      </button>
      <button class="data-transfer__button" :class="isDisableNext && 'disabled'" outlined
        @click="handleClickBtnDataMoving(true)">
        {{ $t("batch_import_csv.button_data_movement_next") }}
        <img src="@/assets/images/emissions/button/next.svg" alt="" class="button-icon" />
      </button>
    </div>
  </div>
</template>

<script>
import { getListWorkflowApi } from "@/api/registerData";
import { ROUTES } from '@/router/constants';

export default {
  name: "DataTransfer",
  props: {
    isUpdateCurrrentId: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      currentIdWorkflow: null,
      moveIdWorkflow: null,
      dataList: [],
      isDisablePrevious: false,
      isDisableNext: false,
    };
  },
  async mounted() {
    // If click to details from list, keep value filter, another case call API get details
    // Remove dataListMoving from sessionStorage
    const dataList = sessionStorage.getItem('dataListMoving')
    if (dataList) {
      this.dataList = JSON.parse(dataList);
      sessionStorage.removeItem('dataListMoving');
    } else {
      const scopeActionMap = {
        [ROUTES.LIST_EMISSION_SUBMITTED]: 'listSubmitIds',
        [ROUTES.LIST_APPROVAL]: 'listApprovalIds',
        [ROUTES.LIST_EMISSION_TEMPLATES]: 'listTemplateIds',
        [ROUTES.PRIMARY_DATA_MERGE_DETAIL]: 'listPrimaryDataIds'
      };

      const action = scopeActionMap[this.$route.params?.scope] || 'listAllIds';
      // Call API to get list default
      const payload = {
        action: action
      };
      const ids = await getListWorkflowApi(payload);
      this.dataList = ids?.data ?? [];
    }
    this.currentIdWorkflow = Number(this.$route.params.category);
  },
  watch: {
    currentIdWorkflow(value) {
      const dataLength = this.dataList.length;

      // When there's 0 or 1 item in the list, both buttons are disabled
      if (dataLength <= 1) {
        this.isDisablePrevious = true;
        this.isDisableNext = true;
        return;
      }

      // When the current value is the first or last item in the list
      const isFirstItem = this.dataList[0] === value;
      const isLastItem = this.dataList[dataLength - 1] === value;

      this.isDisablePrevious = isFirstItem;
      this.isDisableNext = isLastItem;
    },

    isUpdateCurrrentId(value) {
      if (value) {
        this.currentIdWorkflow = this.moveIdWorkflow;
      }
    }
  },
  methods: {
    handleClickBtnDataMoving(action) {
      this.currentIdWorkflow = Number(this.$route.params.category);
      if (action) {
        this.moveIdWorkflow = this.dataList[this.dataList.indexOf(this.currentIdWorkflow) + 1] ?? null;
      } else {
        this.moveIdWorkflow = this.dataList[this.dataList.indexOf(this.currentIdWorkflow) - 1] ?? null;
      }
      const { category, ...otherParams } = this.$route.params;
      this.$router.push({
        path: this.$route.path.replace(category, this.moveIdWorkflow),
        params: { ...otherParams, category: this.moveIdWorkflow }
      });
    }
  }
};
</script>

<style lang="scss">
.data-transfer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;

  &__label {
    color: $monoBlack;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.33px;
  }

  &__buttons {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }

  &__button {
    display: flex;
    height: 28px;
    padding: 7px 6px 9px 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px 0px rgba(160, 181, 186, 0.07), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11), 0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17), 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24);
    overflow: hidden;
    color: $monoBlack;
    text-overflow: ellipsis;

    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.33px;

    &:hover {
      opacity: 1;
      background-color: $goldLight;
      box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48),
        0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31),
        0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24),
        0px 4px 14px 0px rgba(160, 181, 186, 0.17);

      color: $monoWhite;

      img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(36deg) brightness(99%) contrast(108%);
      }
    }

    &.disabled {
      background-color: $monoLight;
      color: $monoMid;
      pointer-events: none;
      cursor: default;

      img {
        filter: brightness(0) saturate(100%) invert(65%) sepia(9%) saturate(306%) hue-rotate(144deg) brightness(100%) contrast(88%);
      }

    }

  }
}

@include desktop {
  .data-transfer {
    padding: 16px 40px !important;
  }
}
</style>
