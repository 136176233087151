<template>
  <div class="product-dialog-popup view-emission-container padding-top-layout">
    <page-title :title="$t('left_menu.button_list_emission')" />
    <div class="content-dialog-popup" @click="focusButton = false">
      <div class="content view-emission-content">
        <!-- TODO: update UI layer -->
        <div class="content-search">
          <div class="content-search-filter select-emission-register">
            <div class="search-item duration">
              <div class="search-item-label">{{ $t('b_register_product.label_period') }}</div>
              <div class="search-item-detail">
                <div class="search-item-date">
                  <v-select
                    solo
                    flat
                    :items="yearList.startYear"
                    v-model="periodData.startYear"
                    item-text="text"
                    item-value="value"
                    :placeholder="$t('calculation_term.placeholder_unselect')"
                    no-data-text="''"
                    class="select-type list-menu--select select-item content-item-block-select"
                    :menu-props="{ contentClass: 'select-menu product-select pulldown-year', closeOnContentClick: true }"
                    ref="yearFrom"
                    @change="handleChangeMonthYear('startAt')"
                  >
                    <template slot="item" slot-scope="data">
                      {{ data.item.text }}
                      <v-spacer></v-spacer>
                      <span v-if="data.item.hasData" class="data-is-registered"></span>
                    </template>
                  </v-select>
                  <v-select
                    solo
                    flat
                    :items="startMonthList"
                    v-model="periodData.startMonth"
                    :placeholder="$t('calculation_term.placeholder_unselect')"
                    item-text="text"
                    item-value="value"
                    no-data-text="''"
                    class="select-type list-menu--select select-item content-item-block-select"
                    :menu-props="{ contentClass: 'select-menu product-select pulldown-year', closeOnContentClick: true }"
                    ref="monthFrom"
                    @change="handleChangeMonthYear('startAt')"
                  >
                  <template slot="item" slot-scope="data">
                      {{ data.item.text }}
                      <v-spacer></v-spacer>
                      <span v-if="data.item.hasData" class="data-is-registered"></span>
                    </template>
                  </v-select>
                </div>
                <div class="search-item-underline">ー</div>
                <div class="search-item-date">
                  <v-select
                    solo
                    flat
                    :items="yearList.endYear"
                    v-model="periodData.endYear"
                    :placeholder="$t('calculation_term.placeholder_unselect')"
                    no-data-text="''"
                    item-text="text"
                    item-value="value"
                    class="select-type list-menu--select select-item content-item-block-select"
                    :menu-props="{ contentClass: 'select-menu product-select pulldown-year', closeOnContentClick: true }"
                    ref="yearTo"
                    @change="handleChangeMonthYear('endAt')"
                  >
                    <template slot="item" slot-scope="data">
                      {{ data.item.text }}
                      <v-spacer></v-spacer>
                      <span v-if="data.item.hasData" class="data-is-registered"></span>
                    </template>
                  </v-select>

                  <v-select
                    solo
                    flat
                    :items="endMonthList"
                    v-model="periodData.endMonth"
                    :placeholder="$t('calculation_term.placeholder_unselect')"
                    no-data-text="''"
                    item-text="text"
                    item-value="value"
                    class="select-type list-menu--select select-item content-item-block-select"
                    :menu-props="{ contentClass: 'select-menu product-select pulldown-year', closeOnContentClick: true }"
                    ref="fromTo"
                    @change="handleChangeMonthYear('endAt')"
                  >
                    <template slot="item" slot-scope="data">
                      {{ data.item.text }}
                      <v-spacer></v-spacer>
                      <span v-if="data.item.hasData" class="data-is-registered"></span>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>

            <div class="search-item">
              <div class="search-item-label">{{ $t('b_register_product.label_scope_category') }}</div>
               <v-select
                solo
                flat
                :items="scopeCategoriesList"
                v-model="scopeCategoriesModel"
                :label="$t('b_register_product.pulldown_select_select_approver')"
                class="select-type list-menu--select select-item content-item-block-select search-item-select select-scope-cat-pulldown"
                :menu-props="{ contentClass: 'select-menu product-select pulldown-year', closeOnContentClick: true }"
                ref="scope"
                item-text="text"
                item-value="value"
                @change="handleChangeSelect"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.text }}
                  <v-spacer></v-spacer>
                  <span v-if="data.item.hasData" class="data-is-registered ml-4"></span>
                </template>
              </v-select>
              <common-button
                class="listMenu-scope"
                :class="categorySelected && categorySelected.scope && 'has-scope'"
                @action="openScopePopup"
                :label="$t('view_emission_update.placeholder_select_scope_category')"
                v-model="categorySelected"
                id="scope_select"
                v-ripple="false"
              />
            </div>

            <div class="search-item">
              <div class="label d-flex search-item-label align-center">
                {{ $t('view_emission_update.label_title') }}
              </div>
              <InputField
                class="search-item-input"
                :isClearData="false"
                :inputValue="search"
                @update:inputValue="handleUpdateSearch"
              />
              <div class="d-flex align-center label">{{ $t('view_emission_update.title_calculation_method') }}</div>
              <common-button
                class="listMenu-scope select-method-popup"
                :class="!!currentSelectedMethod ? 'has-scope' : 'no-scope'"
                @action="radioDialog = true"
                :disabled="methodList.length === 0"
                :label="
                  methodList.length === 0 || !methodSelectedInPopup
                    ? $t('view_emission_update.placeholder_formulation_method_name')
                    : getLabelMethodName()
                "
                v-ripple="false"
                id="select-method"
              />
            </div>
            <div
              v-if="planType.is_pcaf && categorySelected.cateId === 15"
              class="search-item duration select-data-type"
            >
              <div class="search-item-label"></div>
              <!-- pulldown category type -->
              <v-select
                solo
                flat
                :disabled="!itemSelectPCAF.listMethodType"
                :items="itemSelectPCAF.listMethodType"
                v-model="itemSelectPCAF.categoryIdSelect"
                item-text="name"
                item-value="id"
                class="select-type select-item list-menu--select select-method select-category"
                :class="{ disabled: currentSelectedMethod > 2 }"
                :menu-props="{ contentClass: 'select-menu product-select pulldown-year' }"
                id="select-category"
                :label="$t('pcaf_update.placeholder_category_type_pcaf')"
                @change="handleChangeTypePCaf"
              >
                <template slot="item" slot-scope="data">
                    {{ data.item.name }}
                    <v-spacer></v-spacer>
                    <span v-if="data.item.hasData" class="data-is-registered"></span>
                  </template>
                </v-select>
              <!-- pulldown data type -->
              <v-select
                :disabled="!itemSelectPCAF.listDataType"
                solo
                flat
                :items="itemSelectPCAF.listDataType"
                v-model="itemSelectPCAF.dataTypeIdSelect"
                item-text="name"
                item-value="id"
                :label="$t('pcaf_update.placeholder_data_type_pcaf')"
                :class="{ disabled: !itemSelectPCAF.listDataType }"
                class="select-type select-item list-menu--select select-method"
                :menu-props="{ contentClass: 'select-menu export-select' }"
                id="select-data-type"
                @change="handleChangeTypePCaf"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.name }}
                  <v-spacer></v-spacer>
                  <span v-if="data.item.hasData" class="data-is-registered"></span>
                </template>
              </v-select>
            </div>
          </div>
          <CommonButton
            class="content-search-btn"
            :label="$t('b_register_product.button_search')"
            type="colored"
            @action="handleSearchEmissions"
          />
        </div>
        <div class="period-description">
          <span class="title1">{{ $t('view_emission_update.title_period') }}</span>
          <span class="duration-date">{{ currentDuration }}</span>
          <span class="total">{{ totalEmission }} <span class="unit">t-CO2</span></span>
        </div>
       
      </div>
    </div>
    <radio-popup
      :scope="categorySelected.scope"
      :category="categorySelected.cateId"
      :dialog="radioDialog"
      :method="methodSelectedInPopup"
      :items="methodList"
      :currentSelect.sync="methodSelectedInPopup"
      :listMethodsAtive="listMethodsActive"
      @close="closePopup"
      @onChange="submitRadioPopup"
    />
    <radio-popup
      :scope="categorySelected.scope"
      :category="categorySelected.cateId"
      :dialog="radioDialogLayer2"
      :items="methodListLayer2"
      :method="methodLayer2SelectedInPopup"
      :currentSelect.sync="methodLayer2SelectedInPopup"
      :isLayer2="true"
      :listMethodsAtive="listMethodsActive"
      @close="closePopupLayer"
      @onChange="submitRadioLayerPopup"
    />
    <scope-category-popup
      v-if="isOpenScopeDialog"
      :dialog="isOpenScopeDialog"
      :scopeCategoriesList="scopeCategoriesList"
      @close="isOpenScopeDialog = false"
      @selectedScope="selectedScope"
    />
    <error-popup
      :dialog="errorDialog"
      :message="errorMessage"
      @submit="errorDialog = false"
    />
  </div>
</template>

<script>
import PageTitle from '@/components/pageTitle/pageTitle.vue';
import InputField from '@/components/products/input/InputField.vue';
import CommonButton from '@/components/utils/button.vue';
import DataTable from '@/components/category/data-table';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import { scopeCategoriesData, oldmethod, pcafMethod, newPcafMethod } from '@/constants/export-report';
import { getMonthListCustome, calcYearFromStartMonthCustome, findRowNumPattern, getFirstMonthByOrder, calculateEndDate } from '@/utils/registerData';
import ScopeCategoryPopup from '@/components/dialogs/scope-category-popup';
import methodMixin from '@/views/linkToPrimaryData/formInputPrimary/method-mixin.js';
import RadioPopup from '@/components/dialogs/radio-popup';
import { getDataTypeApi } from '@/api/pcaf';
import { PCAF_CATEGORY_TYPE_METHOD_1, PCAF_CATEGORY_TYPE_METHOD_2 } from '@/constants/pcaf';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import { PERIOD_DASHBOARD } from "@/constants/dashboard";
import { getExistApprovalData, getExistApproveScopeCategory, getExistApproveByMethod } from '@/api/newRegisterData'
import { DEFAULT_YEAR } from '@/constants/registerData';
export default {
  name: 'ViewEmission',
  components: { InputField, CommonButton, DataTable, PageTitle, ScopeCategoryPopup, RadioPopup, ErrorPopup },
  mixins: [methodMixin],
  props: {
      startMonth: {
        default: 4,
        type: Number
      },
      totalEmission: {
        default: null,
        type: Number | String
      }
  },
  data() {
    return {
      width: '',
      title: this.$t('b_register_product.title_select_registered_emissions'),
      value: 'acd',
      search: null,
      flex: null,
      listEmissionData: [],
      gridColumns: [],
      listData: null,
      pageSize: 20,
      total: 0,
      currentPage: 1,
      listPage: [],
      showTooltip: false,
      yearList: {
        startYear: [],
        endYear: [],
      },
      listLayer: [],
      monthList: [],
      periodData: {
        startYear: null,
        endYear: null,
        startMonth: null,
        endMonth: null,
      },
      beginYear: DEFAULT_YEAR.MIN_YEAR,
      beginMonth: 1,
      endMonth: null,
      endYear: null,
      scopeCategoriesList: [],
      isSearchEmissions: false,
      focusButton: false,
      endMonthList: [],
      startMonthList: [],
      isOpenScopeDialog: false,
      radioDialog: false,
      categorySelected: {},
      totalEmissions: null,
      currentDuration: null,
      itemSelectPCAF: {
        categoryIdSelect: null,
        dataTypeIdSelect: null,
        listMethodType: null,
        listDataType: null,
      },
      titlePattern: null,
      row_num_pattern: null,
      errorMessage: '',
      errorDialog: false,
      scopeCategoriesModel: null,
      dataYearHasDataEmission: {},
      listMethodsActive: [],
      existApprovedData: {}
    };
  },
  async mounted() {
    this.getWidth();
    window.addEventListener('resize', this.getWidth);
    this.prepareMonthYearPulldown();
    this.getCategoryList();
    // default selected
    this.categorySelected = {
      scope: Number(this.$route.params?.scope),
      cateId: Number(this.$route.params?.category),
    };
    await this.selectedScope(this.categorySelected);
    this.methodSelectedInPopup = 1; // default selected method
    this.getTitleScopeCate();
    this.scopeCategoriesModel = {
      scope: Number(this.$route.params?.scope),
      category: Number(this.$route.params?.category),
    }
    this.search = null;

    if (this.$route.query?.year) {
      this.beginYear = Number(this.$route.query?.year);
    }
    else {
      this.beginYear = DEFAULT_YEAR.MIN_YEAR;
    }
    if (this.$route.query?.month) {
      this.beginMonth = Number(this.$route.query?.month);
    } else {
      this.beginMonth = this.startMonth;
    }
    if (this.$route.query?.order) {
      const isHalfYear = this.$route.query?.period === PERIOD_DASHBOARD.PERIOD_HALF_YEAR;
      this.beginMonth = Number(getFirstMonthByOrder(moment, this.startMonth, this.$route.query?.order, isHalfYear));
    }

    let currentYear = DEFAULT_YEAR.MAX_YEAR;
    const currentMonth = new Date().getMonth() + 1;
    if (this.startMonth > currentMonth) {
      currentYear = currentYear - 1;
    }
    const lastDate = moment(`${currentYear}/${this.startMonth}`, 'YYYY/M').add(11, 'months');
    if (this.$route.query?.period) {
      const date = new Date(this.beginYear, this.beginMonth - 1);
      [this.endMonth, this.endYear] = calculateEndDate(date, this.$route.query?.period)
    }
  
    this.periodData = {
      startMonth: this.beginMonth,
      startYear: this.beginYear,
      endMonth: this.$route.query?.period ? this.endMonth : +lastDate.format('M'),
      endYear: this.$route.query?.period ?  this.endYear : +lastDate.format('YYYY'),
    };
    const payload = {
      start_at: [
        moment(this.periodData.startYear, 'YYYY').format('YYYY'),
        moment(this.periodData.startMonth, 'MM').format('MM'),
      ].join('-'),
      end_at: [
        moment(this.periodData.endYear, 'YYYY').format('YYYY'),
        moment(this.periodData.endMonth, 'MM').format('MM'),
      ].join('-'),
      row_num_pattern: this.row_num_pattern,
      title: this.search || '',
    };
    if (this.planType.is_pcaf && this.categorySelected.cateId === 15) {
      if (this.itemSelectPCAF.categoryIdSelect) {
        payload.type_classify = this.itemSelectPCAF.categoryIdSelect;
      }
      if (this.itemSelectPCAF.dataTypeIdSelect) {
        payload.type_data = this.itemSelectPCAF.dataTypeIdSelect;
      }
    }
    Promise.all([
      this.onHandleSearchEmission(payload),
      this.getExistApprovedData(),
      this.getExistApprovedDataOfScopeCategory(),
      this.getExistApprovedDataByMethod()
    ]).catch(() => {});
  },
  methods: {
    ...mapActions('commonApp', ['actionUpdateIsLoadingTable']),
    openScopePopup() {
      this.isOpenScopeDialog = true;
    },
    async getExistData() {
      try {
        Promise.all([
          this.getExistApprovedDataOfScopeCategory(),
          this.getExistApprovedData(),
          this.getExistApprovedDataByMethod(),
        ]).catch(() => {});
      } catch (error) {
        console.warn(error);
      }
    },
    async selectedScope(scope) {
      let scopeAndCategory = { cateId: +scope.cateId, scope: +scope.scope };
      this.isOpenScopeDialog = false;
      // When change scope category is default method 1
      this.currentSelectedMethod = 1;
      this.methodSelectedInPopup = 1;
      this.categorySelected = scopeAndCategory;
      this.getMethodList(scopeAndCategory.scope, scopeAndCategory.cateId);
      this.getMethodListLayer2(this.categorySelected.scope, this.categorySelected.cateId);
      if (this.methodListLayer2.length > 0) {
        this.currentSelectedLayer2 = this.methodList.length + 1;
        this.methodLayer2SelectedInPopup = this.methodList.length + 1;
        if (this.categorySelected.scope === 3 && [9,11].includes(this.categorySelected.cateId) ) {
          this.currentSelectedLayer2 = 1;
          this.methodLayer2SelectedInPopup = 1;
        }
      }
      else {
        this.currentSelectedLayer2 = null;
        this.methodLayer2SelectedInPopup = null;
      }
      this.getTitleScopeCate()
      if (this.categorySelected.scope === 3 && this.categorySelected.cateId === 15) {
        await this.getTypeDataPCAF();
        const getFirstItemId = (items) => {
          return items.length ? items[0].id : null;
        }
        this.itemSelectPCAF.categoryIdSelect = getFirstItemId(this.itemSelectPCAF.listMethodType);
        this.itemSelectPCAF.dataTypeIdSelect = getFirstItemId(this.itemSelectPCAF.listDataType);
      }
      this.scopeCategoriesModel = {
        scope: scopeAndCategory.scope,
        category: scopeAndCategory.cateId,
      }

      this.getExistData() //false to dont call api get by month
    },
    openSelectPopup() {
      this.radioDialog = true;
    },
    async submitRadioPopup() {
      this.tempCurrentSelectedMethod = this.currentSelectedMethod;
      this.currentSelectedMethod = this.methodSelectedInPopup;
      this.getMethodListLayer2(this.categorySelected.scope, this.categorySelected.cateId);
      this.radioDialog = false;
      if (this.methodListLayer2.length > 0) {
        this.radioDialogLayer2 = true;
      } else {
        this.currentSelectedLayer2 = null;
        this.methodLayer2SelectedInPopup = null;
        this.getTitleScopeCate();
        if (this.categorySelected.scope === 3 && this.categorySelected.cateId === 15) {
          await this.getTypeDataPCAF();
          const getFirstItemId = (items) => {
            return items?.length ? items[0].id : null;
          }
          this.itemSelectPCAF.categoryIdSelect = getFirstItemId(this.itemSelectPCAF.listMethodType);
          this.itemSelectPCAF.dataTypeIdSelect = getFirstItemId(this.itemSelectPCAF.listDataType);
        }
      }
      this.getExistData()
    },
    async getTypeDataPCAF() {
        this.itemSelectPCAF.dataTypeIdSelect = null;
        this.itemSelectPCAF.categoryIdSelect = null;
        this.getMethodType(this.methodSelectedInPopup);
        await this.getDataTypeByMethod(this.methodSelectedInPopup + 4);
    },
    async getDataTypeByMethod(method) {
      await getDataTypeApi(method).then((res) => {
        this.itemSelectPCAF.listDataType = res.data;
      });
    },
    getMethodType(method) {
      if (method === 1) {
        this.itemSelectPCAF.listMethodType = PCAF_CATEGORY_TYPE_METHOD_1;
      } else if (method === 2) {
        this.itemSelectPCAF.listMethodType = PCAF_CATEGORY_TYPE_METHOD_2;
      } else {
        this.itemSelectPCAF.listMethodType = null;
      }
    },

    submitRadioLayerPopup() {
      this.currentSelectedLayer2 = this.methodLayer2SelectedInPopup;
      this.radioDialogLayer2 = false;
      this.getTitleScopeCate();
      this.getExistData()
    },
    closePopup() {
      this.$nextTick((_) => (this.methodSelectedInPopup = this.currentSelectedMethod));
      this.radioDialog = false;
    },
    closePopupLayer() {
      if (!this.currentSelectedLayer2) {
        this.currentSelectedMethod = null;
        this.currentSelectedLayer2 = null;
      }
      if (this.tempCurrentSelectedMethod !== this.currentSelectedMethod) {
        this.currentSelectedMethod = this.tempCurrentSelectedMethod;
        this.getMethodListLayer2(this.categorySelected.scope, this.categorySelected.cateId);
      }

      this.$nextTick((_) => {
        this.methodSelectedInPopup = this.currentSelectedMethod;
        this.methodLayer2SelectedInPopup = this.currentSelectedLayer2;
      });
      this.radioDialogLayer2 = false;
    },
    getWidth() {
      if (window.innerWidth > 1024) {
        this.width = this.isExpand ? 'calc(100% - (236px + 56px))' : 'calc(100% - (72px + 56px))';
        return;
      }
      this.width = '100%';
    },
    handleUpdateSearch(newVal) {
      this.search = newVal;
    },

    getMonth(month) {
      return Number(month) < 10 ? `0${month}` : month;
    },
    async onHandleSearchEmission(payload) {
      const targetPath = `/emissions/view/${this.categorySelected.scope}/${this.categorySelected.cateId}`;
      if (this.$route.path !== targetPath) {
        this.$router.push({ path: targetPath });
      }

      setTimeout(() => {
        const viewEmissionData = {
        scope: this.categorySelected.scope,
        category: this.categorySelected.cateId,
        method: this.methodSelectedInPopup,
        methodLayer2: this.methodLayer2SelectedInPopup,
        type_classify: this.itemSelectPCAF.categoryIdSelect,
        type_data: this.itemSelectPCAF.dataTypeIdSelect,
        itemsDataType: this.itemSelectPCAF.listDataType,
        itemsCategoryType: this.itemSelectPCAF.listMethodType,
        payload: payload
      }
      const startMonthYear = this.$d(
        new Date(`${this.periodData.startYear}-${this.getMonth(this.periodData.startMonth)}`),
        'short',
      );
      const endMonthYear = this.$d(
        new Date(`${this.periodData.endYear}-${this.getMonth(this.periodData.endMonth)}`),
        'short',
      );
      this.currentDuration = `${startMonthYear} － ${endMonthYear}`;
      viewEmissionData.currentDuration = this.currentDuration;
      this.$emit('onHandleSearchEmission', viewEmissionData);
      this.$emit('updatePeriodData', this.periodData)
      }, 300)
    },
    prepareMonthYearPulldown() {
      this.yearList.endYear = calcYearFromStartMonthCustome(this.startMonth, 2017);
      this.yearList.startYear = calcYearFromStartMonthCustome(this.startMonth, 2017);
      this.startMonthList = getMonthListCustome(this.yearList.endYear[0].value, this.startMonth);
      this.endMonthList = getMonthListCustome(
        this.yearList.endYear[this.yearList.endYear.length - 1].value,
        this.startMonth,
      );
      
      // set default select
      this.periodData = {
        startMonth: this.startMonthList[0].text,
        startYear: this.yearList.startYear[0].text,
        endMonth: this.endMonthList[this.endMonthList.length - 1].text,
        endYear: this.yearList.startYear[this.yearList.startYear.length - 1].text,
      };
    },
    handleSearchEmissions() {
      if (this.isSearchEmissions) return;
      const payload = {
        start_at: [
          moment(this.periodData.startYear, 'YYYY').format('YYYY'),
          moment(this.periodData.startMonth, 'MM').format('MM'),
        ].join('-'),
        end_at: [
          moment(this.periodData.endYear, 'YYYY').format('YYYY'),
          moment(this.periodData.endMonth, 'MM').format('MM'),
        ].join('-'),
        row_num_pattern: this.row_num_pattern,
        title: this.search,
      };
      if (!this.search) {
        delete payload.title;
      }

      if (this.planType.is_pcaf && this.categorySelected.cateId === 15) {
        if (this.itemSelectPCAF.categoryIdSelect) {
          payload.type_classify = this.itemSelectPCAF.categoryIdSelect;
        }
        if (this.itemSelectPCAF.dataTypeIdSelect) {
          payload.type_data = this.itemSelectPCAF.dataTypeIdSelect;
        }
      }

      // this.$emit('updatePeriodData', this.periodData)
      this.onHandleSearchEmission(payload);
    },
    getTitleScopeCate() {
      let scopeCategoriesDataPattern = scopeCategoriesData;
      const indexGhg = scopeCategoriesDataPattern.findIndex((item) => item.categories === 15);
      scopeCategoriesDataPattern[indexGhg].methods = this.planType?.is_pcaf ? newPcafMethod : oldmethod;
      const row_num_pattern = findRowNumPattern({
        scopeCategoriesData: scopeCategoriesDataPattern,
        scope: this.categorySelected.scope,
        category: this.categorySelected.cateId,
        method: this.methodSelectedInPopup,
        methodLayer2: this.methodLayer2SelectedInPopup,
      });
      this.row_num_pattern = row_num_pattern;
      this.titlePattern = scopeCategoriesDataPattern.find(item => item.categories === this.categorySelected.cateId && this.categorySelected.scope === item.scope)?.text
    },
    async handleChangeTypePCaf() {
      this.getTitleScopeCate();
      try {
        Promise.all([
          this.getExistApprovedData(),
          this.getExistApprovedDataByMethod(),
        ]).catch(() => {});
      } catch (error) {
        console.warn(error);
      }
    },
    checkDuration(isStart = '') {
      this.startMonthList = getMonthListCustome(this.periodData.startYear, this.startMonth)
      const indexMonthStart =  this.startMonthList.findIndex(item => item.value === this.periodData.startMonth)
      if(indexMonthStart < 0) {
        this.periodData.startMonth = null
      }

      this.endMonthList = getMonthListCustome(this.periodData.endYear, this.startMonth)
      const indexMonthEnd =  this.endMonthList.findIndex(item => item.value === this.periodData.endMonth)
      if(indexMonthEnd < 0) {
        this.periodData.endMonth = null
      }

      this.setExistDataForStartMonthListByYearSelected()
      this.setExistDataForEndMonthListByYearSelected()
      if(this.periodData.startMonth !== null && this.periodData.startYear !== null && this.periodData.endMonth !== null && this.periodData.endYear !== null) {
        const monthStart = moment(this.periodData.startMonth, 'MM').format('MM');
        const monthEnd = moment(this.periodData.endMonth, 'MM').format('MM');
        const yearStart = moment(this.periodData.startYear, 'YYYY').format('YYYY');
        const yearEnd = moment(this.periodData.endYear, 'YYYY').format('YYYY');
        const dateStart = moment(`${monthStart} ${yearStart}`, 'MM YYYY');
        const dateEnd = moment(`${monthEnd} ${yearEnd}`, 'MM YYYY');

        if (dateStart.isAfter(dateEnd)) {
          this.errorMessage = isStart === 'startAt' ? this.$t('popup.popup_month_before_end_date') : this.$t('popup.popup_month_after_start_date');
          this.errorDialog = true;
          return false;
        }
        return true;
      }
      return false;
    },
    async handleChangeMonthYear(text) {
      try {
        Promise.all([
          this.getExistApprovedDataOfScopeCategory(),
          this.getExistApprovedDataByMethod(),
          this.checkDuration(text)
        ]).catch(() => {});
      } catch (error) {
        console.warn(error);
      }
    },
    getCategoryList() {
      this.scopeCategoriesList = [];
      let scopeCategoriesDataPattern = scopeCategoriesData;
      const indexGhg = scopeCategoriesDataPattern.findIndex((item) => item.categories === 15);
      scopeCategoriesDataPattern[indexGhg].methods = this.planType?.is_pcaf ? newPcafMethod : oldmethod;
      scopeCategoriesDataPattern.forEach(
        (item) =>
          item.text !== this.$t('b_export_report.checkbox_select_all') &&
          this.scopeCategoriesList.push({ value: { scope: item.scope, category: item.categories }, ...item }),
      );
      // Filter out scope 3 if not enabled
      if (!this.isEnableScope3) {
        this.scopeCategoriesList = this.scopeCategoriesList.filter(item => item.scope !== 3);
      }
    },
    async handleChangeSelect() {
      try {
        this.categorySelected = {
          scope: this.scopeCategoriesModel.scope,
          cateId: this.scopeCategoriesModel.category,
        };
        Promise.all([
          this.selectedScope(this.categorySelected),
        ]).catch(() => {});
      } catch (error) {
        console.warn(error);
      }
    },
    async getExistApprovedDataOfScopeCategory() {
      try {
        if(!Number(this.periodData.startYear) || !Number(this.periodData.endYear)) return
        const payload = {
          from: [
            moment(this.periodData.startYear, 'YYYY').format('YYYY'),
            moment(this.periodData.startMonth, 'MM').format('MM'),
          ].join('-'),
          to: [
            moment(this.periodData.endYear, 'YYYY').format('YYYY'),
            moment(this.periodData.endMonth, 'MM').format('MM'),
          ].join('-')
        };
        const responseData = await getExistApproveScopeCategory(payload)
        this.existApprovedData = responseData
        this.setExistDataForCategory(responseData)
      } catch (error) {
        console.warn(error);
      }
    },
    async getExistApprovedDataByMethod() {
      try {
        const startYear = Number(this.periodData.startYear)
        const endYear = Number(this.periodData.endYear)
        const isStartYearSmallerEndYear = startYear > endYear
        if(!startYear || !endYear || isStartYearSmallerEndYear) return

        const payload = {
          from: [
            moment(this.periodData.startYear, 'YYYY').format('YYYY'),
            moment(this.periodData.startMonth, 'MM').format('MM'),
          ].join('-'),
          to: [
            moment(this.periodData.endYear, 'YYYY').format('YYYY'),
            moment(this.periodData.endMonth, 'MM').format('MM'),
          ].join('-'),
          scope: this.scopeCategoriesModel.scope,
          category: this.scopeCategoriesModel.category,
        };
        const responseData = await getExistApproveByMethod(payload)
        this.getListMethodHasApproveData(responseData)
      } catch (error) {
        console.warn(error);
      }
    },
    getMethodActiveByPatternId(category, scope, patternId) {
      if (category === 1 && scope === 1) {
        if (patternId === 1) {
          return 2;
        }
        if (patternId === 2) {
          return 4;
        }
        if (patternId === 3) {
          return 3;
        }
        return 1;
      } else if (scope === 3) {
        if (category === 4) {
          if (patternId === 5) {
            return 6;
          } else if (patternId === 6) {
            return 5;
          }
        }
        if (category === 6) {
          if (patternId === 2) {
            return 1;
          } else if (patternId === 3) {
            return 2;
          } else if (patternId === 1) {
            return 3;
          }
        }
        if (category === 7) {
          if (patternId === 2) {
            return 1;
          } else if (patternId === 3) {
            return 2;
          } else if (patternId === 1) {
            return 3;
          }
        }
        if (category === 9) {
          if (patternId === 2) {
            return 3;
          } else if (patternId === 3) {
            return 2;
          }
        }
        if (category === 15) {
          return this.planType?.is_pcaf ? patternId - 4 : patternId
        }
      }

      return patternId
    },
    getListMethodHasApproveData(dataProps) {
      let listMethodsActive = []
      for(const exitData in dataProps) {
        if(dataProps[exitData]) {
          let existData = parseInt(exitData)
          const dataId = this.getMethodActiveByPatternId(this.categorySelected.cateId, this.categorySelected.scope, existData)
          if (this.categorySelected.scope === 3 && this.categorySelected.cateId === 15 && this.planType?.is_pcaf) {
            if ([5, 6].includes(existData)) {
              let categoryExistData = {
                0: false,
                1: false,
              };

              let methodExistData = {
                0: false,
                1: false,
              }

              Object.values(dataProps[exitData]).forEach((categoryType, categoryTypeIndex) => {
                Object.keys(categoryType).forEach((dataType) => {
                  if (categoryType[dataType]) {
                    methodExistData[categoryTypeIndex] = true;
                  }
                })
              })
              if(this.currentSelectedMethod + 4 === existData) {
                Object.values(dataProps[exitData]).forEach((categoryType, categoryTypeIndex) => {
                  Object.keys(categoryType).forEach((dataType, dataTypeIndex) => {
                    if (this.itemSelectPCAF.categoryIdSelect === (categoryTypeIndex + 1) && [1, 2].includes(this.currentSelectedMethod)) {
                      this.itemSelectPCAF.listDataType[dataTypeIndex].hasData = categoryType[dataType];
                    }
                    if (categoryType[dataType]) {
                      categoryExistData[categoryTypeIndex] = true;
                    }
                  })
                  if ([1, 2].includes(this.currentSelectedMethod)) {
                    this.itemSelectPCAF.listMethodType[categoryTypeIndex].hasData = categoryExistData[categoryTypeIndex];
                  }
                })
              }
              if (Object.values(methodExistData).some(exist => exist === true)) {
                listMethodsActive.push(dataId);
              }
            } else {
              if (this.currentSelectedMethod === (exitData - 4)) {
                let methodExistData = false;
                Object.values(dataProps[exitData]).forEach((dataType, dataTypeIndex) => {
                  this.itemSelectPCAF.listDataType[dataTypeIndex].hasData = dataType;
                  if (dataType) {
                    methodExistData = true;
                  }
                })
                if (methodExistData) {
                  listMethodsActive.push(dataId);
                }
              } else {
                if (Object.values(dataProps[exitData]).some(itemsDataType => itemsDataType === true)) {
                  listMethodsActive.push(dataId);
                }
              }
            }
          } else {
            listMethodsActive.push(dataId);
          }
        }
      }
      this.listMethodsActive = listMethodsActive
    },
    setExistDataForCategory() {
      this.scopeCategoriesList.forEach(scopeItem => {
        scopeItem.hasData = !!this.existApprovedData[scopeItem.scope]?.includes(scopeItem?.categories?.toString()) // set hasData data for category item
      })
    },
    async getExistApprovedData() {
      try {
        const payload = {
          scope: this.categorySelected?.scope,
          category: this.categorySelected?.cateId,
          method: this.setPatternId(this.categorySelected?.cateId, this.categorySelected?.scope, this.methodSelectedInPopup, this.methodLayer2SelectedInPopup)
        }

        if (this.planType.is_pcaf && this.categorySelected.cateId === 15) {
          if (this.itemSelectPCAF.categoryIdSelect) {
            payload.type_classify = this.itemSelectPCAF.categoryIdSelect;
          }
          if (this.itemSelectPCAF.dataTypeIdSelect) {
            payload.type_data = this.itemSelectPCAF.dataTypeIdSelect;
          }
        }

        const responseData = await getExistApprovalData(payload)
        this.dataYearHasDataEmission = responseData
        this.setExistData()
      } catch (error) {
        console.warn(error);
      }
    },
    setPatternId(category, scope, method, methodLayer2) {
      if (category === 1 && scope === 1) {
        if (method === 2) {
          return 1;
        } else if (method === 3) {
          return 3;
        } else if (method === 4) {
          return 2;
        } else if (method === 1) {
          return 4;
        }
      } else if (scope === 3) {
        if (category === 4) {
          if (method === 1) {
            if (methodLayer2 === 5) {
              return 6;
            } else if (methodLayer2 === 6) {
              return 5;
            }
          }
          if (method === 3) {
            return 2
          }
        }
        if (category === 6) {
          if (method === 1) {
            return 2;
          } else if (method === 2) {
            return 3;
          } else if (method === 3) {
            return 1;
          }
        }
        if (category === 7) {
          if (method === 1) {
            return 2;
          } else if (method === 2) {
            return 3;
          } else if (method === 3) {
            return 1;
          }
        }
        if (category === 9) {
          if (method === 1) {
            if (methodLayer2 === 2) {
              return 3;
            } else if (methodLayer2 === 3) {
              return 2;
            }
          }
        }
        if(category === 11) {
          if (method === 2) {
            return 4
          }
        }
        if(category === 15) {
          return this.planType.is_pcaf ? method + 4 : method
        }
      }
      let patternId = null;
      patternId = methodLayer2 || method

      return patternId;
    },
    setExistData() {
      this.yearList.startYear?.forEach(yearItem => {
        yearItem.hasData = this.dataYearHasDataEmission[yearItem?.value] ? true : false
      })

      this.yearList.endYear?.forEach(yearItem => {
        yearItem.hasData = this.dataYearHasDataEmission[yearItem?.value] ? true : false
      })

      this.setExistDataForStartMonthListByYearSelected()
      this.setExistDataForEndMonthListByYearSelected()
    },
    setExistDataForStartMonthListByYearSelected() {
      const dataByYearSelected = this.dataYearHasDataEmission[this.periodData?.startYear]
      this.startMonthList.forEach(month => {
        month.hasData = !!dataByYearSelected?.includes(month.value)
      })
    },
    setExistDataForEndMonthListByYearSelected() {
      const dataByYearSelected = this.dataYearHasDataEmission[this.periodData?.endYear]
      this.endMonthList.forEach(month => {
        month.hasData = !!dataByYearSelected?.includes(month.value)
      })
    }
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState('userData', ['isSettingPcaf', 'planType']),
    ...mapState('dashboard', ['isEnableScope3']),
  },
  watch: {
    'periodData.startYear': {
      handler(newVal) {
        const startYear = this.yearList.startYear.find((item) => item.value === +newVal)?.value;
        this.startMonthList = getMonthListCustome(startYear, this.startMonth);
        this.setExistDataForStartMonthListByYearSelected()
      },
    },
    'periodData.endYear': {
      handler(newVal) {
        const startYear = this.yearList.endYear.find((item) => item.value === +newVal)?.value;
        this.endMonthList = getMonthListCustome(startYear, this.startMonth);
        this.setExistDataForEndMonthListByYearSelected()
      },
    },
    isEnableScope3: {
      handler() {
        this.getCategoryList();
        this.setExistDataForCategory()
      },
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWidth);
  },
};
</script>

<style lang="scss" scoped>
.view-emission-container {
  height: unset !important;
  max-height: unset !important;
  margin-bottom: 24px !important;
}
.product-dialog-popup {
  box-shadow: none !important;
  background: unset !important;
  @media (min-width: 1024px) {
    padding: 0 40px !important;
  }
  .custom-btn-method {
    width: 168px;
  }
  .label {
    color: $monoBlack;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.42px;
  }
  .period-description {
    display: flex;
    padding: 8px 20px;
    align-items: center;
    border-radius: 4px;
    background: $bgLight;
    width: 100%;
    flex-wrap: wrap;
    gap: 24px;
    .title1 {
      color: $monoBlack;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: 0.42px;
      width: 60px;
      flex-shrink: 0;
    }
    .duration-date {
      color: $monoBlack;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
    }
    .total {
      color: $goldMid;
      font-size: 34px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      letter-spacing: 1.02px;
      flex: 1;
      text-align: right;
      @media (max-width: 1024px) {
        text-align: left;
      }
      .unit {
        font-size: 14px;
      }
    }
  }
  .content-search {
    padding: 0 32px !important;
  }
  @media (max-width: 1024px) {
    .content-search {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: start !important;
      padding: 8px !important;
      gap: 0 !important
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1160px) {
    .content-search {
      flex-direction: column !important;
    }
    #select-method {
      max-width: 140px;
      min-width: 140px;
    }
  }
  .content-search-btn {
    width: 61px !important;
    margin-bottom: 16px;
    @media (max-width: 1024px) {
      margin-bottom: 0;
    }
  }
  .view-emission-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    @media (max-width: 1023px) {
      // 24px - content-main: 20px
      padding: 0 4px;
    }
  }
  .content-dialog-popup {
    border-top: none;
    height: unset !important;
  }
  .select-emission {
    height: 56px !important;
    min-height: 56px !important;
    @media (max-width: 1024px) {
      position: relative !important;
      bottom: 0;
      .main-action,
      .content-paging {
        display: flex;
        flex-direction: row !important;
        height: 100% !important;
        width: 100%;
        .content-paging-detail {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0px;
          gap: 16px !important;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: calc(52px + 8px);
            width: 1px;
            background-color: $monoOffWhite;
            height: 100%;
            border-radius: 4px;
            box-shadow: 0px 36px 33px 0px rgba(160, 181, 186, 0.07), 0px 13.141px 12.046px 0px rgba(160, 181, 186, 0.11), 0px 6.38px 5.848px 0px rgba(160, 181, 186, 0.13), 0px 3.127px 2.867px 0px rgba(160, 181, 186, 0.17), 0px 1.237px 1.134px 0px rgba(160, 181, 186, 0.24);
          }
        }
      
      }
      .content-item-block-select {
        display: none !important;
      }
    }
  }
  .main-action {
    width: 100% !important;
  }
  .view-emission-content {
    overflow: hidden !important;
  }
  .custom-table {
    width: 100%;
    padding: 0 1px;
  }
}
#select-method {
  min-width: 200px;
  max-width: 200px;
}
#scope_select {
  @media (max-width: 1023px) {
    width: 100%;
  }
}
.listMenu-btn-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 100%;
  height: 40px;
}
.listMenu-scope.has-scope:disabled ::v-deep .v-btn__content {
  color: $monoMid !important;
}
.select-scope-cat-pulldown {
  overflow: hidden;
}
.select-method {
  width: 100%;
}
.title-name {
  font-size: 14px;
  width: 100%;
  border-radius: 4px;
  padding: 7px 16px;
  border: 1px solid rgba(42, 42, 48, 0.1);
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background: $monoWhite;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.42px;
  color: $monoBlack;
}
.select-data-type {
  margin-top: 0px;
  @media (max-width: 1024px) {
    margin-top: -30px;
  }
}
.select-emission-register {
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 0 !important;
  @media (max-width: 1023px) { 
    padding: 16px 0 32px 0 !important;
  }
}
.search-item {
  position: relative;
  min-height: 42px;
  .label {
    min-width: fit-content;
  }
  @media (max-width: 1023px) {
    &.duration {
        .search-item-detail {
          padding: 0px 20px !important;
          .search-item-underline {
            transform: rotate(0) !important;
          }
        }
      }
  }

  .tooltip {
    position: absolute;
    width: 400px;
    background: $monoWhite;
    display: block;
    justify-content: left;
    border-radius: 4px;
    color: $monoBlack;
    margin: 0 auto;
    font-size: 15px;
    padding: 10px;
    height: unset;
    max-width: max-content;
    left: 0;
    margin-bottom: 45px;
    // left: 0px;
    bottom: 25px;
    z-index: 9999;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  }
  @media (max-width: 768px) {
    .tooltip {
      width: auto;
    }
  }
}
.view-emission-wrapper {
  margin-top: 24px;
}
.search-item {
  #scope_select {
    background: $monoWhite !important;
    &:hover {
      background: $goldLight !important;
      box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48), 0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31), 0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24), 0px 4px 14px 0px rgba(160, 181, 186, 0.17);
      .v-btn__content {
        color: $monoWhite;
      }
      path {
        color: $monoWhite;
      }
    }
  }
}
.select-category,
.select-method {
    ::v-deep .v-select__slot {
      label {
        position: relative !important;
        font-size: 14px;
      }
    }
}
@include desktop {
  .emission-selected-wrapper {
    .content-paging-detail {
      justify-content: center;
      margin-left: -6px;
    }
    ::v-deep .v-input__slot {
      width: 192.68px;
    }
    ::v-deep .v-input {
      flex: unset;
    }
    .submit-btn {
      max-width: 312.68px !important;
    }
  }
}

.common-btn {
  &#select-method {
    ::v-deep .v-btn__content {
    width: 100%;
      div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
      }
    }
  }
}
.select-type ::v-deep .v-input--is-disabled ::v-deep .v-input__slot ::v-deep .v-label {
  position: relative !important;
}

.action-dialog-popup {
  .focus-button {
    border: 2px solid $blueMid;
    overflow: hidden;
  }
}

.view-emission-content {
  ::v-deep .wrap-table.wj-flexgrid [wj-part='root'],
  ::v-deep .wrap-table {
    max-height: 728px !important;
  }
}

#scope_select {
  ::v-deep .v-btn__content {
    max-width: 237px;
    div {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>
