import i18n from '@/lang/i18n';

export const DESCRIPTIONS = {
  scope1: {
    category1: {
      title: i18n.t('register_data.title_scope_1_category_1'),
      position: i18n.t('register_data.title_company'),
      description: i18n.t('register_data.tooltip_scope_1_category_1'),
      details: {
        method1: '燃料法で算定',
        method2: 'tkm法で算定',
        method3: '燃費法で算定',
        method4: '輸送以外の活動'
      },
      patternId: {
        1: i18n.t('register_data.radio_calculated_by_fuel_method_scope1'),
        2: i18n.t('register_data.radio_calculated_by_the_tkm_method_scope1'),
        3: i18n.t('register_data.radio_calculated_by_fuel_efficiency_method_scope1'),
        4: i18n.t('register_data.radio_calculated_activities_other_than_transportation')
      }
    },
  },
  scope2: {
    category1: {
      title: i18n.t('register_data.title_scope_2_category_1'),
      position: i18n.t('register_data.title_company'),
      description: i18n.t('register_data.tooltip_scope_2_category_1'),
      details: {
        method1: '購入した電力、熱の使用に伴う間接排出',
      },
    },
  },
  scope3: {
    category1: {
      title: i18n.t('register_data.title_scope_3_category_1'),
      position: i18n.t('register_data.hyperlink_upstream'),
      description:i18n.t('register_data.tooltip_scope_3_category_1'),
      details: {
        method1: 'グループ会社外から調達した製品の上流におけるCO2排出量（OEMサービス、OEM以外も含む）',
      },
    },
    category2: {
      title: i18n.t('register_data.title_scope_3_category_2'),
      position: i18n.t('register_data.hyperlink_upstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_2'),
      details: {
        method1: '購入または取得した資本財の建設・製造及び輸送から発生する排出量',
      },
    },
    category3: {
      title: i18n.t('register_data.title_scope_3_category_3'),
      position: i18n.t('register_data.hyperlink_upstream'),
      description:i18n.t('register_data.tooltip_scope_3_category_3'),
      details: {
        method1: '購入した燃料及びエネルギーの上流側（資源採取、精算及び輸送）の排出',
      },
    },
    category4: {
      title: i18n.t('register_data.title_scope_3_category_4'),
      position: i18n.t('register_data.hyperlink_upstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_4'),
      details: {
        method1:
          '調達輸送に伴う排出量を算定。国内企業からの調達のみを行っており、該当する活動は調達に伴う国内陸上輸送のCO2排出量',
        method2: '改正省エネ法の荷主責任報告値を用いる方法',
        method3: '外部事業者に委託した物流（海外輸出における海上輸送及び国外陸上輸送に伴うCO2排出量）',
      },
      patternId: {
        4: i18n.t('register_data.method_calculated_by_fuel_method_and_shipping_fee'),
        5: i18n.t('register_data.radio_calculated_by_the_tkm_method'),
        6: i18n.t('register_data.radio_calculated_by_fuel_efficiency_method'),
        7: i18n.t('register_data.radio_fuel'),
        8: i18n.t('register_data.radio_electricity'),
        2: i18n.t('b_register_product.method_how_to_use_the_shipper_responsibility_report_value')
      }
    },
    category5: {
      title: i18n.t('register_data.title_scope_3_category_5'),
      position: i18n.t('register_data.hyperlink_upstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_5'),
      details: {
        method1:
          '自社の事業活動から発生する有価のものを除いた廃棄物に対する、自社以外での廃棄や処理に伴うGHG排出量を登録します。なお本カテゴリには、廃棄物の輸送に係るGHG排出量も含むことが可能です。',
      },
    },
    category6: {
      title: i18n.t('register_data.title_scope_3_category_6'),
      position: i18n.t('register_data.hyperlink_upstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_6'),
      details: {
        method1: '出張に際して用いる交通機関の稼働に伴うCO2排出量',
      },
      patternId: {
        1: i18n.t('register_data.radio_calculated_passenger_kilometer'),
        2: i18n.t('register_data.radio_calculated_by_fuel_method'),
        3: i18n.t('register_data.radio_calculated_by_fuel_efficiency_method'),
        4: i18n.t('register_data.radio_calculated_payment_amount'), //s3c6 old
        5: i18n.t('register_data.radio_calculated_number_of_accommodation'),
        6: i18n.t('register_data.radio_calculated_number_business_trip_day'),
        7: i18n.t('register_data.radio_calculated_number_of_employees'),
      }
    },
    category7: {
      title: i18n.t('register_data.title_scope_3_category_7'),
      position: i18n.t('register_data.hyperlink_upstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_7'),
      details: {
        method1: '通勤（支給額）ベースでの交通機関の稼働に伴うCO2排出量',
        method2: '通勤（従業員数・勤務日数）ベースでの交通機関の稼働に伴うCO2排出量',
      },
      patternId: {
        1: i18n.t('register_data.radio_calculated_passenger_kilometer'),
        2: i18n.t('register_data.radio_calculated_by_fuel_method'),
        3: i18n.t('register_data.radio_calculated_by_fuel_efficiency_method'),
        4: i18n.t('register_data.radio_calculated_payment_amount'),
        5: i18n.t('register_data.radio_calculated_from_the_number_of_employees_and_business_days')
      }
    },
    category8: {
      title: i18n.t('register_data.title_scope_3_category_8'),
      position: i18n.t('register_data.hyperlink_upstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_8'),
      details: {
        method1: '賃借している資産の稼働に伴うCO2排出量',
      },
    },
    category9: {
      title: i18n.t('register_data.title_scope_3_category_9'),
      position: i18n.t('register_data.hyperlink_downstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_9'),
      details: {
        method1: '販売店の運営に伴うCO2排出量',
      },
      patternId: {
        1: i18n.t('b_register_product.method_calculated_by_fuel_method_and_shipping_fee'),
        2: i18n.t('b_register_product.method_calculated_by_the_tkm_method'),
        3: i18n.t('b_register_product.method_calculated_by_fuel_efficiency_method'),
        4: i18n.t('b_register_product.method_fuel'),
        5: i18n.t('b_register_product.method_electricity')
      }
    },
    category10: {
      title: i18n.t('register_data.title_scope_3_category_10'),
      position: i18n.t('register_data.hyperlink_downstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_10'),
      details: {
        method1: '販売した製品の加工に伴うCO2排出量',
      },
    },
    category11: {
      title: i18n.t('register_data.title_scope_3_category_11_export'),
      position: i18n.t('register_data.hyperlink_downstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_11'),
      details: {
        method1: '販売した製品の使用に伴うCO2排出量',
      },
      patternId: {
        1: i18n.t('register_data.radio_calculated_engery_using_product'),
        2: i18n.t('register_data.radio_calculated_fuel_feedstock'),
        3: i18n.t('register_data.radio_calculated_containing_product'),
        4: i18n.t('register_data.radio_calculated_indirect_use_stage'),
      }
    },
    category12: {
      title: i18n.t('register_data.title_scope_3_category_12_export'),
      position: i18n.t('register_data.hyperlink_downstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_12'),
      details: {
        method1: '廃棄物処理・リサイクル業者への委託費用や委託量に、排出原単位を乗じることによって排出量を推計',
      },
    },
    category13: {
      title: i18n.t('register_data.title_scope_3_category_13'),
      position: i18n.t('register_data.hyperlink_downstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_13'),
      details: {
        method1: '各リース資産についての規模等を表す指標に基づき算定',
      },
    },
    category14: {
      title: i18n.t('register_data.title_scope_3_category_14'),
      position: i18n.t('register_data.hyperlink_downstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_14'),
      details: {
        method1: '各リース資産についての規模等を表す指標に基づき算定',
      },
    },
    category15: {
      title: i18n.t('register_data.title_scope_3_category_15'),
      position: i18n.t('register_data.hyperlink_downstream'),
      description: i18n.t('register_data.tooltip_scope_3_category_15'),
      details: {
        method1: '株式',
        method2: '債務（債券・融資）',
        method3: 'プロジェクトファイナンス',
        method4: '資産運用・管理/顧客サービス',
      },
      patternId: {
        1: i18n.t('b_register_product.method_stock'),
        2: i18n.t('b_register_product.method_debt_bond_loan'),
        3: i18n.t('b_register_product.method_project_finance'),
        4: i18n.t('b_register_product.method_asset_management_customer_service'),
        5: i18n.t('setting_pcaf.pulldown_pcaf_method_listed_stocks_bouds'),
        6: i18n.t('setting_pcaf.pulldown_pcaf_method_bussiness_loans_unlisted_stocks'),
        7: i18n.t('setting_pcaf.pulldown_pcaf_method_project_finance'),
        8: i18n.t('setting_pcaf.pulldown_pcaf_method_commercial_estate'),
        9: i18n.t('setting_pcaf.pulldown_pcaf_method_home_loan'),
        10: i18n.t('setting_pcaf.pulldown_pcaf_method_car_loan'),
        11: i18n.t('setting_pcaf.pulldown_pcaf_method_debt')
      }
    },
    category16: {
      title: i18n.t('register_data.title_scope_3_category_16'),
      position: i18n.t('register_data.hyperlink_others'),
      description: i18n.t('register_data.tooltip_scope_3_category_16'),
      details: {
        method1: '15Categoryのなかで評価できないその他GHG排出量の登録',
      },
    },
  },
  scope4: {
    category1: {
      title: i18n.t('register_data.title_scope_4_category_1'),
      position: i18n.t('register_data.hyperlink_others'),
      description: i18n.t('register_data.tooltip_scope_4_category_1'),
      details: {
        method1: '国内認証排出削減量及び海外認証排出削減量',
      },
    },
  },
};

export const VERSIONS = 'ver. 1.270';