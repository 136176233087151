<template>
  <div>
    <DialogIncludeCloseIcon
      :dialogTitle="$t('tkm_pattern_table.label_popup_search_distance')"
      :dialog="dialog"
      @close="close"
      :hasAction="true"
      :cancelText="$t('tkm_pattern_table.label_popup_search_cancel')"
      :confirmText="$t('tkm_pattern_table.label_popup_search_submit')"
      @submit="submit"
      :disabled="disableSubmitButton"
    >
      <div class="search-wrapper-popup">
        <div class="search-radio">
          <div class="search-radio-label">{{ $t('tkm_pattern_table.label_popup_search_radio') }}</div>
          <RadioSelect v-model="vehicleModel" :defaultValue="vehicleModel" :items="vehicleType" @change="handleChangeRadio"/>
        </div>
        <div class="search-pulldown">
          <div class="search-pulldown-item">
            {{ $t('tkm_pattern_table.label_popup_search_departure_location') }}
            <InfiniteScroll
              @updateMasterDb="(value) => getPulldownModel(value, 'departureLocationModel')"
              v-bind="getPulldownProps('departureLocation')"
              :key="reRenderPulldown"
            />
          </div>
          <div class="search-pulldown-item">
            {{ $t('tkm_pattern_table.label_popup_search_arrival_location') }}
            <InfiniteScroll
              @updateMasterDb="(value) => getPulldownModel(value, 'arrivalLocationModel')"
              v-bind="getPulldownProps('arrivalLocation')"
              :key="reRenderPulldown"
            />
          </div>
        </div>
      </div>
      <div class="external-sources-table tkm-popup-table">
        <data-table
          :init-grid="initGrid"
          :data-source="tkmDistanceGrid"
          :grid-columns="tkmDistanceColumns"
          :allowAddNew="false"
          :isShowToolbar="false"
          :rowFocus="1"
          :showFocus="false"
          :tableName="'searchExternalSource'"
          :labelEmpty="messageLabelEmpty"
          :isEmptySource="isEmptySource"
          :isRemoveIndex="true"
          :headersVisibility="headersVisibility"
        />
      </div>
      <div class="note"> {{ $t('tkm_pattern_table.label_popup_search_note') }}</div>
    </DialogIncludeCloseIcon>
  </div>
</template>
<script>
import DataTable from '@/components/category/data-table';
import { CollectionView } from '@mescius/wijmo';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { mapActions } from 'vuex';
import RadioSelect from '@/components/commonApp/RadioCustom/index.vue';
import InfiniteScroll from '@/components/pulldown/InfiniteScroll';
import DialogIncludeCloseIcon from '@/components/dialogs/base/DialogIncludeCloseIcon';
import { BLANK_ID } from '@/constants/registerData';
import { getSearchListTkmDistance} from '@/api/tkm-method';
import { VEHICLE_TYPE } from '@/constants/tkm-method'
import { formatNumberRealNum } from '@/utils/convertNumber';

export default {
  name: 'TkmDistancePopup',
  components: { DataTable, RadioSelect, InfiniteScroll, DialogIncludeCloseIcon },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    selectedRow: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      flexGrid: null,
      tkmDistanceGrid: null,
      tkmDistanceColumns: [],
      data: [],
      tkmDistanceData: [],
      vehicleType: VEHICLE_TYPE,
      vehicleModel: 1,
      pulldownData: {
        departureLocationItems: [],
        departureLocationModel: null,
        arrivalLocationItems: [],
        arrivalLocationModel: null,
      },
      selectedItem: null,
      isEmptySource: false,
      reRenderPulldown: 0,
    };
  },
  async mounted() {
    await this.getTkmDistanceData();
    this.initialView();
  },
  created() {},
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
    watchData() {
      return this.pulldownData.departureLocationModel + this.pulldownData.arrivalLocationModel;
    },
    messageLabelEmpty() {
      return this.$t('tkm_pattern_table.message_label_empty')
    },
    disableSubmitButton() { 
      return !this.selectedItem || this.tkmDistanceData.length === 0
    },
    headersVisibility() {
      return 'Column'
    }
  },
  watch: {
    watchData: {
      handler() {
        this.getTkmDistanceData();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('commonApp', ['actionUpdateIsLoadingPopUp']),
    resetPullDown() {
      this.pulldownData.departureLocationModel = null;
      this.pulldownData.arrivalLocationModel = null;
    },
    handleChangeRadio() {
      this.resetPullDown();
      this.getTkmDistanceData()
      this.reRenderPulldown ++;
    },
    getPulldownModel(value, key) {
      if (key === 'departureLocationModel') { // when select start location reset end location
        this.pulldownData['arrivalLocationModel'] = null;
      }
      if (this.pulldownData[key] == value) {
        return;
      }
      this.pulldownData[key] = value;
      this.reRenderPulldown ++;
    },
    getPulldownProps(type) {
      const items = `${type}Items`;
      const model = `${type}Model`;
      let disable = false;
      if (type === 'arrivalLocation') {
        disable = !this.pulldownData.departureLocationModel
      }
      return {
        items: this.pulldownData[items],
        model: this.pulldownData[model],
        itemText: 'text',
        itemValue: 'value',
        dropdownElementId: `${type}_dropdown`,
        inputFieldElementId: `${type}_inputField`,
        tooltipColumn: `${type}_tooltipColumn`,
        searchInput: `${type}_searchInput`,
        isIconSearch: true,
        label: disable ? '' : type === 'arrivalLocation' ? 'Hong Kong' : 'Tokyo',
        isDisabled: disable
      };
    },
    mapToDropdownItems(locations) {
      return locations.map((location) => ({ text: location, value: location }));
    },
    updateDropdownItems(departureLocations, arrivalLocations) {
      if (!this.pulldownData.departureLocationModel) {
        this.pulldownData.departureLocationItems = this.mapToDropdownItems(departureLocations);
      }
      if (!this.pulldownData.arrivalLocationModel) {
        this.pulldownData.arrivalLocationItems = this.mapToDropdownItems(arrivalLocations);
      }
    },
    async getTkmDistanceData() {
      this.actionUpdateIsLoadingPopUp(true);
      const payload = {
        vehicle_type: this.vehicleModel,
        departure_location: this.pulldownData.departureLocationModel,
        arrival_location: this.pulldownData.arrivalLocationModel,
      };
      try {
        const res = await getSearchListTkmDistance(payload)
        const { departure_locations, arrival_locations, data } = res.data;
        this.updateDropdownItems(departure_locations, arrival_locations);
        this.tkmDistanceData = data.map((tkmItem) => ({
          ...tkmItem,
          distance: formatNumberRealNum(tkmItem.distance),
          vehicle_type: this.vehicleType.find(item => item.id === tkmItem.vehicle_type).name
        }));
        this.tkmDistanceGrid = new CollectionView([...this.tkmDistanceData]);
        this.isEmptySource = this.tkmDistanceGrid?.items?.length <= 0;
        this.flexGrid.columnGroups = this.initGridColumn();
        setMinMaxSizeColumns(this.flexGrid, this.tkmDistanceData);
        this.tkmDistanceGrid.currentItem = null;
      } catch (error) {
        console.error(error);
      } finally {
        this.actionUpdateIsLoadingPopUp(false);
      }
    },
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('close');
      this.$emit('submit', this.selectedItem);
    },
    initGridColumn() {
      return [
        {
          header: this.$t('tkm_pattern_table.table_select_radio_search_tkm'),
          binding: 'select_distance',
          maxWidth: 50 ,
          minWidth: 50,
          allowSorting: false,
          isReadOnly: false,
          isRequired: false,
          dataType: 'Boolean',
          cssClassAll: 'single-row hide-filter justify-center',
        },
        {
          header: this.$t('tkm_pattern_table.table_vehicle_type_search_tkm'),
          binding: 'vehicle_type',
          minWidth: 132,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          multiLine: true,
          cssClassAll: 'single-row hide-filter justify-center',
        },
        {
          header: this.$t('tkm_pattern_table.table_departure_location_search_tkm'),
          binding: 'departure_location',
          minWidth: 420,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          cssClassAll: 'single-row hide-filter justify-center',
        },
        {
          header: this.$t('tkm_pattern_table.table_arrival_location_search_tkm'),
          binding: 'arrival_location',
          minWidth: 420,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          cssClassAll: 'single-row hide-filter justify-center',
        },
        {
          header: this.$t('tkm_pattern_table.table_distance_search_tkm'),
          binding: 'distance',
          width: '*',
          minWidth: 132,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          align: 'right',
          cssClassAll: 'single-row hide-filter justify-center',
        },
      ];
    },
    onSelectItem(item) {
      this.selectedItem = item;
    },
    initGrid(grid) {
      this.flexGrid = grid;

      grid.scrollPositionChanged.addHandler((s) => {});

      if (this.flexGrid) {
        this.flexGrid.columnGroups = this.initGridColumn();
      }
      grid.beginningEdit.addHandler((s, e) => {
        const column = s.columns[e.col];
        if (column.binding === 'select_distance') {
          e.cancel = false;
        } else e.cancel = true;
      });
      grid.formatItem.addHandler((s, e) => {
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel === s.cells && colBinding === 'select_distance') {
          const rowData = s.rows[e.row].dataItem;
          const radioButton = document.createElement('input');
          radioButton.type = 'radio';
          radioButton.name = 'select_distance';
          radioButton.className = 'custom-radio-table';
          radioButton.value = rowData.id;
          if (e.row === 0) {
            // selected the first row
            radioButton.ariaChecked = true;
            this.onSelectItem(rowData);
          } else radioButton.ariaChecked = false;
          radioButton.addEventListener('change', () => {
            this.onSelectItem(rowData);
            Array.from(document.querySelectorAll('input[name="select_distance"]')).forEach((radio) => {
              const isChecked = radioButton.getAttribute('value') === radio.getAttribute('value');
              radio.setAttribute('aria-checked', isChecked ? 'true' : 'false');
            });
          });
          e.cell.innerHTML = '';
          e.cell.appendChild(radioButton);
        }
      });
    },
    initialView() {
      this.tkmDistanceColumns = this.initGridColumn();
      if (this.tkmDistanceGrid) {
        this.data = this.tkmDistanceGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
        this.tkmDistanceGrid.currentItem = null;
      }
      if (this.flexGrid) {
        this.flexGrid.columnGroups = this.initGridColumn();
        setMinMaxSizeColumns(this.flexGrid, this.tkmDistanceData);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
.search-pulldown {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  color: $monoBlack;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.42px;
  @media (max-width: $desktop) {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
  .search-pulldown-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    min-width: 380px;
    @media (max-width: $desktop) {
      min-width: 100%;
    }
  }
}

.search-radio-label {
  color: $monoBlack;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.42px;
  margin-bottom: 16px;
}

.search-wrapper-popup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  align-self: stretch;
  margin-bottom: 32px;
}
.note {
  color: $monoBlack;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  width: 1066px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 24px;
  width: 100%;
}

</style>
<style lang="scss" >
</style>