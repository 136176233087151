export const DRAFT_STATUS = 'status_draft';
export const SUBMITTED_STATUS = 'status_submit';
export const ENABLED_STATUS = 'status_enable';
export const DISABLED_STATUS = 'status_disable';
export const APPROVED_STATUS = 'status_approve';

export const MESSAGE_BATCH_SETTING = {
  labelButton: {
    [DRAFT_STATUS]: 'multiple_approval.button_submit_data_draf',
    [SUBMITTED_STATUS]: 'multiple_approval.button_submit_data_submitted',
    [ENABLED_STATUS]: 'batch_setting.button_submit_enable',
    [DISABLED_STATUS]: 'batch_setting.button_submit_disable',
    [APPROVED_STATUS]: 'batch_setting.button_approved'
  },
  confirm: {
    [DRAFT_STATUS]: null,
    [SUBMITTED_STATUS]: 'multiple_approval.description_confirm_cancel_submitted',
    [ENABLED_STATUS]: 'batch_setting.description_confirm_enable_submitted',
    [DISABLED_STATUS]: 'batch_setting.description_confirm_disable_submitted',
    [APPROVED_STATUS]: null
  },
  popup: {
    [DRAFT_STATUS]: 'register_data.popup_message_submit_sucess',
    [SUBMITTED_STATUS]: 'register_data.popup_message_cancel_submit',
    [ENABLED_STATUS]: 'batch_setting.popup_message_enable_submit',
    [DISABLED_STATUS]: 'batch_setting.popup_message_disable_submit',
    [APPROVED_STATUS]: null
  },
  submit: {
    [DRAFT_STATUS]: null,
    [SUBMITTED_STATUS]: 'register_data.button_cancel_request',
    [ENABLED_STATUS]: 'batch_setting.button_submit',
    [DISABLED_STATUS]: 'batch_setting.button_submit',
    [APPROVED_STATUS]: null
  },
  labelSuccess: { 
    [DRAFT_STATUS]: 'list_menu.label_decision',
    [SUBMITTED_STATUS]: 'list_menu.label_decision',
    [ENABLED_STATUS]: 'batch_setting.button_submit_success',
    [DISABLED_STATUS]: 'batch_setting.button_submit_success',
    [APPROVED_STATUS]: 'list_menu.label_decision'
  }
};
