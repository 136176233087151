<template>
  <div class="main verify-otp">
    <div class="wrap">
      <div class="left_wrap">
        <div class="image-title">
          <p>Welcome to</p>
          <p v-if="isSuplier" class="supplier">{{ $t('supplier.label_under_logo_login_screen') }}</p>
        </div>
      </div>
      <div class="right_wrap" v-if="!status">
        <v-form class="right_block verify_otp_frm" @submit.prevent="submit" ref="form" lazy-validation>
          <h1 class="title-text">
            <span class="title-text-style">{{ $t('2FA.title_email_send') }}</span>
          </h1>
          <div class="instruction forgetPass_inst mt-48">
            <span>{{ $t('2FA.description_email_send') }}
            </span>
          </div>
          <div class="input-block">
            <span class="input-title">{{ $t('2FA.label_opt') }}</span>
            <div class="passBox">
              <input id="password" v-model="otp" class="input-elic mb-0" :type="!showPass ? 'password' : 'text'"
                @focus="handleFocusPass" @blur="handleBlurPass" />
              <eye-icon :id="'password_eye'" :changeIcon="() => (this.showPass = !this.showPass)" />
              <span class="err-mess" style="display: none" id="password_error"></span>
              <img id="password_img" class="validWarning" src="/img/icons/warning.svg"
                style="position: absolute; right: 6px; display: none" />
            </div>
          </div>
          <div class="input-block forgetPass_inst">
            <span class="input-description">{{ $t('2FA.description_otp_10min') }}</span>
          </div>
          <div class="input-block forgetPass_inst">
            <input class="btn-submit" :disabled="disabled" type="submit" :value="buttonSend" />
          </div>

          <div class="input-block forgetPass_inst">
            <span class="input-description">{{ $t('2FA.description_detail_check_email') }} </span>
          </div>

          <div class="forgetPass_inst button-send">
            <span class="input-description reset-otp" @click="dialogQuestion = true">{{ $t('2FA.label_resend_otp') }}
              <span class="underline"></span>
            </span>
          </div>
        </v-form>
      </div>
    </div>
    <error-popup :dialog="dialog" :message="message" @submit="handleClose" />
    <question-popup :dialog="dialogQuestion" :message="$t('2FA.label_confirm_resend_otp')"
      :confirmText="$t('2FA.button_confirm_send_opt_again')" :loading="isLoadingGetOtp" @close="dialogQuestion = false"
      classes="change-method" @submit="submitQuestion()" />
    <notification-popup :confirmText="'OK'" :dialog="dialogNotification" :message="message"
      @submit="dialogNotification = false" />
  </div>
</template>

<script>
import { getErrorMessge } from '@/utils/messageHandle';
import QuestionPopup from '@/components/dialogs/question-popup';
import InputField from '@/components/products/input/InputField.vue';
import { loginApi } from '@/api/auth';
import { validateRequired } from '@/api/others';
import { ROUTES } from '@/router/constants';
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import EyeIcon from '@/components/commonApp/EyeIcon.vue';

export default {
  data() {
    return {
      dialog: false,
      dialogLockPass: false,
      dialogCheck: false,
      otp: '',
      message: '',
      status: false,
      disabled: false,
      isActiveCloseIcon: false,
      dialogQuestion: false,
      errorMessagesTitle: '',
      email: '',
      password: '',
      isLoadingGetOtp: false,
      dialogNotification: false,
      showPass: false,
    };
  },
  components: {
    NotificationPopup,
    QuestionPopup,
    InputField,
    NotificationPopup,
    ErrorPopup,
    EyeIcon
  },
  methods: {
    validateOtpInput() {
      // otp < 6 characters
      if (this.otp.length > 6) {
        this.otp = this.otp.slice(0, 6);
      }
    },
    handleFocusPass() {
      const password_img = document.getElementById('password_img');
      const password_error = document.getElementById('password_error');
      const password = document.getElementById('password');
      const passwordEye = document.getElementById('password_eye');
      password.style.border = '2px solid #327998';
      password_img.style.display = 'none';
      password_error.style.display = 'none';
      passwordEye.style.display = 'block';
    },
    handleBlurPass() {
      const password = document.getElementById('password');
      password.style.border = 'none';
    },
    submit() {
      if (!validateRequired(['password'])) {
        return;
      } else if (!this.otp?.trim()) {
        this.dialog = true;
        this.message = this.$t('2FA.error_message_otp_invalid');
        return;
      }
      this.disabled = true;

      loginApi({
        email: this.email?.toLowerCase(),
        password: this.password.trim(),
        otp: this.otp.trim(),
        primary_uuid: this.$route?.query?.primary_uuid,
      })
        .then((res) => {
          this.disabled = false;
          if (!res.user.status) {
            this.dialog = true;
          } else {
            if (res.user?.language?.code !== this.$i18n.locale) {
              localStorage.setItem('language', res.user?.language?.code);
              const newUrl = this.$router.resolve({
                path: ROUTES.TYPE_SELECTION,
                query: { ...this.$route.query }
              }).href;
              window.location.replace(newUrl);
              localStorage.setItem('hasGroupsImporting', true)
            } else {
              this.$router.push({ path: ROUTES.TYPE_SELECTION, query: { ...this.$route.query } });
              localStorage.setItem('hasGroupsImporting', true)
            }
          }
        })
        .catch((err) => {
          this.disabled = false;
          if (err.code === 'block_account') {
            this.message = err.message;
          } else {
            this.message = getErrorMessge(err);
          }
          this.dialog = true;
        });
    },

    handleClose() {
      this.otp = '';
      this.dialog = false;
      this.errorMessagesTitle = '';
    },
    clearDataModel() {
      this.otp = '';
      this.errorMessagesTitle = '';
    },
    submitQuestion() {
      this.isLoadingGetOtp = true;
      // to call get re-send otp
      loginApi({
        email: this.email?.toLowerCase(),
        password: this.password.trim(),
        primary_uuid: this.$route?.query?.primary_uuid,
      })
        .then((res) => {
          if (res?.is_sending_otp) {
            this.dialogQuestion = false;
            this.message = this.$t('2FA.popup_inform_resend_verify_email_ok');
            this.dialogNotification = true;
          }
        })
        .catch((err) => {
          this.dialogQuestion = false;
          if (err.code === 'block_account') {
            this.message = err.message;
          } else {
            this.message = getErrorMessge(err);
          }
          this.dialog = true;
        })
        .finally(() => {
          this.isLoadingGetOtp = false;
        });
    },
    handleBlurInput(value) {
      // if (value?.length > 6) {
      //   this.setErrorMessage('validation.error_maximum_20_charactors', { charactors: '6' });
      // }
    },
    setErrorMessage(messageKey, params = {}) {
      this.errorMessagesTitle = this.$t(messageKey, params);
      this.disabled = false;
    },
  },
  computed: {
    buttonSend() {
      return this.$t('2FA.button_login_in_otp_scren');
    },
    isSuplier() {
      return this.$route?.query?.primary_uuid;
    },
  },
  created() {
    // Only use isGenerateOTP for the first time
    this.email = sessionStorage.getItem('email');
    this.password = sessionStorage.getItem('password');
    if (sessionStorage.getItem('isGenerateOTP')) {
      sessionStorage.removeItem('isGenerateOTP');
      sessionStorage.removeItem('email');
      sessionStorage.removeItem('password');
    } else {
      this.$router.push({ path: '/auth/login' })
    }
  },
};
</script>

<style lang="scss" scoped>
@import './LoginStyle/index.scss';

.while-color {
  background: #ffffff;
}

.wrap {
  min-height: 270px;
  overflow: auto;
  display: flex;
  gap: 81px;
}

.title-text {
  span {
    position: relative !important;
    top: 1px !important;
  }
}

.input-block {
  position: relative;
}

.clear-data-input-search {
  position: absolute;
  top: 44px;
  right: 11px;
  cursor: pointer;
}

.input-description {
  color: $monoDark;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.05em;

  &.reset-otp {
    position: relative;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;

    .underline {
      position: absolute;
      left: 0;
      width: 0;
      height: 1px;
      background-color: $monoDark;
      transition: width 0.3s ease;
      bottom: -2px;
    }

    &:hover {
      text-decoration: underline;

      .underline {
        width: 100%;
      }
    }
  }
}

.main {
  &.verify-otp {
    @media (max-width: 1024px) {
      height: auto;
    }
  }
}

.button-send {
  width: 100%;
  display: flex;
}

.input-block {
  margin-top: 32px;
}
@include desktop {
  .input-block {
    margin-top: 24px;
  }
}
</style>
