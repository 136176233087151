<template>
  <div class="setting-page padding-top-layout">
    <div class="wrap">
      <div class="wrap__block">
        <div class="wrap__block__header wrap__header">
          <div class="wrap__block__header__content">
            <h1 class="wrap-title padding-left-layout">{{ $t("list_approve.title_approval_of_emissions") }}</h1>
            <div class="helper-icon">
              <img
                :src="getSettingIcon('helper-icon.svg')"
                @mouseover="changeTooltipImage($event)"
                @mouseleave="changeTooltipImage($event, 'mouseleave')"
                alt=""
              />
              <div
                v-if="showTooltip"
                class="helper-icon tooltip"
                v-html='$t("list_approve.tooltip_approval_use")'
              ></div>
            </div>
          </div>
        </div>
        <div class="wrap__action wrap__pc">
          <!-- Desktop response -->
          <div class="select-data" :class="$i18n.locale">
            <v-select
              :items="listActionMulti"
              v-model="optionSelectMulti"
              item-text="text"
              item-value="value"
              :placeholder="$t('multiple_approval.placeholder_select_type')"
              dense
              hide-details
              solo
              return-object
              no-data-text="''"
              @change="handleChangeOptionMulti"
              class="select-type select-default list-menu--select select-item custom-placeholder-color"
              :class="{ 'disable-select': isEmptySource }"
              :menu-props="{ contentClass: 'select-menu export-select' }"
              :disabled="isEmptySource"
            >         
            </v-select>
          </div>
          <common-button
            class="submit-register-btn"
            :disabled="!totalRowsCheckedLength"
            :label="getLabelButtonSubmit"
            type="colored"
            @action="submitData"
            v-ripple="false"
          />
        </div>
      </div>
    </div>
    <div
      class="category-table main-table custom-table approval-table"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
    >
      <data-table
        :data-source="approvalGrid"
        :grid-columns="approvalColumn"
        :init-grid="initGrid"
        :rowFocus="1"
        :allowAddNew="false"
        :isShowToolbar="false"
        :filterColumns="filterColumns"
        :showFocus="false"
        :isEmptySource="isEmptySource"
        :labelEmpty="descriptionLabelEmpty"
        :isResizeCustome="false"
        :bigCheckboxes="true"
        @changeFullScreen="onChangeFullScreen"
        @flexGridInitialDone="onFlexGridInitialDone"
        @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
      />
    </div>
    <log-history-popup :dialog="logHistoryPopup" :historyLogList="historyLogList" @close="logHistoryPopup = false" />
    <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
    <approval-popup
      :dialog="dialogApproval"
      :items="listStatusApproval"
      :confirmText="$t('register_data.button_decision')"
      :notificationText="$t('multiple_approval.description_popup_approve', { totalRowsCheckedLength })"
      @submit="approvalDataHandler"
      @close="dialogApproval = false"
    />
    <notification-popup
      :dialog="notificationPopup"
      :message="notificationMessage"
      @submit="notificationPopup = false"
      :confirmText="confirmTextSuccessPopup"
    />
    <QuestionPopup
      :dialog="dialogStatusPopup"
      :message="messageDialogPopup"
      :isSaveButtonDelete="!isDisableEnable"
      :confirmText="confirmMessage"
      :loading="loading"
      @submit="submitDataHandler"
      @close="dialogStatusPopup = false"
    />
    <div ref="scrollElement">
      <!-- Mobile response -->
      <div class="button-bottom" :class="{ 'fixed-button-bottom': isFixedButton }">
        <div class="wrap__action wrap__mobile">
          <v-select
            :items="listActionMulti"
            v-model="optionSelectMulti"
            item-text="text"
            item-value="value"
            :placeholder="$t('multiple_approval.placeholder_select_type')"
            dense
            hide-details
            solo
            return-object
            no-data-text="''"
            @change="handleChangeOptionMulti"
            class="select-type select-default list-menu--select select-item custom-placeholder-color"
            :menu-props="{ contentClass: 'select-menu export-select export pulldown-year' }"
            :class="{ 'disable-select': isEmptySource }"
            :disabled="isEmptySource"
          >
          </v-select>
          <common-button
            class="submit-register-btn"
            :disabled="!totalRowsCheckedLength"
            :label="getLabelButtonSubmit"
            type="colored"
            @action="submitData"
            v-ripple="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/components/category/data-table";
import CommentLogPopup from "@/components/dialogs/log-confirm-popup";
import LogHistoryPopup from "@/components/dialogs/history-popup.vue";
import ButtonCommonCustome from "@/components/commonApp/ButtonCustome.vue";
import NotificationPopup from "@/components/dialogs/notification-popup.vue";

import { getListCommentHistory, getListLogHistory, getListWorkflowApi } from "@/api/registerData";
import { getUserInfo } from "@/api/auth";
import { ROUTES } from "@/router/constants";
import { STATUS_FIELD } from "@/constants/status";
import { KEYS_CODE } from "@/constants/keyboard";
import { BLANK_ID, CANCEL_TYPE, ACTION_HEADER_TABLE, APPROVAL_TYPE } from "@/constants/registerData";
import { getColorStatusById, getStatusNameById, getColorTextStatusById } from "@/utils/status";
import { getCategoryName, getDataLayer, getScopeName } from "@/utils/registerData";
import { formatDateTimeDataTable } from "@/utils/datetimeFormat";
import { mapActions, mapState } from "vuex";
import * as wjcCore from "@mescius/wijmo";
import { CollectionView } from "@mescius/wijmo";
import { UndoStack } from "@/concerns/utils/undo-stack";
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import * as wjGrid from "@mescius/wijmo.grid";
import throttle from "lodash/throttle";
import { updateDataBeforeRedirect } from "@/utils/updateDataBeforeRedirect";
import { cancelSubmittedData, updateStatusMultipleData } from '@/api/newRegisterData';
import { $_helper_isNumberType, formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import { getWidthOfDetailButton, getWidthOfLogHistory } from '@/utils/multiLanguage'
import { getWidthByText } from '@/utils/getWidthByText';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import CommonButton from '@/components/utils/button.vue';
import ApprovalPopup from '@/components/dialogs/approval-popup';
import { APPROVAL_STATUS } from '@/constants/registerData';
import { ENABLED_STATUS, DISABLED_STATUS, MESSAGE_BATCH_SETTING, APPROVED_STATUS } from '@/constants/batch-setting'
import QuestionPopup from '@/components/dialogs/question-popup';
import { ROLE } from '@/constants/role';

export default {
  name: "ApprovalPage",
  components: {
    DataTable,
    CommentLogPopup,
    LogHistoryPopup,
    CommonButton,
    ApprovalPopup,
    ButtonCommonCustome,
    NotificationPopup,
    QuestionPopup
  },
  data() {
    return {
      approvalGrid: null,
      flexGrid: null,
      approvalData: [],
      approvalColumn: [],
      dialogChangeStatus: false,
      dialogComment: false,
      listStatusApproval: [],
      showTooltip: false,
      breadCrumb: [
        {
          text: this.$t('list_approve.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD
        },
        {
          text: this.$t('list_approve.label_approval_of_emissions'),
          disabled: true,
          href: ROUTES.EMISSIONS
        }
      ],
      commentContents: [],
      filterColumns: [],
      commentPopup: false,
      logHistoryPopup: false,
      commentHistoryList: [],
      historyLogList: [],
      userName: "",
      selectedFilterColumn: null,
      isEmptySource: true,
      selectedItems: [],
      totalRowsCheckedLength: 0,
      dialogApproval: false,
      isFixedButton: true,
      isEditTitle: false,
      dialogNotification: false,
      notificationPopup: false,
      notificationMessage: '',
      listActionMulti: [
        {
          text: this.$t('batch_setting.label_select_status_approve'),
          column_name: APPROVED_STATUS,
          data_bidding: 'is_status_submit',
          value: 1
        },
        {
          text: this.$t('batch_setting.label_select_status_enable'),
          column_name: ENABLED_STATUS,
          data_bidding: 'is_status_enable',
          value: 2
        },
        {
          text: this.$t('batch_setting.label_select_status_disable'),
          column_name: DISABLED_STATUS,
          data_bidding: 'is_status_disable',
          value: 3
        }
      ],
      optionSelectMulti: {},
      listCheckBoxStatus: [APPROVED_STATUS, ENABLED_STATUS, DISABLED_STATUS],
      messageDialogPopup: '',
      dialogStatusPopup: false,
      confirmMessage: this.$t('register_data.button_cancel_request'),
      dataApproval: {},
      loading: false,
      confirmTextSuccessPopup: this.$t('list_menu.label_decision'),
    };
  },
  async created() {
    this.handleUpdatePulldownBatch();
    this.initializeDefaultOptions();
    if(this.$route.query?.is_allow_cancel) {
      await this.handleApprovalCancleSubmit();
    }
    this.actionUpdateIsLoadingTable(true)
    await this.checkUserInfo();
    await this.handleGetDataApproval(true);
    this.actionUpdateIsLoadingTable(false)
    this.handleChangeOptionMulti();
  },
  async mounted() {
    this.updateBreadCrumb(this.breadCrumb);
    this.listStatusApproval = [
      {
        id: APPROVAL_TYPE.APPROVE,
        name: this.$t('register_data.radio_approve'),
        description: this.$t('register_data.description_approve'),
      },
      {
        id: APPROVAL_TYPE.REJECT,
        name: this.$t('register_data.radio_reject'),
        description: this.$t('register_data.description_reject'),
      },
    ];
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    this.actionDisableMenu(false);
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: { 
    totalRowsCheckedLength(newVal) {
      this.actionDisableMenu(newVal > 0)
    }
  },
  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb", "actionUpdateIsLoadingTable"]),
    ...mapActions("registerData", ["updateDataScope", "updateDataCategory", "updateDataMonth", "updateDuration"]),
    ...mapActions("newRegisterData", ["updateIsApproved", "updatePatternId"]),
    ...mapActions("settingApp", ["actionDisableMenu"]),
    ...mapActions('workflowData', ['getNotification']),
    async checkUserInfo() {
      await getUserInfo()
        .then((res) => {
          this.userName = res.name;
        })
        .catch(() => {
        });
    },
    handleUpdatePulldownBatch() {
      if (this.currentUser?.user?.role_id !== ROLE.ADMIN) {
        this.listActionMulti = this.listActionMulti.filter(item => item.column_name !== ENABLED_STATUS && item.column_name !== DISABLED_STATUS);
      }
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip = true;
      }
    },
    initGrid(grid) {
      document?.addEventListener("keydown", (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        "keydown",
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
          if (event.keyCode === KEYS_CODE.ENTER) {
            if (grid.selection.row === grid.rows.length - 1) {
              const lastClientId = grid.itemsSource.itemCount;

              grid.deferUpdate(() => {
                grid.itemsSource.addNew(this.blankData(lastClientId + 1));
                grid.itemsSource.commitNew();
                grid.itemsSource.clearChanges();
              });
            }
          }
        },
        false
      );

      grid.beginningEdit.addHandler((sender, event) => {
        let column = sender.columns[event.col];
        const listColumnNotDisable = [
          "unique_id",
          "log_history",
          this.optionSelectMulti.column_name
        ];
        if (!listColumnNotDisable.includes(column.binding)) {
          event.cancel = true;
        }
      });

      grid.pastingCell.addHandler((s, e) => {
        const col = e.panel.columns[e.col];
        const view = s.collectionView;
        const source = view.sourceCollection;
        const currentItem = source[e.range.row] || {};
        if(col.binding === this.optionSelectMulti.column_name) {
          e.cancel = true;
        }
      })

      grid.hostElement.addEventListener('mouseover',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      grid.hostElement.addEventListener('mouseout',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
        e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      this.flexGrid = grid;
      this.flexGrid.onSelectionChanged(null);
      grid.onSelectionChanged(null);
    },
    async handleGetDataApproval(isReloadFirstOne) {
      const payload = {
        action: "listApproval"
      };
      const approvalResponse = await getListWorkflowApi(payload);
      if (approvalResponse.data.length > 0) {
        const approvedStatuses = [
          STATUS_FIELD.STATUS_APPROVED,
          STATUS_FIELD.STATUS_REPRENSENT_APPROVED,
        ];
        const isApprovedStatus = (status) => approvedStatuses.includes(status);
        const isStatusCanDisable = (item) => isApprovedStatus(item.status) && (item.is_active || item.can_enable_or_disable);
        const isStatusCanEnable = (item) => isApprovedStatus(item.status) && (!item.is_active || item.can_enable_or_disable);
        this.approvalData = approvalResponse.data.map((approvalItem) => {
          let status = getStatusNameById(approvalItem.status);
          let color = getColorTextStatusById(approvalItem.status);

          if (approvalItem.status === STATUS_FIELD.STATUS_SUBMIT) {
            status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
            color = getColorTextStatusById(STATUS_FIELD.STATUS_REPRENSENT_RETURN)
          }

          if (approvalItem.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED) {
            status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
          }

          return {
            ...approvalItem,
            status: status,
            approver_info: approvalItem?.approved_by ? approvalItem?.approver?.name : approvalItem?.selected_approver.name,
            submitter: approvalItem?.submitter?.name,
            log_history: this.$t('list_approve.button_log_display'),
            scope: getScopeName(parseInt(approvalItem.scope)),
            category_name: getCategoryName(parseInt(approvalItem.scope), parseInt(approvalItem.category)) || null,
            layer1: getDataLayer(approvalItem.scope, approvalItem.category, approvalItem.pattern_id)?.method || null,
            layer2: getDataLayer(approvalItem.scope, approvalItem.category, approvalItem.pattern_id)?.sub_method || null,
            lasted_update: formatDateTimeDataTable(approvalItem.updated_at),
            month_year_registration: `${approvalItem.year}/${String(approvalItem.month).padStart(2, "0")}`,
            iconComment: approvalItem.comment_count > 0 ? require(`@/assets/icons/comment.svg`) : "",
            scope_id: parseInt(approvalItem.scope),
            category_id: parseInt(approvalItem.category),
            date_submit: formatDateTimeDataTable(approvalItem.submitted_at),
            date_approval: formatDateTimeDataTable(approvalItem.approved_at),
            contractor_name: approvalItem.contractor?.name,
            duration_id: approvalItem.duration_id,
            approved_by: approvalItem.approved_by,
            curent_user: this.userName,
            title: approvalItem.title || null,
            colorStatus: color,
            is_status_submit: approvalItem.status === STATUS_FIELD.STATUS_SUBMIT,
            is_status_disable: isStatusCanDisable(approvalItem),
            is_status_enable: isStatusCanEnable(approvalItem),
            status_submit: false,
            status_id: approvalItem.status
          };
        });
      }

      if (isReloadFirstOne) {
        this.defineTable();
      } else {
        this.approvalGrid.sourceCollection = this.approvalData;
      }
    },
    handleEvent() {
      let isHandlingCollectionChange = false;
      this.approvalGrid.collectionChanged.addHandler(
        throttle(async() => {
          if (isHandlingCollectionChange) {
            return;
          }

          isHandlingCollectionChange = true;
          setTimeout(() => {
            isHandlingCollectionChange = false;
          }, 100);

          let edited = {};
          this.approvalGrid.itemsEdited.forEach((approvalItem, approvalIndex) => {
            if (approvalItem.title) {
              // prepare data: title, id, ...
              edited[approvalIndex] = {
                id: approvalItem.id,
                title: approvalItem.title
              };
            }
          });
          // if (Object.values(edited).length) {
          //   try {
          //     this.approvalGrid.itemsEdited.length = 0;
          //   } catch (error) {
          //     this.approvalGrid.itemsEdited.length = 0;
          //   }
          // }
        }, 300)
      );
    },
    initialView() {
      if (this.approvalGrid) {
        this.approvalData = this.approvalGrid.items.filter(
          (approvalItem) => approvalItem.id && approvalItem.id !== BLANK_ID
        );
      }
      this.approvalGrid = new CollectionView([...this.approvalData], {
        trackChanges: true,
        sortComparer: (a, b) => {
          if (this.selectedFilterColumn !== 'category_name') return null;
          const valueA = a?.split('カテゴリ')[1]?.trim();
          const valueB = b?.split('カテゴリ')[1]?.trim();
          if ($_helper_isNumberType(valueA) && $_helper_isNumberType(valueB)) {
            const numA = Number(formatValue(valueA));
            const numB = Number(formatValue(valueB));
            return numA - numB;
          }
          return null;
        }
      });
      this.handleEvent();
      this.flexGrid.columnGroups = this.getApprovalColumns();
      this.approvalGrid.currentItem = null;
      this.flexGrid.cellEditEnded.addHandler((s, e) => {
        const totalRowsChecked = this.getTotalRowsChecked();
        this.totalRowsCheckedLength = totalRowsChecked.length;

        const col = e.panel.columns[e.col];
        if (col.binding === 'title') {
          this.isEditTitle = true;
        }
      });
      this.flexGrid.formatItem.addHandler((s, e) => {
        if (s.columns[e.col].binding === 'status' && s.cells.cellType === wjGrid.CellType.Cell) {
          let rowValue = s.rows[e.row]?._data;
          e.cell.classList.add(rowValue?.colorStatus);
        }

        if (this.listCheckBoxStatus.includes(s.columns[e.col].binding) && e.panel == s.columnHeaders) {
          const checkbox = document.createElement('input');
          checkbox.type = 'checkbox';
          checkbox.classList.add('wj-cell-check-custome');
          const self = this;
          checkbox.checked = self.selectAllChecked();
          const hasCheckableItems = this.flexGrid.rows.some(row => row.dataItem && row.dataItem[this.optionSelectMulti.data_bidding]);
          // If there are no checkable items, disable the checkbox
          if (!hasCheckableItems) {
            checkbox.classList.add('wj-state-disabled');
          }
          checkbox.addEventListener('click', (_) => {
            this.flexGrid.beginUpdate();
            this.flexGrid.rows.forEach((row) => {
              if (row.dataItem && row.dataItem[this.optionSelectMulti.data_bidding]) {
                if (checkbox.checked) {
                  self.selectedItems.push({
                    id: row?.dataItem.id,
                    name: row?.dataItem.name,
                  });
                }
                row.dataItem[this.optionSelectMulti.column_name] = checkbox.checked;
              }
            });
            if (checkbox.checked) {
              self.removeDuplicateData(self.selectedItems);
            } else {
              self.selectedItems = [];
            }
            this.flexGrid.endUpdate();

            const totalRowsChecked = this.getTotalRowsChecked();
            this.totalRowsCheckedLength = totalRowsChecked.length;
          });

          e.cell.appendChild(checkbox);
        }

        if (s.columns[e.col].binding === this.optionSelectMulti.column_name && e.panel != s.columnHeaders) {
          const rowData = s.rows[e.row].dataItem;
          const isDisable = !rowData[this.optionSelectMulti.data_bidding];
          if (isDisable) {
            wjcCore.addClass(e.cell, "wj-cell-background-disabled");
            wjcCore.toggleClass(e.cell, "wj-state-disabled");
          }
        }

        if (s.columns[e.col].binding === 'unique_id' && e.panel != s.columnHeaders) {
          const checkLeastOneItemChecked = this.getTotalRowsChecked();
          if (checkLeastOneItemChecked.length > 0) {
            wjcCore.toggleClass(e.cell, "wj-state-disabled");
          }
        }
      });
      this.approvalGrid.collectionChanged.addHandler((s, e) => {
        const checkLeastOneItemChecked = s._view.some((row) => {
          return row.is_can_checkbox;
        });

      });
      setMinMaxSizeColumns(this.flexGrid, this.approvalData);
      this.markUnreadRows();
    },
    getTotalRowsChecked() {
      const totalRowsChecked = this.approvalGrid.sourceCollection.filter((row) => row[this.optionSelectMulti.column_name])
      return totalRowsChecked;
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    selectAllChecked() {
      const totalRows = this.flexGrid.rows.filter((row) => row.dataItem && row.dataItem[this.optionSelectMulti.data_bidding]);
      const totalRowsChecked = totalRows.filter((row) => row.dataItem[this.optionSelectMulti.column_name] === true);

      return totalRows.length === totalRowsChecked.length && totalRows.length !== 0 && totalRowsChecked.length !== 0
        ? true
        : false;
    },
    removeDuplicateData(arr) {
      this[arr] = [...new Map(arr.map((item) => [item['id'], item])).values()];
    },
    getApprovalColumns() {
      const checkBoxColumns = this.listCheckBoxStatus.map((status) => ({
        header: ' ',
        binding: status,
        cssClassAll: 'column-checkbox hide-filter',
        minWidth: 40,
        maxWidth: 40,
        dataType: 'Boolean',
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        visible: false,
      }));
      return [
        {
          header: "#",
          binding: "id",
          allowSorting: false,
          isReadOnly: true,
          visible: false
        },
        {
          header: " ",
          binding: "unique_id",
          minWidth: getWidthOfDetailButton(),
          maxWidth: getWidthOfDetailButton(),
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          cssClass: "btn-db",
          cellTemplate: CellMaker.makeButton({
            text: this.$t('list_approve.button_detail'),
            click: (event, context) => this.onIdClicking(context)
          }),
          cssClassAll: "height34 approval-cell_custom hide-filter"
        },
        ...checkBoxColumns,
        {
          header: this.$t('list_approve.table_status'),
          binding: "status",
          minWidth: getWidthByTextContent(this.$t('list_approve.table_status')),
          maxWidth: 350,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cellTemplate: (ctx) => (`<span class="${ctx.item.colorStatus}">${ctx.item.status}</span>`),
          cssClassAll: "height34 approval-cell_status"
        },
        {
          header: " ",
          binding: "iconComment",
          minWidth: 32,
          maxWidth: 32,
          allowSorting: false,
          isRequired: false,
          cssClass: "btn-db",
          cellTemplate: CellMaker.makeImage({
            click: (event, context) => this.onCommentClicking(context)
          }),
          cssClassAll: "approval-cell_comment hide-filter comment-icon",
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_approve.table_submitter'),
          binding: "submitter",
          minWidth: getWidthByTextContent(this.$t('list_approve.table_submitter')),
          maxWidth: 220,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_approve.table_approver_info'),
          binding: "approver_info",
          minWidth: getWidthByTextContent(this.$t('list_approve.table_approver_info')),
          maxWidth: 300,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: "approver_info",
          cellTemplate: "<span class=\"${(item.approver_info !== item.curent_user) && \"not-same-name\"}\">${item.approver_info}</span>"
        },
        {
          header: this.$t('list_approve.table_date_submit'),
          binding: "date_submit",
          minWidth: getWidthByTextContent(this.$t('list_approve.table_date_submit')),
          maxWidth: 200,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_approve.table_date_approval'),
          binding: "date_approval",
          minWidth: getWidthByTextContent(this.$t('list_approve.table_date_approval')),
          maxWidth: 200,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_approve.table_title'),
          binding: "title",
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          isRequired: false,
          cssClassAll: "readOnly-approved"
        },
        {
          header: this.$t('list_approve.table_contractor_name'),
          binding: "contractor_name",
          minWidth: getWidthByTextContent(this.$t('list_approve.table_contractor_name')),
          maxWidth: 200,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_approve.table_scope'),
          binding: "scope",
          minWidth: 120,
          maxWidth: 200,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_approve.table_category'),
          binding: "category_name",
          minWidth: 120,
          maxWidth: 200,
          allowSorting: false,
          isReadOnly: false,
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_approve.table_calculation_method'),
          align: "center",
          columns: [
            {
              header: this.$t('list_approve.table_layer1'),
              binding: "layer1",
              minWidth: getWidthByTextContent(this.$t('list_approve.table_layer1')) + 5,
              maxWidth: 890,
              allowSorting: false,
              isReadOnly: false,
              wordWrap: true
            },
            {
              header: this.$t('list_approve.table_layer2'),
              binding: "layer2",
              minWidth: getWidthByTextContent(this.$t('list_approve.table_layer2')) + 5,
              maxWidth: 890,
              allowSorting: false,
              isReadOnly: false,
              wordWrap: true
            }
          ]
        },
        {
          header: this.$t('list_approve.table_month_year_registration'),
          binding: "month_year_registration",
          minWidth: getWidthByTextContent(this.$t('list_approve.table_month_year_registration')),
          maxWidth: 300,
          allowSorting: false,
          isRequired: false,
          isReadOnly: false,
          cssClass: "btn-db",
          multiLine: true,
          wordWrap: true
        },
        {
          header: this.$t('list_approve.button_log_history'),
          binding: "log_history",
          minWidth: getWidthByText(this.$t('list_approve.button_log_history')),
          width: '*',
          allowSorting: false,
          cssClass: "btn-db",
          cssClassAll: "cell_comment-confirm hide-filter",
          multiLine: true,
          wordWrap: true,
          cellTemplate: CellMaker.makeButton({
            text: this.$t('list_approve.button_log_display'),
            click: (e, ctx) => this.onHistoryClicking(ctx)
          })
        }
      ];
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      const idsWorkflow = this.approvalGrid.items.map(item => item.id);
      sessionStorage.setItem("dataListMoving", JSON.stringify(idsWorkflow));
      await updateDataBeforeRedirect(rowData.item);
      // return this.$router.push({path: `/emissions/view/${rowData.item.scope_id}/${rowData.item.category_id}`});
      this.$router.push({ path: `/emissions/register/approval/${rowData.item.id}` });
    },
    async onHistoryClicking(rowData) {
      if (!rowData.item?.id) return;
      this.workflowDataId = rowData.item?.id;
      await this.handleGetHistoryChanged(rowData.item?.id);
      this.logHistoryPopup = true;
      this.dialogCancelBtn = true;
    },
    async onCommentClicking(rowData) {
      if (!rowData.item?.id) return;
      await this.handleGetCommentHistory(rowData.item?.id);
      this.commentPopup = true;
      this.dialogCancelBtn = true;
    },
    async handleGetCommentHistory(workflowDataId) {
      const payload = {
        id: workflowDataId
      };
      const commentList = await getListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map(commentHistory => {
        return {
          ...commentHistory,
          date: formatDateTimeDataTable(commentHistory.updated_at),
          name: commentHistory.user.name,
          content: commentHistory.content
        };
      });
    },
    async handleGetHistoryChanged(workflowDataId) {
      const payload = {
        id: workflowDataId
      };
      const listHistoryChanged = await getListLogHistory(payload);
      this.historyLogList = listHistoryChanged.data.map(listHistoryChanged => {
        let status = getStatusNameById(listHistoryChanged.status);
        let colorStatus = getColorStatusById(listHistoryChanged.status);

        if (listHistoryChanged.status === STATUS_FIELD.STATUS_SUBMIT) {
          status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
          colorStatus = getColorStatusById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
        }

        if (listHistoryChanged.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED) {
          status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
          colorStatus = getColorStatusById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
        }

        return {
          ...listHistoryChanged,
          date: formatDateTimeDataTable(listHistoryChanged.created_at),
          name: listHistoryChanged.content,
          status: status,
          color: colorStatus
        };
      });
    },
    defineTable() {
      this.approvalColumn = this.getApprovalColumns();
      this.initialView();

      this.$nextTick(() => {
        this.scrollToTop();
      });
      this.isEmptySource= this.approvalData.length <= 0;
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    onFlexGridInitialDone(flexgrid) {
      this.flexgrid = flexgrid;
      if (!flexgrid) {
        return;
      }
      this.canUndo = false;
      this.canRedo = false;
      const stateChanged = (event) => {
        this.canUndo = event.canUndo;
        this.canRedo = event.canRedo;
      };
      this.undoStack = new UndoStack(flexgrid, stateChanged);
    },
    markUnreadRows() {
      this.flexgrid.formatItem.addHandler((handler, eventHandler) => {
        let accessRowIndex = eventHandler.row;
        let currentItem = this.approvalGrid.items[accessRowIndex];
        if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
          let currentItemUnread = currentItem !== undefined && currentItem["unread_flg"] !== undefined ? currentItem["unread_flg"] : 0;
          if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
            if (currentItemUnread) {
              eventHandler.cell.classList.add("wj-has-unread-mark");
            }
          }
        }
      });
    },
    async handleApprovalCancleSubmit() {
      try {
        const payload = {
          action: ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
          dataWorkflow: {
            id: parseInt(this.$route.query.workflow_data_id),
            comment: "",
          },
          is_allow_cancel: CANCEL_TYPE.ACCEPT
        }
        await cancelSubmittedData(payload)
        this.$router.replace({ path: '/approval'})
      } catch (error) {
        this.$router.replace({ path: '/approval'})
      }
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    approvalDataHandler(approvalFormData) {
      this.dataApproval = approvalFormData
      this.submitDataHandler(true);
    },
    async submitDataHandler(isSubmitData = false) {
      this.loading = true;
      const ACTION = {
        approve: 'approve',
        reject: 'return',
      };
      const totalRowsChecked = this.getTotalRowsChecked();
      const workflowIds = totalRowsChecked.map((row) => row.id);
      let actionSubmit = this.dataApproval.status === APPROVAL_TYPE.APPROVE ? ACTION.approve : ACTION.reject;
      let payload = {
        action: isSubmitData ? actionSubmit : 'change_status',
        workflowIds,
      };
      if (isSubmitData) {
        payload.selected_approver = ''
        payload.comment = this.dataApproval.comment
        payload.check_duplicate = 1
      } else {
        payload.status = this.optionSelectMulti.column_name === ENABLED_STATUS;
      }
      try {
        const response = await updateStatusMultipleData(payload);
        if (response.success) {
          this.dialogApproval = false;
          this.dialogStatusPopup = false;
          this.selectedItems = [];

          this.actionUpdateIsLoadingTable(true);
          await this.handleGetDataApproval();
          this.actionUpdateIsLoadingTable(false);
          this.getNotification();

          if (isSubmitData) {
            const isApproved = this.dataApproval.status === APPROVAL_TYPE.APPROVE;
            const translationKey = isApproved 
              ? "register_data.popup_message_accept_approve" 
              : "register_data.popup_message_return_approve";
            
            this.notificationMessage = this.$t(translationKey);
          } else {
            const translationKey = MESSAGE_BATCH_SETTING.popup[this.optionSelectMulti.column_name];
            this.notificationMessage = this.$t(translationKey, { number: this.totalRowsCheckedLength });
          }
          this.totalRowsCheckedLength = 0;
          this.notificationPopup = true;
          this.loading = false;
        }
      } catch (error) {
        this.notificationMessage = error.errors?.error_msg?.join(",");
        this.dialogApproval = false;
        this.dialogStatusPopup = false;
        this.notificationPopup = true;
        this.loading = false;
      }
    },
    handleScroll() {
      const scrollElementTop = this.$refs.scrollElement.offsetTop;
      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition > scrollElementTop + 239 + 56) {
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
    },
    initializeDefaultOptions() {
      this.optionSelectMulti = this.listActionMulti[0];
    },
    handleChangeOptionMulti() {
      // Toggle checkBox visible
      this.listCheckBoxStatus.forEach((status) => {
        const isVisible = status === this.optionSelectMulti.column_name;
        this.flexGrid.getColumn(status).visible = isVisible;
      });
      this.resetCheckboxTable();
    },
    resetCheckboxTable() {
      this.totalRowsCheckedLength = 0;
      this.flexGrid.rows.forEach((row) => {
        if (row.dataItem?.[this.optionSelectMulti.data_bidding]) {
          row.dataItem[this.optionSelectMulti.column_name] = false;
        }
      });
    },
    submitData() {
      const { column_name } = this.optionSelectMulti
      const translationConfirm = MESSAGE_BATCH_SETTING.confirm[column_name]
      const translationSubmit = MESSAGE_BATCH_SETTING.submit[column_name]
      const translationLabelSuccess = MESSAGE_BATCH_SETTING.labelSuccess[column_name]

      this.confirmTextSuccessPopup = this.$t(translationLabelSuccess);
      switch (column_name) {
        case APPROVED_STATUS:
          this.dialogApproval = true
          break;
        case DISABLED_STATUS:
        case ENABLED_STATUS:
          this.dialogStatusPopup = true
          this.messageDialogPopup = this.$t(translationConfirm, { number: this.totalRowsCheckedLength })
          this.confirmMessage = this.$t(translationSubmit)
          break;
        default:
          break;
      }
    },
  },
  computed: {
    ...mapState("commonApp", ["isExpand"]),
    ...mapState("registerData", ["isFullScreen"]),
    ...mapState("userData", ["currentUser"]),

    descriptionLabelEmpty() {
      return this.$t("list_approve.description_label_empty");
    },
    labelBtnApproved() {
      return this.$t("multiple_approval.button_approved", { totalRowsCheckedLength: this.totalRowsCheckedLength });
    },
    isDisableButton() {
      return this.totalRowsCheckedLength === 0;
    },
    messageSavedChange() {
      return this.$t('facility_management.message_saved_changes')
    },
    isAdmin() {
      return this.currentUser?.user?.role_id === 3;
    },
    getLabelButtonSubmit(){
      let translationKey = MESSAGE_BATCH_SETTING.labelButton[this.optionSelectMulti.column_name]

      if (translationKey) {
        return this.$t(translationKey, { number: this.totalRowsCheckedLength });
      }
    },
    isDisableEnable() {
      return [ENABLED_STATUS, DISABLED_STATUS].includes(this.optionSelectMulti.column_name);
    }
  },
}
</script>

<style lang="scss">
.wj-flexgrid .wj-cell-check-custome[type=checkbox]:checked {
  line-height: 1em;
}

.wj-flexgrid .wj-cells .wj-cell-background-disabled {
  background-color: $monoLight !important;
}

.wj-flexgrid .readOnly-approved.wj-cell[aria-readonly] {
  background: #f7f7f2 !important;
  color: #404d50 !important;
}
.setting-page{
  .wj-flexgrid  {
    .wj-cells {
      .wj-state-active:not(.wj-state-multi-selected) {
        background: $monoOffWhite !important;
        &.wj-cell-background-disabled {
          background-color: $monoLight !important;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
@import './styles/index.scss';
.button-bottom {
  width: calc(100% + 40px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 20px;
  background: $bgMid;
  box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
  position: relative;
  bottom: -80px;
  margin-left: -20px;

  &.fixed-button-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: 16px 40px;
  }
  .buton-edit-title,
  .btn-action {
    width: 100%;
    justify-content: center;
  }
}
.wrap__pc {
  display: none;
}
@include desktop {
  .button-bottom {
    display: none;
  }
  .wrap { 
    &__action {
      margin-right:40px;
      display: flex;
      gap: 20px;
      .submit-register-btn {
        min-width: 180px;
      }
    }
    &__pc {
      display: flex;
    }
  }
  .select-data {
    width: 170px;
    &.en {
      width: 180px;
    }
  }
}

.wrap__mobile {
  display: flex;
  gap: 16px;
  flex-flow: wrap-reverse;
  .common-btn {
    width: 100%;
  }
}
</style>