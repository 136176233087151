import {
  MESSAGE_NOT_NULL,
  MESSAGE_MAX_20_CHARACTOR,
  MESSAGE_IS_NUMBER,
  MESSAGE_POSITIVE_NUMBER
}
  from '@/constants/registerData'
import { create, all } from 'mathjs'; 
import store from '@/store';
import { KEYS_CODE } from '@/constants/keyboard';
/**
 * @param {string|null} valueInput
 * @returns string
 */
export const formatValue = (valueInput) => {
  if (!valueInput && valueInput !== 0) {
    return null;
  }
  return (valueInput || 0).toString().trim().replace(/,/g, '');
};

export const $_helper_isNumberType =(input)=>{
  if (!input && input !== 0) {
    return false;
  }
  if(formatValue(input).includes('-') && formatValue(input).length < 2) {
    return false
  }
  const realNumber = input?.toString()?.trim()?.replace(/,/g, '');
  return /^-?\d*\.?\d*$/.test(realNumber);
}

export const $_helper_isNegativeNumber =(input)=>{
  return $_helper_isNumberType(input) && formatValue(input) < 0;
}

export const validateNumberField = (input) => {
  if (input) {
    if(!$_helper_isNumberType(input)){
      return MESSAGE_IS_NUMBER; 
    }

    //remove check negative number
    // if ($_helper_isNegativeNumber(input)) {
    //   return MESSAGE_POSITIVE_NUMBER;
    // }
    const numberValidate = formatValue(input).includes('-') ? 26 : 25
    if (formatValue(input).length > numberValidate) {
      return MESSAGE_MAX_20_CHARACTOR;
    }
  } else {
    return MESSAGE_NOT_NULL;
  }
  return null;
}

const config = {
  // Default type of number
  // Available options: 'number' (default), 'BigNumber', or 'Fraction'
  number: 'BigNumber',

  // Number of significant digits for BigNumbers
  precision: 50,

}
export const math = create(all, config)

export const formatBigNumber = (value, precision = 2) => {
  const response = math.format(value, {notation: 'fixed', precision: precision});
  return response === '-0.00' ? '0.00' : response;
}

const NA_VALUE = 'N/A';
export const formatEmission25Number = (emission) => {
  const format25Number = formatValue(emission)?.includes('-') ? 26 : 25;
  return formatValue(emission)?.substring(0, format25Number) === NA_VALUE ? null : formatValue(emission)?.substring(0, format25Number)
}

export const fullWidthToHalfWidth = (value) => {
  return value.replace(/[０-９]/g, (match) => {
    return String.fromCharCode(match.charCodeAt(0) - 0xFEE0);
  });
}
export const formatNumberBySetting = (valueInput) => {
  if (!valueInput && valueInput !== 0) {
    return null;
  }
  const settingDigits = store.getters['settingApp/getDigitsBySetting'];
  const valueFormat = (valueInput || 0).toString().trim();
  switch (settingDigits) {
    case 0: return valueFormat.replace(/,/g, '');
    case 1: return valueFormat.replace(/\./g, '').replace(/,/g, '.');
    case 2: return valueFormat.replace(/'/g, '');
    case 3: return valueFormat.replace(/ /g, '').replace(/,/g, '.');
    default:
      break;
  }
};
export const removeSpecialChars = (input, isPositiveNumber = false) => {
  if (!input) return input?.toString() || '';
  const settingDigits = store.getters['settingApp/getDigitsBySetting'];
  const decimalSeparatorComma = [1,3].includes(settingDigits);
  const processInput = (text) => {
    return text.replace(/[\uFF01-\uFF5E]/g, (char) =>
      String.fromCharCode(char.charCodeAt(0) - 0xFEE0)
    ).replace(/\u3000/g, ' ') // Replace fullwidth space with halfwidth space
      .replace(/[０-９]/g, (char) =>
        String.fromCharCode(char.charCodeAt(0) - 0xFEE0)
      )
      .replace(/[^0-9.,-]/g, ''); // Chỉ giữ lại số, dấu ., dấu , và dấu -
  };
  let halfWidthInput = processInput(input.toString());
  let cleaned = null;
  // [^...] negative sentence replace all character except (0 -> 9 && "," && "." && "-")
  if (decimalSeparatorComma) {
    cleaned = halfWidthInput.replace(/[^\d,-]/g, '').replace(/,/g, '.');
  } else {
    cleaned = halfWidthInput.replace(/[^\d.-]/g, '');
  }

  cleaned = cleaned.replace(/^0+(?=\d)/, ''); // Remove all zero begin string
  cleaned = cleaned.replace(/(?!^)-+/g, ''); // Remove minus not at the begin string
  if(isPositiveNumber) {
    cleaned = cleaned.replace(/-/g, '')
  }
  return cleanAndKeepLastDot(cleaned);
}
export const  cleanAndKeepLastDot = (input) => {
  let arr = input.split('.');
  if(arr.length === 1) return input;
  let result = arr.slice(0, -1).join('') + (arr.length > 1 ? '.' + arr[arr.length - 1] : '');
  return result;
}

export const $_helper_isNegativeNumber_bySetting =(input)=>{
  return $_helper_isNumberType_bySetting(input) && formatNumberBySetting(input) < 0;
}

export const $_helper_isNumberType_bySetting =(input, isScope4 = false)=>{
  if (!input && input !== 0) {
    return false;
  }
  if(formatValue(input).includes('-') && formatValue(input).length < 2) {
    return false
  }
  const realNumber = isScope4 ? formatNumberBySetting(input) : removeSpecialChars(input);
  return /^-?\d*\.?\d*$/.test(realNumber);
}

export const validateNumberFieldBySetting = (input) => {
  if (input) {
    if(!$_helper_isNumberType_bySetting(input)){
      return MESSAGE_IS_NUMBER; 
    }

    const numberValidate = formatNumberBySetting(input).includes('-') ? 26 : 25
    if (formatNumberBySetting(input).length > numberValidate) {
      return MESSAGE_MAX_20_CHARACTOR;
    }
  } else {
    return MESSAGE_NOT_NULL;
  }
  return null;
}

export const preventKeydownNumber = ({event, getDigitsBySetting, columnNum, formatItems, isWsuValueS3c16 = () => false, allowMinus = true }) => {
  const allowedKeys = [
    KEYS_CODE.BACKSPACE,
    KEYS_CODE.TAB,
    KEYS_CODE.LEFT_ARROW,
    KEYS_CODE.UP_ARROW,
    KEYS_CODE.RIGHT_ARROW,
    KEYS_CODE.DOWN_ARROW,
    KEYS_CODE.DELETE,
  ];
  if (allowMinus) {
    allowedKeys.push(KEYS_CODE.DASH, KEYS_CODE.SUBTRACT);
  }
  const decimalSeparatorComma = [1, 3].includes(getDigitsBySetting);
  if (decimalSeparatorComma) {
    allowedKeys.push(KEYS_CODE.COMMA); // COMMA (',')
  } else {
    allowedKeys.push(KEYS_CODE.PERIOD, KEYS_CODE.DECIMAL); // Decimal ('.') & Period ('.')
  }

  const isNumberKey =
    (event.keyCode >= 48 && event.keyCode <= 57) || // Phím 0-9 trên hàng phím chính
    (event.keyCode >= 96 && event.keyCode <= 105); // Phím 0-9 trên Numpad

  const isSpecialCharacter = /[!@#$%^&*()_+]/.test(event.key);

  const isControlKey = allowedKeys.includes(event.keyCode) || (event.ctrlKey || event.metaKey);

  // Block if not a number and not a control key
  if (formatItems.includes(columnNum) || columnNum === 'value' || isWsuValueS3c16(columnNum)) {
    if (!isNumberKey && !isControlKey || isSpecialCharacter) {
      event.preventDefault();
    }
  }
}
