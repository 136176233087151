<template>
  <div class="w-full">
    <v-row v-if="getInputList.length > 0" no-gutters>
      <v-col cols="12">
        <div class="title-btn title-btn-select-scope mt-3">
          <span
            >{{
              scopeAndCategory.cateId == 11
                ? $t('56_pattern.table_product_name')
                : $t('primary_data_merge.label_classification_input')
            }}
          </span>
          <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
        </div>
      </v-col>
      <v-col
        v-for="(item, index) in getInputList"
        cols="12"
        :md="getInputList.length > 1 ? 6 : 12"
        :key="`${index}-item`"
      >
        <div
          v-if="item.typeInput === 'select'"
          class="listMenu-btn-text list-input-item mb-3 mt-2"
          :class="index % 2 > 0 ? 'ml-md-1' : 'mr-md-1'"
        >
          <v-select
            solo
            flat
            item-text="key"
            item-value="value"
            v-model="valueInput[item.keyInput]"
            :items="item.items"
            :label="item.inputName"
            :disabled="disabled"
            :id="item.keyInput"
            class="select-type select-default list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu export-select' }"
            @change="handleUpdateValue(item.keyInput)"
          />
        </div>
        <div v-else :class="index % 2 > 0 ? 'ml-md-1' : 'mr-md-1'">
          <input-field
            :label="item.inputName"
            v-model="valueInput[item.keyInput]"
            :inputValue="valueInput[item.keyInput]"
            :isReadOnly="disabled"
            :errorMessages="errorMessages[item.keyInput]"
            @change="handleUpdateValue(item.keyInput)"
            @blur="handleCheckValidate(item.keyInput)"
            @focus="handleFocus(item.keyInput)"
            class="mt-2 mb-3"
            :id="item.keyInput"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-for="(item, index) in getGroupInput" :key="`${index}-title`" class="mb-2" no-gutters>
      <v-col cols="12">
        <div class="title-btn title-btn-select-scope mt-3">
          <span>{{ item.nameGroup }} </span>
          <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
        </div>
      </v-col>
      <v-col v-for="(itemInput, indexInput) in item.items" cols="12" :md="itemInput.cols" :key="`${indexInput}-item`">
        <div
          v-if="itemInput.typeInput === 'select'"
          class="listMenu-btn-text list-input-item mb-1 mt-2"
          :class="itemInput.class"
        >
          <v-select
            solo
            flat
            item-text="key"
            item-value="value"
            v-model="valueInput[itemInput.keyInput]"
            :items="itemInput.items"
            :label="itemInput.inputName"
            :disabled="disabled"
            class="select-type select-default list-menu--select select-item"
            :menu-props="{ contentClass: 'select-menu export-select' }"
            @change="handleUpdateValue(itemInput.keyInput)"
            :id="itemInput.keyInput"
          />
        </div>
        <div v-else class="mt-2 mb-1" :class="itemInput.class">
          <input-field
            :label="itemInput.inputName"
            v-model="valueInput[itemInput.keyInput]"
            :inputValue="valueInput[itemInput.keyInput]"
            :isNumberType="itemInput.isNumber"
            @change="handleUpdateValue(itemInput.keyInput)"
            @blur="handleBlur(itemInput.keyInput)"
            :isReadOnly="disabled"
            :idInputField="itemInput.keyInput"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InputField from '@/components/products/input/InputField.vue';
import { Fuel, CarModel, ProcessingMethods, Classification } from '@/constants/customOptionByMethod.js';
import {
  GICS_PULLDOWN,
  SCOPE_TYPE,
  DATA_TYPE,
  CURRENCY_TYPE,
  SCORE_TYPE,
  CAR_LOAN_TYPE,
  COMMERCIAL_DATA_TYPE1,
} from '@/constants/registerData';
import { mapState } from 'vuex';

export default {
  props: {
    scopeAndCategory: {
      type: Object,
      default() {
        return {};
      },
    },
    method: {
      type: Number,
      default: 0,
    },
    valueEdit: {
      type: Object,
      default() {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    pcafType: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  components: {
    InputField,
  },

  data() {
    return {
      inputList: [],
      valueInput: {},
      errorMessages: {
        product_name: [],
      },
    };
  },
  computed: {
    ...mapState('userData', ['planType']),
    getInputList() {
      const cateId = this.scopeAndCategory?.cateId;
      let inputList = [];
      if (!cateId) return inputList;

      if (this.scopeAndCategory.scope === 3) {
        switch (cateId) {
          case 5:
            inputList = [
              {
                typeInput: 'select',
                items: ProcessingMethods,
                inputName: this.$t('primary_data_merge.placeholder_layer', {
                  layerName: this.$t('56_pattern.table_processing_method'),
                }),
                keyInput: 'processing_method',
              },
              {
                typeInput: 'select',
                items: Classification,
                inputName: this.$t('primary_data_merge.placeholder_layer', {
                  layerName: this.$t('primary_data_merge.label_classification'),
                }),
                keyInput: 'wsu_type',
              },
            ];
            break;

          case 11:
            inputList = [
              {
                typeInput: 'input',
                items: [],
                inputName: this.$t('primary_data_merge.placeholder_layer', {
                  layerName: this.$t('56_pattern.table_product_name'),
                }),
                keyInput: 'product_name',
              },
            ];
            break;

          case 12:
            inputList = [
              {
                typeInput: 'select',
                items: ProcessingMethods,
                inputName: this.$t('primary_data_merge.placeholder_layer', {
                  layerName: this.$t('56_pattern.table_processing_method'),
                }),
                keyInput: 'processing_method',
              },
              {
                typeInput: 'select',
                items: Classification,
                inputName: this.$t('primary_data_merge.placeholder_layer', {
                  layerName: this.$t('primary_data_merge.label_classification'),
                }),
                keyInput: 'wsu_type',
              },
            ];
            break;

          case 14:
            inputList = [
              {
                typeInput: 'input',
                items: [],
                inputName: this.$t('primary_data_merge.placeholder_layer', {
                  layerName: this.$t('56_pattern.table_scale_index_source_c14'),
                }),
                keyInput: 'scale_index_source',
              },
            ];
            break;

          case 15:
            if (this.method > 0 && this.planType.is_pcaf) {
              inputList = [
                {
                  typeInput: 'select',
                  items: this.switchKeyValueData(GICS_PULLDOWN),
                  inputName: this.$t('56_pattern.table_gics_value'),
                  keyInput: 'classify_gics',
                },
                {
                  typeInput: 'select',
                  items: SCOPE_TYPE,
                  inputName: this.$t('56_pattern.table_scope'),
                  keyInput: 'scope_pattern',
                },
              ];
            }
            break;
        }
      }

      this.valueInput = this.valueEdit;
      this.$emit(
        'onUpdateValidate',
        inputList.map((item) => item.keyInput),
      );
      return inputList;
    },

    getGroupInput() {
      let groupInput = [];
      const cateId = this.scopeAndCategory?.cateId;
      let value_quality_score = null;

      if (this.planType?.is_pcaf) {
        if (cateId === 15 && (this.method === 1 || this.method === 2 || this.method === 3)) {
          let total_capital_name_col = this.$t('56_pattern.table_total_capital');
          if (this.method === 1 || this.method === 2) {
            const current_data_type = this.pcafType.listMethodType.findIndex(
              (item) => item.id === this.pcafType.categoryIdSelect,
            );
            if (this.pcafType.listMethodType[current_data_type]?.id === 1)
              total_capital_name_col = this.$t('56_pattern.table_evic');
          }

          switch (this.pcafType.dataTypeIdSelect) {
            case 1:
              value_quality_score = 1;
              break;
            case 2:
            case 3:
              value_quality_score = 2;
              break;
            case 4:
              value_quality_score = 3;
              break;
            case 5:
              value_quality_score = 4;
              break;
            default:
              value_quality_score = 5;
          }

          groupInput = [
            {
              nameGroup: this.$t('56_pattern.table_attraction_factor'),
              items: [
                {
                  typeInput: 'input',
                  inputName: this.$t('56_pattern.table_category1'),
                  keyInput: 'category_1',
                  cols: 3,
                  class: 'mr-1',
                },
                {
                  typeInput: 'input',
                  inputName: this.$t('56_pattern.table_amount'),
                  keyInput: 'loan_amount',
                  cols: 6,
                  class: 'mx-1',
                  isNumber: true,
                },
                {
                  typeInput: 'select',
                  items: CURRENCY_TYPE,
                  inputName: this.$t('56_pattern.table_currency'),
                  keyInput: 'currency',
                  cols: 3,
                  class: 'ml-1',
                },
                {
                  typeInput: 'input',
                  inputName: this.$t('56_pattern.table_category1'),
                  keyInput: 'category_2',
                  cols: 3,
                  class: 'mr-1',
                },
                {
                  typeInput: 'input',
                  items: [],
                  inputName: total_capital_name_col,
                  keyInput: 'total_capital',
                  cols: 9,
                  class: 'ml-1',
                  isNumber: true,
                },
              ],
            },

            {
              nameGroup: this.$t('56_pattern.summary_score_dq_quality_score'),
              items: [
                {
                  typeInput: 'select',
                  items: SCORE_TYPE,
                  inputName: this.$t('56_pattern.summary_score_dq_quality_score'),
                  keyInput: 'dq_quality_score',
                  cols: 12,
                  class: 'ml-1',
                },
              ],
            },
          ];
        }

        if (cateId === 15 && (this.method === 4 || this.method === 5)) {
          switch (this.pcafType.dataTypeIdSelect) {
            case 7:
              value_quality_score = 1;
              break;
            case 8:
              value_quality_score = 2;
              break;
            case 9:
              value_quality_score = 3;
              break;
            case 10:
              value_quality_score = 4;
              break;
            default:
              value_quality_score = 5;
          }
          groupInput = [
            {
              nameGroup: this.$t('56_pattern.table_attraction_factor'),
              items: [
                {
                  typeInput: 'input',
                  inputName: this.$t('56_pattern.table_category1'),
                  keyInput: 'category_1',
                  cols: 3,
                  class: 'mr-1',
                },
                {
                  typeInput: 'input',
                  inputName: this.$t('56_pattern.table_amount'),
                  keyInput: 'loan_amount',
                  cols: 6,
                  class: 'mx-1',
                  isNumber: true,
                },
                {
                  typeInput: 'select',
                  items: CURRENCY_TYPE,
                  inputName: this.$t('56_pattern.table_currency'),
                  keyInput: 'currency',
                  cols: 3,
                  class: 'ml-1',
                },
                {
                  typeInput: 'input',
                  inputName: this.$t('56_pattern.table_category1'),
                  keyInput: 'category_2',
                  cols: 3,
                  class: 'mr-1',
                },
                {
                  typeInput: 'input',
                  items: [],
                  inputName: this.$t('56_pattern.table_property_value'),
                  keyInput: 'value_purchase',
                  cols: 9,
                  class: 'ml-1',
                  isNumber: true,
                },
              ],
            },

            {
              nameGroup: this.$t('56_pattern.summary_score_dq_quality_score'),
              items: [
                {
                  typeInput: 'select',
                  items: SCORE_TYPE,
                  inputName: this.$t('56_pattern.summary_score_dq_quality_score'),
                  keyInput: 'dq_quality_score',
                  cols: 12,
                  class: 'ml-1',
                },
              ],
            },
          ];
        }
        if (cateId === 15 && this.method === 6) {
          switch (this.pcafType.dataTypeIdSelect) {
            case 12:
            case 13:
              value_quality_score = 1;
              break;
            case 14:
              value_quality_score = 2;
              break;
            case 15:
              value_quality_score = 3;
              break;
            case 16:
              value_quality_score = 4;
              break;
            default:
              value_quality_score = 5;
          }
          groupInput = [
            {
              nameGroup: this.$t('56_pattern.table_attraction_factor'),
              items: [
                {
                  typeInput: 'input',
                  inputName: this.$t('56_pattern.table_category1'),
                  keyInput: 'category_1',
                  cols: 3,
                  class: 'mr-1',
                },
                {
                  typeInput: 'input',
                  inputName: this.$t('56_pattern.table_amount'),
                  keyInput: 'loan_amount',
                  cols: 6,
                  class: 'mx-1',
                  isNumber: true,
                },
                {
                  typeInput: 'select',
                  items: CURRENCY_TYPE,
                  inputName: this.$t('56_pattern.table_currency'),
                  keyInput: 'currency',
                  cols: 3,
                  class: 'ml-1',
                },
                {
                  typeInput: 'input',
                  inputName: this.$t('56_pattern.table_category1'),
                  keyInput: 'category_2',
                  cols: 3,
                  class: 'mr-1',
                },
                {
                  typeInput: 'input',
                  items: [],
                  inputName: this.$t('56_pattern.table_property_buy_value'),
                  keyInput: 'value_purchase',
                  cols: 9,
                  class: 'ml-1',
                  isNumber: true,
                },
              ],
            },

            {
              nameGroup: this.$t('56_pattern.summary_score_dq_quality_score'),
              items: [
                {
                  typeInput: 'select',
                  items: SCORE_TYPE,
                  inputName: this.$t('56_pattern.summary_score_dq_quality_score'),
                  keyInput: 'dq_quality_score',
                  cols: 12,
                  class: 'ml-1',
                },
              ],
            },
          ];
        }
        if (cateId === 15 && this.method === 7) {
          switch (this.pcafType.dataTypeIdSelect) {
            case 18:
              value_quality_score = 1;
              break;
            case 19:
              value_quality_score = 2;
              break;
            case 20:
              value_quality_score = 3;
              break;
            case 21:
              value_quality_score = 4;
              break;
            default:
              value_quality_score = 5;
          }
          groupInput = [
            {
              nameGroup: this.$t('56_pattern.table_attraction_factor'),
              items: [
                {
                  typeInput: 'input',
                  inputName: this.$t('56_pattern.table_category1'),
                  keyInput: 'category_1',
                  cols: 3,
                  class: 'mr-1',
                },
                {
                  typeInput: 'input',
                  inputName: this.$t('56_pattern.table_loan_amount'),
                  keyInput: 'loan_amount',
                  cols: 6,
                  class: 'mx-1',
                  isNumber: true,
                },
                {
                  typeInput: 'select',
                  items: CURRENCY_TYPE,
                  inputName: this.$t('56_pattern.table_currency'),
                  keyInput: 'currency',
                  cols: 3,
                  class: 'ml-1',
                },
                {
                  typeInput: 'input',
                  inputName: this.$t('56_pattern.table_category1'),
                  keyInput: 'category_2',
                  cols: 3,
                  class: 'mr-1',
                },
                {
                  typeInput: 'input',
                  items: [],
                  inputName: this.$t('56_pattern.table_purchasing_power_parity'),
                  keyInput: 'gdp_ppp_adjusted',
                  cols: 9,
                  class: 'ml-1 ',
                  isNumber: true,
                },
              ],
            },
            {
              nameGroup: this.$t('56_pattern.summary_score_dq_quality_score'),
              items: [
                {
                  typeInput: 'select',
                  items: SCORE_TYPE,
                  inputName: this.$t('56_pattern.summary_score_dq_quality_score'),
                  keyInput: 'dq_quality_score',
                  cols: 12,
                  class: '',
                },
              ],
            },
          ];
        }
      } else {
        if (cateId === 15 && this.method >= 1 && this.method <= 4) {
          if (this.method === 1) {
            groupInput = [
              {
                nameGroup: this.$t('primary_data_merge.label_classification_input'),
                items: [
                  {
                    typeInput: 'input',
                    inputName: this.$t('56_pattern.table_ides_name_company'),
                    keyInput: 'ides_name_company',
                    cols: 3,
                    class: 'mr-1',
                  },
                  {
                    typeInput: 'input',
                    items: CURRENCY_TYPE,
                    inputName: this.$t('56_pattern.table_ir_company_hold'),
                    keyInput: 'ir_company_hold',
                    cols: 9,
                    class: 'ml-1',
                    isNumber: true,
                  },
                  {
                    typeInput: 'input',
                    inputName: this.$t('56_pattern.table_ir_release_quantity'),
                    keyInput: 'ir_release_quantity',
                    cols: 12,
                    class: '',
                    isNumber: true,
                  },
                ],
              },
            ];
          } else {
            groupInput = [
              {
                nameGroup: this.$t('primary_data_merge.label_classification_input'),
                items: [
                  {
                    typeInput: 'input',
                    inputName: this.$t('56_pattern.table_ides_name_company'),
                    keyInput: this.method === 3 ? 'investment_destination' : 'ides_name_company',
                    cols: 3,
                    class: 'mr-1',
                  },
                  {
                    typeInput: 'input',
                    items: CURRENCY_TYPE,
                    inputName: this.$t('56_pattern.table_ir_internal_investment'),
                    keyInput: this.method === 4 ? 'ir_calculation_numerator' : 'ir_internal_investment',
                    cols: 9,
                    class: 'ml-1',
                    isNumber: true,
                  },
                  {
                    typeInput: 'input',
                    inputName: this.$t('56_pattern.table_ir_total_investment'),
                    keyInput: this.method === 4 ? 'ir_calculated_denominator' : 'ir_total_investment',
                    cols: 12,
                    class: '',
                    isNumber: true,
                  },
                ],
              },
            ];
          }
        }
      }

      let keyInputs = [];
      groupInput.forEach((group) => {
        group.items.forEach((item) => {
          if (item.keyInput) {
            keyInputs.push(item.keyInput);
          }
        });
      });

      this.valueEdit.dq_quality_score = this.disabled ? this.valueEdit.dq_quality_score : value_quality_score;

      this.$emit('onUpdateValidateGroupInput', keyInputs);

      return groupInput;
    },
  },

  methods: {
    handleBlur(key) {
      if ((key === 'category_1' || key === 'category_2') && this.valueInput[key]?.length > 128) {
        this.valueInput[key] = this.valueInput[key].slice(0, 128);
      }
    },

    handleUpdateValue(value) {
      if (this.planType?.is_pcaf) {
        let fieldAutoField = [
          'classify_gics',
          'scope_pattern',
          'category_1',
          'loan_amount',
          'category_2',
          'total_capital',
          'type_data',
          'gdp_ppp_adjusted',
          'dq_quality_score',
        ];

        if (this.valueInput[value] && fieldAutoField.includes(value) && !this.valueInput.currency) {
          this.valueInput.currency = 'JPY';
        }
      }

      this.$emit('onUpdateValue', this.valueInput);
    },

    handleCheckValidate(key) {
      if (key !== 'product_name' && !this.valueInput[key]) return;

      let textProduct = this.valueInput[key]?.trim();

      if (textProduct.substring(0, 1) === ',') textProduct = textProduct.slice(1);
      if (textProduct.charAt(textProduct.length - 1) === ',')
        textProduct = textProduct.slice(0, textProduct.length - 1);

      this.valueInput[key] = textProduct;

      let string = [];

      if (this.valueInput[key]) {
        string = this.valueInput[key]
          ?.trim()
          .split(',')
          .map((word) => {
            return word.trim().length;
          });
      }

      if (Math.max(...string) > 255)
        this.errorMessages[key].push(this.$t('validation.error_maximum_20_charactors', { charactors: 255 }));
    },

    handleFocus(key) {
      if (key !== 'product_name') return;
      this.errorMessages[key] = [];
    },
    switchKeyValueData(arr) {
      return arr.map((item) => {
        return { key: item.value, value: item.key };
      });
    },
  },
};
</script>

<style lang="scss">
.w-full {
  width: 100%;
}

.title-btn {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px;
  margin: 0;
  gap: 16px;
  width: 100%;
  height: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;

  .title-require {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 4px 2px;
    width: auto;
    height: 20px;
    margin-top: 8px;

    background: $goldMid;
    border-radius: 4px;
    color: white;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
  }
}
</style>
